import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CS_VENDOR_ID, PAGE_LIMIT, TASK_STATUS } from "../../constants";
import { useClaimTaskMutation, useFindTasksQuery } from "../../generated/graphql";
import { usePagination } from "../../util/use-pagination";
import {
  useQueryParamInt,
  useQueryParamObject,
  useQueryParamString,
  useQueryParamStringArray,
} from "../../util/use-query-param";
import { useQueryParamIntArray } from "../../util/use-query-param/use-query-param-int-array";
import { ProductTaskTypePair } from "./common";

export function useFindTasks() {
  const pagination = usePagination(PAGE_LIMIT);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearchParam] = useQueryParamString("search");
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [claimTaskId, setClaimTaskId] = useState(-1);
  const [categoryIds, setCategoryIdsParam] = useQueryParamIntArray("categoryIds");
  const [productTaskTypes, setProductTaskTypesParam] = useQueryParamObject<ProductTaskTypePair[]>("productTaskTypes");
  const [taskTypeIds, setTaskTypeIdsParam] = useQueryParamStringArray("taskTypeIds");
  const [isFulfilledByRCMWriter, setIsFulfilledByRCMWriter] = useQueryParamInt("isRCMWriter");

  const queryResult = useFindTasksQuery({
    variables: {
      limit: PAGE_LIMIT,
      offset: pagination.offset,
      statusIds: [TASK_STATUS.AVAILABLE],
      vendorIds: [CS_VENDOR_ID],
      productTaskTypes: productTaskTypes && productTaskTypes.length > 0 ? productTaskTypes : null,
      categoryIds,
      taskTypeIds,
      isFulfilledByRCMWriter: isFulfilledByRCMWriter !== undefined ? isFulfilledByRCMWriter === 1 : undefined,
      search,
    },
    fetchPolicy: "network-only",
  });
  const [claimTaskMutation, { loading: claimTaskLoading }] = useClaimTaskMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const tasks = queryResult.data?.tasks.nodes ?? [];
  const totalCount = queryResult.data?.tasks.totalCount ?? 0;

  function onShowFilterDrawer() {
    setShowFilterDrawer(true);
  }

  function onCloseFilterDrawer() {
    setShowFilterDrawer(false);
  }

  function onSearch(newSearchTerm: string) {
    setSearchParam(newSearchTerm);
  }

  function onCategoryIdsChange(value?: number[]) {
    setCategoryIdsParam(value ?? []);
  }

  function onProductTaskTypesChange(value: ProductTaskTypePair[]) {
    if (value.length === 0) {
      setProductTaskTypesParam(undefined);
    } else {
      setProductTaskTypesParam(value);
    }
  }

  function onTaskTypeIdsChange(value?: string[]) {
    setTaskTypeIdsParam(value ?? []);
  }

  function onIsRCMWriterChange() {
    setIsFulfilledByRCMWriter(!isFulfilledByRCMWriter ? 1 : 0);
  }

  async function onClaimTask(taskId: number) {
    setClaimTaskId(taskId);
    const response = await claimTaskMutation({
      variables: {
        input: {
          taskId,
        },
      },
    });
    setClaimTaskId(-1);
    if (response?.data?.claimTask?.ok) {
      toast({
        title: "Task is claimed!",
        status: "success",
      });
      navigate(`/tasks/${taskId}`);
    } else {
      if (response?.data?.claimTask?.error) {
        toast({
          title: response?.data?.claimTask?.error.message,
          status: "error",
        });
      } else {
        toast({
          title: "Error occurred while claiming task",
          status: "error",
        });
      }
    }
  }

  return {
    loading: queryResult.loading,
    pagination,
    tasks,
    totalCount,
    showFilterDrawer,
    onShowFilterDrawer,
    onCloseFilterDrawer,
    onSearch,
    onCategoryIdsChange,
    onProductTaskTypesChange,
    onTaskTypeIdsChange,
    claimTaskId,
    claimTaskLoading,
    claimTaskMutation,
    onClaimTask,
    categoryIds,
    productTaskTypes,
    taskTypeIds,
    isFulfilledByRCMWriter,
    onIsRCMWriterChange,
  };
}
