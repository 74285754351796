import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
};

export type AdminReportPublicationManagement = {
  __typename?: "AdminReportPublicationManagement";
  ordersNeedingResultUrl: OrderConnection;
};

export type AdminReportPublicationManagementOrdersNeedingResultUrlArgs = {
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type AdminReports = {
  __typename?: "AdminReports";
  publicationManagement: AdminReportPublicationManagement;
};

export type AmTask = {
  __typename?: "AmTask";
  completedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  info?: Maybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type AmTaskConnection = {
  __typename?: "AmTaskConnection";
  nodes: Array<AmTask>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ApprovePaymentBatchFundingInput = {
  paymentBatchId: Scalars["Int"];
};

export type ApprovePaymentBatchFundingPaypalInput = {
  paymentBatchId: Scalars["Int"];
};

export type ApprovePaymentBatchFundingPaypalResponse = {
  __typename?: "ApprovePaymentBatchFundingPaypalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ApprovePaymentBatchFundingResponse = {
  __typename?: "ApprovePaymentBatchFundingResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ApproveUserWritingSampleInput = {
  userWritingSampleId: Scalars["Int"];
};

export type ApproveUserWritingSampleResponse = {
  __typename?: "ApproveUserWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type BlockDomainConnection = {
  __typename?: "BlockDomainConnection";
  nodes: Array<BlockDomainCustomer>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type BlockDomainCustomer = {
  __typename?: "BlockDomainCustomer";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  domain: Scalars["String"];
  id: Scalars["Int"];
};

export enum CampaignCategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type CampaignCategory = {
  __typename?: "CampaignCategory";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CampaignCategoryConnection = {
  __typename?: "CampaignCategoryConnection";
  nodes: Array<CampaignCategory>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export enum CategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type Category = {
  __typename?: "Category";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
};

export type CategoryConnection = {
  __typename?: "CategoryConnection";
  nodes: Array<Category>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ChangePasswordInput = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type ChangePasswordResponse = {
  __typename?: "ChangePasswordResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Checklist = {
  __typename?: "Checklist";
  checklistItems: Array<ChecklistItem>;
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChecklistDefinition = {
  __typename?: "ChecklistDefinition";
  checklistDefinitionItems: Array<ChecklistDefinitionItem>;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChecklistDefinitionConnection = {
  __typename?: "ChecklistDefinitionConnection";
  nodes: Array<ChecklistDefinition>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ChecklistDefinitionItem = {
  __typename?: "ChecklistDefinitionItem";
  checklistDefinitionId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type ChecklistItem = {
  __typename?: "ChecklistItem";
  completedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type CheckoutInput = {
  customerId?: InputMaybe<Scalars["Int"]>;
  orderIds?: InputMaybe<Array<Scalars["Int"]>>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  partnerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  payNow?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutResponse = {
  __typename?: "CheckoutResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ClaimTaskInput = {
  taskId: Scalars["Int"];
};

export type ClaimTaskResponse = {
  __typename?: "ClaimTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  task?: Maybe<Task>;
};

export type ClearProposedCustomerKeywordsRejectionInput = {
  customerKeywordProposalId: Scalars["Int"];
};

export type ClearProposedCustomerKeywordsRejectionResponse = {
  __typename?: "ClearProposedCustomerKeywordsRejectionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ConfirmEmailInput = {
  confirmationToken: Scalars["String"];
};

export type ConfirmEmailResponse = {
  __typename?: "ConfirmEmailResponse";
  ok: Scalars["Boolean"];
};

export type ContentIdeation = {
  __typename?: "ContentIdeation";
  id: Scalars["Int"];
  idea: Scalars["String"];
};

export type ContentItem = {
  __typename?: "ContentItem";
  classification?: Maybe<Scalars["Int"]>;
  content: Scalars["String"];
  feedbackContentItems: Array<FeedbackContentItem>;
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  probability?: Maybe<Scalars["Float"]>;
  thumbnailFile?: Maybe<File>;
  title: Scalars["String"];
};

export type ContentItemDelivery = {
  __typename?: "ContentItemDelivery";
  content: Scalars["String"];
  keyword: Scalars["String"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  partnerLogoUrl?: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  publicUrl: Scalars["String"];
  title: Scalars["String"];
};

export type Country = {
  __typename?: "Country";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CreateAmTaskInput = {
  completedAt: Scalars["Date"];
  customerId: Scalars["Int"];
  info?: InputMaybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type CreateAmTaskResponse = {
  __typename?: "CreateAmTaskResponse";
  amTask?: Maybe<AmTask>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateBlockDomainCustomersInput = {
  customerId: Scalars["Int"];
  domains: Array<Scalars["String"]>;
};

export type CreateBlockDomainCustomersResponse = {
  __typename?: "CreateBlockDomainCustomersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateBulkProposedCustomerKeywordsInput = {
  customerKeywordProposalId: Scalars["Int"];
  keywords: Array<Scalars["String"]>;
};

export type CreateBulkProposedCustomerKeywordsResponse = {
  __typename?: "CreateBulkProposedCustomerKeywordsResponse";
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
};

export type CreateBusinessSubscriptionOrdersInput = {
  customerId: Scalars["Int"];
};

export type CreateBusinessSubscriptionOrdersResponse = {
  __typename?: "CreateBusinessSubscriptionOrdersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type CreateCategoryInput = {
  name: Scalars["String"];
};

export type CreateCategoryResponse = {
  __typename?: "CreateCategoryResponse";
  category?: Maybe<Category>;
};

export type CreateChecklistDefinitionInput = {
  items: Array<CreateChecklistDefinitionItemInput>;
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreateChecklistDefinitionItemInput = {
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type CreateChecklistDefinitionResponse = {
  __typename?: "CreateChecklistDefinitionResponse";
  checklistDefinition?: Maybe<ChecklistDefinition>;
};

export type CreateCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDisplay?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  uberallCategoryId?: InputMaybe<Scalars["Int"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerBusinessListingSpecResponse = {
  __typename?: "CreateCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId: Scalars["String"];
  customerId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partnerPackageId?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
};

export type CreateCustomerCampaignResponse = {
  __typename?: "CreateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
};

export type CreateCustomerConversationInput = {
  content: Scalars["String"];
  customerId: Scalars["Int"];
  subject: Scalars["String"];
};

export type CreateCustomerConversationResponse = {
  __typename?: "CreateCustomerConversationResponse";
  customerConversation: CustomerConversation;
};

export type CreateCustomerHoursOfOperationItemInput = {
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CreateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  categoryId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  partnerPortal?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerInvoiceInput = {
  customerId: Scalars["Int"];
  orderIds: Array<Scalars["Int"]>;
};

export type CreateCustomerInvoiceResponse = {
  __typename?: "CreateCustomerInvoiceResponse";
  customerInvoice?: Maybe<CustomerInvoice>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  isPrimary: Scalars["Boolean"];
  isTrackingRank: Scalars["Boolean"];
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateCustomerKeywordResponse = {
  __typename?: "CreateCustomerKeywordResponse";
  customerKeyword: CustomerKeyword;
};

export type CreateCustomerLinkBuildInput = {
  customerId: Scalars["Int"];
  orderId: Scalars["Int"];
  summary: Scalars["String"];
};

export type CreateCustomerLinkBuildResponse = {
  __typename?: "CreateCustomerLinkBuildResponse";
  customerLinkBuild?: Maybe<CustomerLinkBuild>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerMessageInput = {
  content: Scalars["String"];
  conversationId: Scalars["String"];
};

export type CreateCustomerMessageResponse = {
  __typename?: "CreateCustomerMessageResponse";
  customerMessage: CustomerMessage;
};

export type CreateCustomerNoteInput = {
  content: Scalars["String"];
  customerId: Scalars["Int"];
};

export type CreateCustomerPremiumBusinessListingSpecImageInput = {
  customerId: Scalars["Int"];
  fileId: Scalars["String"];
  imageTypeId: Scalars["String"];
};

export type CreateCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "CreateCustomerPremiumBusinessListingSpecImageResponse";
  customerPremiumBusinessListingSpecImage: CustomerPremiumBusinessListingSpecImage;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerResponse = {
  __typename?: "CreateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type CreateCustomerSiteAuditInput = {
  customerId: Scalars["Int"];
  fileId?: InputMaybe<Scalars["String"]>;
  orderId: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerSiteAuditResponse = {
  __typename?: "CreateCustomerSiteAuditResponse";
  customerSiteAudit?: Maybe<CustomerSiteAudit>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerStatusChangeInput = {
  customerId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  reason?: InputMaybe<Scalars["String"]>;
  statusId: Scalars["String"];
};

export type CreateCustomerStatusChangeResponse = {
  __typename?: "CreateCustomerStatusChangeResponse";
  customerStatusChange: CustomerStatusChange;
};

export type CreateCustomerUserInput = {
  customerId: Scalars["Int"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateCustomerUserResponse = {
  __typename?: "CreateCustomerUserResponse";
  customerUser?: Maybe<CustomerUser>;
};

export type CreateCustomerUserSsoCodeInput = {
  customerUserId: Scalars["Int"];
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CreateCustomerUserSsoCodeResponse = {
  __typename?: "CreateCustomerUserSsoCodeResponse";
  customerUserSsoCode: CustomerUserSsoCode;
};

export type CreateDomainCategoryInput = {
  categoryId: Scalars["Int"];
  domainId: Scalars["String"];
};

export type CreateDomainCategoryResponse = {
  __typename?: "CreateDomainCategoryResponse";
  domainCategory?: Maybe<DomainCategory>;
};

export type CreateDomainInput = {
  id: Scalars["String"];
};

export type CreateDomainResponse = {
  __typename?: "CreateDomainResponse";
  domain?: Maybe<Domain>;
};

export type CreateDomainsInput = {
  ids: Array<Scalars["String"]>;
};

export type CreateDomainsResponse = {
  __typename?: "CreateDomainsResponse";
  domains: Array<Domain>;
};

export type CreateFeedbackContentItemInput = {
  contentItemId: Scalars["Int"];
  feedback?: InputMaybe<Scalars["String"]>;
  rating: Scalars["Int"];
};

export type CreateFeedbackContentItemResponse = {
  __typename?: "CreateFeedbackContentItemResponse";
  error?: Maybe<ResponseError>;
  feedbackContentItem?: Maybe<FeedbackContentItem>;
  ok: Scalars["Boolean"];
};

export type CreateFulfillmentRuleInput = {
  partnerId?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  vendorId: Scalars["Int"];
};

export type CreateFulfillmentRuleResponse = {
  __typename?: "CreateFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  fulfillmentRule?: Maybe<FulfillmentRule>;
  ok: Scalars["Boolean"];
};

export type CreateLinkLocationInput = {
  allowsCbdContent?: InputMaybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: InputMaybe<Scalars["Boolean"]>;
  authorityScore?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  contactPhone?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  internalQuality?: InputMaybe<Scalars["Int"]>;
  minimumWordCount?: InputMaybe<Scalars["Int"]>;
  monthlyTraffic?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  spam?: InputMaybe<Scalars["Int"]>;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CreateLinkLocationResponse = {
  __typename?: "CreateLinkLocationResponse";
  error?: Maybe<ResponseError>;
  linkLocation?: Maybe<LinkLocation>;
  ok: Scalars["Boolean"];
};

export type CreateNoteResponse = {
  __typename?: "CreateNoteResponse";
  error?: Maybe<ResponseError>;
  note?: Maybe<Note>;
  ok: Scalars["Boolean"];
};

export type CreateOrderExternalContentSpecInput = {
  cost: Scalars["Float"];
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  externalOrderId: Scalars["String"];
  orderId: Scalars["Int"];
  submittedAt: Scalars["DateTime"];
  trustFlow?: InputMaybe<Scalars["Int"]>;
  vendorId: Scalars["Int"];
};

export type CreateOrderExternalContentSpecResponse = {
  __typename?: "CreateOrderExternalContentSpecResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
};

export type CreateOrderHaloKeywordInput = {
  customerKeywordId: Scalars["Int"];
  customerProposalId?: InputMaybe<Scalars["Int"]>;
  isProposed: Scalars["Boolean"];
  orderId: Scalars["Int"];
};

export type CreateOrderHaloKeywordResponse = {
  __typename?: "CreateOrderHaloKeywordResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderHaloKeyword?: Maybe<OrderHaloKeyword>;
};

export type CreateOrderInput = {
  customerCampaignId: Scalars["Int"];
  customerId: Scalars["Int"];
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  partnerProductId: Scalars["Int"];
  quantity?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  startImmediately?: InputMaybe<Scalars["Boolean"]>;
  topic?: InputMaybe<Scalars["String"]>;
  trackingId?: InputMaybe<Scalars["String"]>;
};

export type CreateOrderResponse = {
  __typename?: "CreateOrderResponse";
  order?: Maybe<Order>;
};

export type CreateOrdersInput = {
  orders?: InputMaybe<Array<OrderInput>>;
};

export type CreateOrdersResponse = {
  __typename?: "CreateOrdersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type CreatePartnerAvailableProductInput = {
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesalePrice: Scalars["Float"];
};

export type CreatePartnerAvailableProductResponse = {
  __typename?: "CreatePartnerAvailableProductResponse";
  partnerAvailableProduct: PartnerAvailableProduct;
};

export type CreatePartnerBillingContactInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreatePartnerBillingContactResponse = {
  __typename?: "CreatePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerBillingContact?: Maybe<PartnerBillingContact>;
};

export type CreatePartnerInput = {
  billingEmail?: InputMaybe<Scalars["String"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired: Scalars["Boolean"];
  name: Scalars["String"];
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePartnerInvoiceInput = {
  orderIds: Array<Scalars["Int"]>;
  partnerId: Scalars["Int"];
};

export type CreatePartnerInvoiceResponse = {
  __typename?: "CreatePartnerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerInvoice?: Maybe<PartnerInvoice>;
};

export type CreatePartnerPackageInput = {
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreatePartnerPackageItemInput = {
  isRecurring: Scalars["Boolean"];
  monthIndex: Scalars["Int"];
  partnerPackageId: Scalars["Int"];
  partnerProductId: Scalars["Int"];
  quantity: Scalars["Int"];
};

export type CreatePartnerPackageItemResponse = {
  __typename?: "CreatePartnerPackageItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackageItem?: Maybe<PartnerPackageItem>;
};

export type CreatePartnerPackageResponse = {
  __typename?: "CreatePartnerPackageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackage?: Maybe<PartnerPackage>;
};

export type CreatePartnerPaymentMethodSetupIntentInput = {
  partnerId: Scalars["Int"];
};

export type CreatePartnerPaymentMethodSetupIntentResponse = {
  __typename?: "CreatePartnerPaymentMethodSetupIntentResponse";
  clientSecret?: Maybe<Scalars["String"]>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreatePartnerProductInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]>;
  checklistDefinitionId?: InputMaybe<Scalars["Int"]>;
  inactiveAt?: InputMaybe<Scalars["DateTime"]>;
  initialPrice: Scalars["Float"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePartnerProductPriceInput = {
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type CreatePartnerProductPriceResponse = {
  __typename?: "CreatePartnerProductPriceResponse";
  partnerProductPrice: PartnerProductPrice;
};

export type CreatePartnerProductResponse = {
  __typename?: "CreatePartnerProductResponse";
  partnerProduct?: Maybe<PartnerProduct>;
};

export type CreatePartnerProductWholesalePriceInput = {
  effectiveAt: Scalars["DateTime"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesaleAmount: Scalars["Float"];
};

export type CreatePartnerProductWholesalePriceResponse = {
  __typename?: "CreatePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
};

export type CreatePartnerResponse = {
  __typename?: "CreatePartnerResponse";
  partner?: Maybe<Partner>;
};

export type CreatePartnerTaskTypeVendorInput = {
  partnerId: Scalars["Int"];
  taskTypeId: Scalars["String"];
  vendorId: Scalars["Int"];
};

export type CreatePartnerTaskTypeVendorResponse = {
  __typename?: "CreatePartnerTaskTypeVendorResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerTaskTypeVendor?: Maybe<PartnerTaskTypeVendor>;
};

export type CreatePartnerUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAdmin: Scalars["Boolean"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
  password?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePartnerUserResponse = {
  __typename?: "CreatePartnerUserResponse";
  partnerUser?: Maybe<PartnerUser>;
};

export type CreateProposedCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  customerKeywordProposalId: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateProposedCustomerKeywordResponse = {
  __typename?: "CreateProposedCustomerKeywordResponse";
  proposedCustomerKeyword: ProposedCustomerKeyword;
};

export type CreatePublisherInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isInvoiceRequired?: InputMaybe<Scalars["Boolean"]>;
  isSlowPartner?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  paymentMethod?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePublisherLinkLocationInput = {
  isRCMPublishing: Scalars["Boolean"];
  linkLocationId: Scalars["Int"];
  publisherId: Scalars["Int"];
};

export type CreatePublisherLinkLocationResponse = {
  __typename?: "CreatePublisherLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
};

export type CreatePublisherPaymentInput = {
  amount: Scalars["Float"];
  orderId: Scalars["Int"];
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["String"]>;
};

export type CreatePublisherPaymentResponse = {
  __typename?: "CreatePublisherPaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherPayment: PublisherPayment;
};

export type CreatePublisherResponse = {
  __typename?: "CreatePublisherResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisher?: Maybe<Publisher>;
};

export type CreatePublisherUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePublisherUserResponse = {
  __typename?: "CreatePublisherUserResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherUser: PublisherUser;
};

export type CreateSiteInput = {
  id: Scalars["String"];
};

export type CreateSitePostInput = {
  categoryId?: InputMaybe<Scalars["Int"]>;
  content: Scalars["String"];
  siteId?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CreateSitePostResponse = {
  __typename?: "CreateSitePostResponse";
  sitePost: SitePost;
};

export type CreateSiteResponse = {
  __typename?: "CreateSiteResponse";
  site: Site;
};

export type CreateUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  isSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
};

export type CreateUserInteractionEventInput = {
  entityId?: InputMaybe<Scalars["Int"]>;
  entityName: Scalars["String"];
  eventData?: InputMaybe<Scalars["String"]>;
  eventType: Scalars["String"];
  userId: Scalars["Int"];
};

export type CreateUserInteractionEventResponse = {
  __typename?: "CreateUserInteractionEventResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type CreateUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type CreateUserProfessionalResponse = {
  __typename?: "CreateUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userProfessional: UserProfessional;
};

export type CreateUserResponse = {
  __typename?: "CreateUserResponse";
  user?: Maybe<User>;
};

export type CreateUserTaskPermissionInput = {
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type CreateUserTaskPermissionResponse = {
  __typename?: "CreateUserTaskPermissionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userTaskPermission?: Maybe<UserTaskPermission>;
};

export type CreateVendorInput = {
  isExternal?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  partnerId?: InputMaybe<Scalars["Int"]>;
};

export type CreateVendorResponse = {
  __typename?: "CreateVendorResponse";
  vendor?: Maybe<Vendor>;
};

export type CreateVendorUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  vendorId: Scalars["Int"];
};

export type CreateVendorUserResponse = {
  __typename?: "CreateVendorUserResponse";
  vendorUser?: Maybe<VendorUser>;
};

export type CreateWorkflowNoteInput = {
  content: Scalars["String"];
  workflowId: Scalars["Int"];
};

export type CreateWritingSampleTopicInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["Int"];
  isActive: Scalars["Boolean"];
  primaryKeyword: Scalars["String"];
  topic: Scalars["String"];
  voice: Scalars["String"];
  website: Scalars["String"];
};

export type CreateWritingSampleTopicResponse = {
  __typename?: "CreateWritingSampleTopicResponse";
  writingSampleTopic: WritingSampleTopic;
};

export type CreativesScaleConfiguration = {
  __typename?: "CreativesScaleConfiguration";
  workInterests?: Maybe<Array<WorkInterest>>;
};

export type CreativesScaleTaskStatus = {
  __typename?: "CreativesScaleTaskStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CreativesScaleUser = {
  __typename?: "CreativesScaleUser";
  activeUserGrammarTest?: Maybe<UserGrammarTest>;
  activeWritingSample?: Maybe<UserWritingSample>;
  allTimeEarnedAmount: Scalars["Float"];
  avgStarRating: Scalars["Float"];
  countryOfResidence?: Maybe<Scalars["String"]>;
  countryOfResidenceCountries: Array<Country>;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isAdmin: Scalars["Boolean"];
  isEligibleForPayments: Scalars["Boolean"];
  isRCMWriter: Scalars["Boolean"];
  isStripeOnboardingReminderEnabled: Scalars["Boolean"];
  latestUserGrammarTest?: Maybe<UserGrammarTest>;
  latestWritingSample?: Maybe<UserWritingSample>;
  onboardingStatus: OnboardingStatus;
  onboardingStatusId: Scalars["String"];
  onboardingStatusLastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  optedInForUpdatesAt?: Maybe<Scalars["DateTime"]>;
  payments: CreativesScaleUserPaymentConnection;
  retryGrammarTestAt?: Maybe<Scalars["DateTime"]>;
  retryWritingSampleAt?: Maybe<Scalars["DateTime"]>;
  stripeAccountId?: Maybe<Scalars["String"]>;
  stripeAccountStatus: StripeOnboardingStatus;
  unpaidBalance: Scalars["Float"];
  user: User;
  userId: Scalars["Int"];
  workInterests: Array<WorkInterest>;
  writerClassification?: Maybe<Scalars["String"]>;
  writingSampleTopics: Array<WritingSampleTopic>;
};

export type CreativesScaleUserPaymentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PaymentsSort>;
};

export type CreativesScaleUserConnection = {
  __typename?: "CreativesScaleUserConnection";
  nodes: Array<CreativesScaleUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreativesScaleUserPayment = {
  __typename?: "CreativesScaleUserPayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  creativesScaleUser: CreativesScaleUser;
  creativesScaleUserId: Scalars["Int"];
  externalPaymentId?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  paymentBatchId?: Maybe<Scalars["Int"]>;
  paymentTypeId: Scalars["String"];
  status: CreativesScaleUserPaymentStatus;
  statusId: Scalars["String"];
  statusIdUpdatedAt: Scalars["DateTime"];
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars["Int"]>;
};

export type CreativesScaleUserPaymentConnection = {
  __typename?: "CreativesScaleUserPaymentConnection";
  nodes: Array<CreativesScaleUserPayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreativesScaleUserPaymentStatus = {
  __typename?: "CreativesScaleUserPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  accountManagerUser?: Maybe<User>;
  accountManagerUserId?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: Maybe<Scalars["String"]>;
  averageRanking?: Maybe<Scalars["Float"]>;
  billableOrders: OrderConnection;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentCustomerCampaign?: Maybe<CustomerCampaign>;
  customerBillingConfig?: Maybe<CustomerBillingConfig>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerBusinessListingSpecs: CustomerBusinessListingSpecConnection;
  customerCampaigns: CustomerCampaignConnection;
  customerHoursOfOperationItems?: Maybe<Array<CustomerHoursOfOperationItem>>;
  customerInvoices?: Maybe<CustomerInvoiceConnection>;
  customerKeywords: CustomerKeywordConnection;
  customerPremiumBusinessListingSpec?: Maybe<CustomerPremiumBusinessListingSpec>;
  customerPremiumBusinessListingSpecImages: CustomerPremiumBusinessListingSpecImageConnection;
  customerQuestionnaire?: Maybe<CustomerQuestionnaire>;
  customerSiteCredential?: Maybe<CustomerSiteCredential>;
  customerStatusChanges: CustomerStatusChangeConnection;
  customerUsers: CustomerUserConnection;
  emailMessages?: Maybe<EmailMessageConnection>;
  gbpUrl?: Maybe<Scalars["String"]>;
  hasMapRanking: Scalars["Boolean"];
  hasPremiumListing: Scalars["Boolean"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isBillingVisible: Scalars["Boolean"];
  isBlogFulfilledByRCMWriter: Scalars["Boolean"];
  isGuestArticleFulfilledByRCMWriter: Scalars["Boolean"];
  keywordsImproved?: Maybe<Scalars["Int"]>;
  keywordsOnFirstPage?: Maybe<Scalars["Int"]>;
  linkLocationsPending: LinkLocationConnection;
  linkLocationsRejected: LinkLocationConnection;
  linkLocationsUsed: LinkLocationConnection;
  name: Scalars["String"];
  notes: NoteConnection;
  partner: Partner;
  partnerId: Scalars["Int"];
  phoneNumber?: Maybe<Scalars["String"]>;
  portal: Scalars["String"];
  rcmAccountManagerUser?: Maybe<User>;
  rcmAccountManagerUserId?: Maybe<Scalars["Int"]>;
  status: CustomerStatus;
  uberallBusinessId?: Maybe<Scalars["Int"]>;
  website: Scalars["String"];
};

export type CustomerAverageRankingArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerBillableOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
};

export type CustomerCurrentCustomerCampaignArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerBusinessListingSpecsArgs = {
  isListed?: InputMaybe<Scalars["Boolean"]>;
};

export type CustomerCustomerCampaignsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerInvoicesArgs = {
  invoiceDate?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerInvoiceSort>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CustomerCustomerKeywordsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerCustomerPremiumBusinessListingSpecImagesArgs = {
  imageTypeId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerStatusChangesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  processedAt?: InputMaybe<DateQuery>;
  sort?: InputMaybe<CustomerStatusChangeSort>;
};

export type CustomerCustomerUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerUsersSort>;
};

export type CustomerEmailMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerKeywordsImprovedArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerKeywordsOnFirstPageArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerLinkLocationsPendingArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerLinkLocationsRejectedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerLinkLocationsUsedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerNotesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerBillingConfig = {
  __typename?: "CustomerBillingConfig";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  isAutoPayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
};

export type CustomerBillingContact = {
  __typename?: "CustomerBillingContact";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
};

export type CustomerBusinessListingSpec = {
  __typename?: "CustomerBusinessListingSpec";
  address: Scalars["String"];
  addressDisplay: Scalars["Boolean"];
  city: Scalars["String"];
  contactName?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  email?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  hasPhysicalStorefront: Scalars["Boolean"];
  id: Scalars["Int"];
  isListed: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  stateOrProvince: Scalars["String"];
  uberallCategoryId?: Maybe<Scalars["Int"]>;
  uberallListingDetails?: Maybe<UberallListingDetailsResponse>;
  uberallLocationId?: Maybe<Scalars["Int"]>;
  uberallSyncNeeded?: Maybe<Scalars["Boolean"]>;
  zipPostalCode: Scalars["String"];
};

export type CustomerBusinessListingSpecConnection = {
  __typename?: "CustomerBusinessListingSpecConnection";
  nodes: Array<CustomerBusinessListingSpec>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaign = {
  __typename?: "CustomerCampaign";
  addedByUserId: Scalars["Int"];
  amount: Scalars["Float"];
  campaignCategory: CampaignCategory;
  campaignCategoryId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerCampaignInvoiceCharges?: Maybe<Array<CustomerCampaignInvoiceCharge>>;
  customerId: Scalars["Int"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  name: Scalars["String"];
  pausedAt?: Maybe<Scalars["DateTime"]>;
  startsAt: Scalars["DateTime"];
};

export type CustomerCampaignConnection = {
  __typename?: "CustomerCampaignConnection";
  nodes: Array<CustomerCampaign>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoice = {
  __typename?: "CustomerCampaignInvoice";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: CustomerCampaignInvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerCampaignInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceCharge = {
  __typename?: "CustomerCampaignInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  monthIndex: Scalars["Int"];
  status: CustomerCampaignInvoiceChargeStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceChargeStatus = {
  __typename?: "CustomerCampaignInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerCampaignInvoiceConnection = {
  __typename?: "CustomerCampaignInvoiceConnection";
  nodes: Array<CustomerCampaignInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoiceData = {
  __typename?: "CustomerCampaignInvoiceData";
  invoiceCharges: Array<CustomerCampaignInvoiceCharge>;
  invoicePayments: Array<CustomerCampaignInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
};

export type CustomerCampaignInvoicePayment = {
  __typename?: "CustomerCampaignInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerCampaignInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoicePaymentStatus = {
  __typename?: "CustomerCampaignInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum CustomerCampaignInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerCampaignInvoiceStatus = {
  __typename?: "CustomerCampaignInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  nodes: Array<Customer>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerConversation = {
  __typename?: "CustomerConversation";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerMessages: CustomerMessageConnection;
  helpRequestedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  subject: Scalars["String"];
};

export type CustomerConversationCustomerMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CustomerMessagesSort>;
};

export type CustomerConversationConnection = {
  __typename?: "CustomerConversationConnection";
  nodes: Array<CustomerConversation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerConversationsSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type CustomerHoursOfOperationItem = {
  __typename?: "CustomerHoursOfOperationItem";
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  id: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CustomerInvoice = {
  __typename?: "CustomerInvoice";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: InvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoiceCharge = {
  __typename?: "CustomerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  status: CustomerInvoiceChargeStatus;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CustomerInvoiceChargeStatus = {
  __typename?: "CustomerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceConnection = {
  __typename?: "CustomerInvoiceConnection";
  nodes: Array<CustomerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerInvoicePayment = {
  __typename?: "CustomerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoicePaymentStatus = {
  __typename?: "CustomerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceRefund = {
  __typename?: "CustomerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  customerInvoicePaymentId: Scalars["Int"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  statusId: Scalars["String"];
};

export enum CustomerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerInvoiceStatus = {
  __typename?: "CustomerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerKeyword = {
  __typename?: "CustomerKeyword";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  displayName: Scalars["String"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  isTrackingMapRank: Scalars["Boolean"];
  isTrackingRank: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  latestRank?: Maybe<CustomerKeywordRanking>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  startRank?: Maybe<CustomerKeywordRanking>;
};

export type CustomerKeywordLatestRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordStartRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordConnection = {
  __typename?: "CustomerKeywordConnection";
  nodes: Array<CustomerKeyword>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerKeywordProposal = {
  __typename?: "CustomerKeywordProposal";
  createdAt: Scalars["DateTime"];
  customerConversationId?: Maybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerKeywordProposalProposedCustomerKeywordsArgs = {
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isRejected?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<ProposedCustomerKeywordSort>;
};

export type CustomerKeywordRanking = {
  __typename?: "CustomerKeywordRanking";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  rank: Scalars["Int"];
  searchEngine: Scalars["String"];
};

export type CustomerKeywordRankingConnection = {
  __typename?: "CustomerKeywordRankingConnection";
  nodes: Array<CustomerKeywordRanking>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerKeywordRankingSort {
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerLinkBuild = {
  __typename?: "CustomerLinkBuild";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerMessage = {
  __typename?: "CustomerMessage";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerConversation: CustomerConversation;
  id: Scalars["Int"];
  isFromCustomer: Scalars["Boolean"];
  user: User;
};

export type CustomerMessageConnection = {
  __typename?: "CustomerMessageConnection";
  nodes: Array<CustomerMessage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerMessagesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type CustomerPaymentMethod = {
  __typename?: "CustomerPaymentMethod";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  type: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpec = {
  __typename?: "CustomerPremiumBusinessListingSpec";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  hotelAmenities: Array<Scalars["String"]>;
  hotelBathroom?: Maybe<Scalars["String"]>;
  hotelFrontDesk?: Maybe<Scalars["String"]>;
  hotelHousekeeping?: Maybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: Maybe<Scalars["String"]>;
  hotelMinimumStay?: Maybe<Scalars["String"]>;
  hotelOnSiteStaff?: Maybe<Scalars["Boolean"]>;
  hotelSecurity?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAlwaysOpen?: Maybe<Scalars["Boolean"]>;
  isBlackOwned?: Maybe<Scalars["Boolean"]>;
  isWomenOwned?: Maybe<Scalars["Boolean"]>;
  priceRangeLevel?: Maybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: Maybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: Maybe<Scalars["String"]>;
  yearsInBusiness?: Maybe<Scalars["Int"]>;
};

export type CustomerPremiumBusinessListingSpecImage = {
  __typename?: "CustomerPremiumBusinessListingSpecImage";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file: File;
  fileId: Scalars["String"];
  id: Scalars["Int"];
  imageTypeId: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpecImageConnection = {
  __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
  nodes: Array<CustomerPremiumBusinessListingSpecImage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerProposal = {
  __typename?: "CustomerProposal";
  id: Scalars["Int"];
  orderContentStrategies: Array<OrderContentStrategy>;
  orderHaloKeywords: Array<OrderHaloKeyword>;
  proposedOrderTopics: Array<ProposedOrderTopic>;
  summary?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CustomerProposalOrderContentStrategiesArgs = {
  sort?: InputMaybe<OrderContentStrategiesSort>;
};

export type CustomerQuestionnaire = {
  __typename?: "CustomerQuestionnaire";
  businessDescription?: Maybe<Scalars["String"]>;
  callToActionUrls?: Maybe<Scalars["String"]>;
  competitorWebsites?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  contentPreference?: Maybe<Scalars["String"]>;
  contentPublishingPermissions?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerMarketSize?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAddImage?: Maybe<Scalars["Boolean"]>;
  isRCMPublishing?: Maybe<Scalars["Boolean"]>;
  preferredVoice?: Maybe<Scalars["String"]>;
  productsOffered?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  serviceArea?: Maybe<Scalars["String"]>;
  targetArea?: Maybe<Scalars["String"]>;
  targetAudience?: Maybe<Scalars["String"]>;
  targetInternalLinks?: Maybe<Scalars["String"]>;
  targetKeywords?: Maybe<Scalars["String"]>;
  websiteCms?: Maybe<Scalars["String"]>;
  websiteHosting?: Maybe<Scalars["String"]>;
  whoCanChangeWebsite?: Maybe<Scalars["String"]>;
};

export type CustomerSiteAudit = {
  __typename?: "CustomerSiteAudit";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file?: Maybe<File>;
  fileId?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerSiteCredential = {
  __typename?: "CustomerSiteCredential";
  adminUrl: Scalars["String"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  user: Scalars["String"];
};

export type CustomerStatus = {
  __typename?: "CustomerStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerStatusChange = {
  __typename?: "CustomerStatusChange";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  customer: Customer;
  customerId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  id: Scalars["Int"];
  reason?: Maybe<Scalars["String"]>;
  status: CustomerStatus;
  statusId: Scalars["String"];
};

export type CustomerStatusChangeConnection = {
  __typename?: "CustomerStatusChangeConnection";
  nodes: Array<CustomerStatusChange>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerStatusChangeSort {
  EffectiveAtAsc = "EFFECTIVE_AT_ASC",
  EffectiveAtDesc = "EFFECTIVE_AT_DESC",
}

export type CustomerUser = {
  __typename?: "CustomerUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  customerUserSsoCodes: CustomerUserSsoCodeConnection;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  user: User;
  userId: Scalars["Int"];
};

export type CustomerUserCustomerUserSsoCodesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerUserConnection = {
  __typename?: "CustomerUserConnection";
  nodes: Array<CustomerUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerUserSsoCode = {
  __typename?: "CustomerUserSsoCode";
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerUserId: Scalars["Int"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  url: Scalars["String"];
};

export type CustomerUserSsoCodeConnection = {
  __typename?: "CustomerUserSsoCodeConnection";
  nodes: Array<CustomerUserSsoCode>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerUsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export enum CustomersKeywordSort {
  CurrentRankAsc = "CURRENT_RANK_ASC",
  CurrentRankDesc = "CURRENT_RANK_DESC",
  IsPrimaryAsc = "IS_PRIMARY_ASC",
  IsPrimaryDesc = "IS_PRIMARY_DESC",
  LatestRankAsc = "LATEST_RANK_ASC",
  LatestRankDesc = "LATEST_RANK_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export enum CustomersSort {
  EndDateAsc = "END_DATE_ASC",
  EndDateDesc = "END_DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type DateQuery = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  orIsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type DeleteAmTaskInput = {
  amTaskId: Scalars["Int"];
};

export type DeleteAmTaskResponse = {
  __typename?: "DeleteAmTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerCampaignInput = {
  customerCampaignId: Scalars["Int"];
};

export type DeleteCustomerCampaignInvoiceInput = {
  customerCampaignInvoiceId: Scalars["Int"];
};

export type DeleteCustomerCampaignInvoiceResponse = {
  __typename?: "DeleteCustomerCampaignInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerCampaignResponse = {
  __typename?: "DeleteCustomerCampaignResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageInput = {
  customerPremiumBusinessListingSpecImageId: Scalars["Int"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "DeleteCustomerPremiumBusinessListingSpecImageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerStatusChangeInput = {
  customerStatusChangeId: Scalars["Int"];
};

export type DeleteCustomerStatusChangeResponse = {
  __typename?: "DeleteCustomerStatusChangeResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteDomainCategoryInput = {
  id: Scalars["Int"];
};

export type DeleteDomainCategoryResponse = {
  __typename?: "DeleteDomainCategoryResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteFulfillmentRuleInput = {
  fulfillmentRuleId: Scalars["Int"];
};

export type DeleteFulfillmentRuleResponse = {
  __typename?: "DeleteFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteLinkLocationInput = {
  linkLocationId: Scalars["Int"];
};

export type DeleteLinkLocationResponse = {
  __typename?: "DeleteLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteOrderContentStrategyInput = {
  orderContentStrategyId: Scalars["Int"];
};

export type DeleteOrderContentStrategyResponse = {
  __typename?: "DeleteOrderContentStrategyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteOrderInput = {
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  orderId: Scalars["Int"];
};

export type DeleteOrderResponse = {
  __typename?: "DeleteOrderResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeletePartnerBillingContactInput = {
  partnerBillingContactId: Scalars["Int"];
};

export type DeletePartnerBillingContactResponse = {
  __typename?: "DeletePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerPaymentMethodInput = {
  partnerPaymentMethodId: Scalars["Int"];
};

export type DeletePartnerPaymentMethodResponse = {
  __typename?: "DeletePartnerPaymentMethodResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerProductInput = {
  partnerProductId: Scalars["Int"];
};

export type DeletePartnerProductPriceInput = {
  partnerProductPriceId: Scalars["Int"];
};

export type DeletePartnerProductPriceResponse = {
  __typename?: "DeletePartnerProductPriceResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeletePartnerProductResponse = {
  __typename?: "DeletePartnerProductResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerProductWholesalePriceInput = {
  partnerProductWholesalePriceId: Scalars["Int"];
};

export type DeletePartnerProductWholesalePriceResponse = {
  __typename?: "DeletePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerTaskTypeVendorInput = {
  partnerTaskTypeVendorId: Scalars["Int"];
};

export type DeletePartnerTaskTypeVendorResponse = {
  __typename?: "DeletePartnerTaskTypeVendorResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteProposedCustomerKeywordInput = {
  proposedCustomerKeywordId: Scalars["Int"];
};

export type DeleteProposedCustomerKeywordResponse = {
  __typename?: "DeleteProposedCustomerKeywordResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteSitePostInput = {
  sitePostId: Scalars["Int"];
};

export type DeleteSitePostResponse = {
  __typename?: "DeleteSitePostResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteUserTaskPermissionInput = {
  userTaskPermissionId: Scalars["Int"];
};

export type DeleteUserTaskPermissionResponse = {
  __typename?: "DeleteUserTaskPermissionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Domain = {
  __typename?: "Domain";
  availabilityLastCheckedAt?: Maybe<Scalars["DateTime"]>;
  canRefreshAvailability: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  domainCategories: DomainCategoryConnection;
  expiresAt?: Maybe<Scalars["Date"]>;
  id: Scalars["String"];
  purchaseError?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["Float"]>;
  purchaseRequestedByUser?: Maybe<User>;
  purchaseRequestedByUserId?: Maybe<Scalars["Int"]>;
  purchasedAt?: Maybe<Scalars["DateTime"]>;
  registrar?: Maybe<Scalars["String"]>;
  registrarId?: Maybe<Scalars["String"]>;
  site?: Maybe<Site>;
  status: DomainStatus;
};

export type DomainDomainCategoriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type DomainCategory = {
  __typename?: "DomainCategory";
  category?: Maybe<Category>;
  categoryId: Scalars["Int"];
  domain?: Maybe<Domain>;
  domainId: Scalars["String"];
  id: Scalars["Int"];
};

export type DomainCategoryConnection = {
  __typename?: "DomainCategoryConnection";
  nodes: Array<DomainCategory>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type DomainConnection = {
  __typename?: "DomainConnection";
  nodes: Array<Domain>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type DomainMetrics = {
  __typename?: "DomainMetrics";
  authorityScore: Scalars["Int"];
  domainAuthority: Scalars["Int"];
  organitcTraffic: Scalars["Int"];
  spamScore: Scalars["Int"];
};

export type DomainMetricsRespose = {
  __typename?: "DomainMetricsRespose";
  domainMetrics?: Maybe<DomainMetrics>;
};

export type DomainStatus = {
  __typename?: "DomainStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum DomainsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type EmailMessage = {
  __typename?: "EmailMessage";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId?: Maybe<Scalars["Int"]>;
  fromAddress: Scalars["String"];
  fromName: Scalars["String"];
  id: Scalars["Int"];
  partnerId?: Maybe<Scalars["Int"]>;
  sentAt?: Maybe<Scalars["DateTime"]>;
  subject: Scalars["String"];
  toAddress: Scalars["String"];
  toName: Scalars["String"];
  userId?: Maybe<Scalars["Int"]>;
};

export type EmailMessageConnection = {
  __typename?: "EmailMessageConnection";
  nodes: Array<EmailMessage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type EnableAutopayInformation = {
  __typename?: "EnableAutopayInformation";
  dueImmediatelyAmount?: Maybe<Scalars["Float"]>;
  nextBillingAmount?: Maybe<Scalars["Float"]>;
  nextBillingDate?: Maybe<Scalars["DateTime"]>;
};

export type FeedbackContentItem = {
  __typename?: "FeedbackContentItem";
  contentItem: ContentItem;
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  feedback: Scalars["String"];
  id: Scalars["Int"];
  rating: Scalars["Int"];
  reviewUser: User;
  reviewerUserId: Scalars["Int"];
};

export type File = {
  __typename?: "File";
  contentType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
  url: Scalars["String"];
};

export type FulfillmentRule = {
  __typename?: "FulfillmentRule";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partnerId?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Vendor>;
  vendorId: Scalars["Int"];
};

export type FulfillmentRuleConnection = {
  __typename?: "FulfillmentRuleConnection";
  nodes: Array<FulfillmentRule>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type GbpItem = {
  __typename?: "GbpItem";
  content: Scalars["String"];
  id: Scalars["Int"];
  readMoreUrl?: Maybe<Scalars["String"]>;
  thumbnailFile?: Maybe<File>;
};

export type GenerateUrlForFileUploadInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadResponse = {
  __typename?: "GenerateUrlForFileUploadResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GenerateUrlForFileUploadSignupInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadSignupResponse = {
  __typename?: "GenerateUrlForFileUploadSignupResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GenerateUrlForStripeDashboardResponse = {
  __typename?: "GenerateUrlForStripeDashboardResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type GenerateUrlForStripeOnboardingResponse = {
  __typename?: "GenerateUrlForStripeOnboardingResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type GoogleAccount = {
  __typename?: "GoogleAccount";
  accountName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  verificationState?: Maybe<Scalars["String"]>;
  vettedState?: Maybe<Scalars["String"]>;
};

export type GoogleAccountsResponse = {
  __typename?: "GoogleAccountsResponse";
  accounts?: Maybe<Array<GoogleAccount>>;
};

export type GoogleLocation = {
  __typename?: "GoogleLocation";
  name?: Maybe<Scalars["String"]>;
  storeCode?: Maybe<Scalars["String"]>;
  storefrontAddress?: Maybe<PostalAddress>;
  title?: Maybe<Scalars["String"]>;
  websiteUri?: Maybe<Scalars["String"]>;
};

export type GoogleLocationsResponse = {
  __typename?: "GoogleLocationsResponse";
  locations?: Maybe<Array<GoogleLocation>>;
};

export type GoogleReview = {
  __typename?: "GoogleReview";
  comment?: Maybe<Scalars["String"]>;
  createTime?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reviewId?: Maybe<Scalars["String"]>;
  reviewReply?: Maybe<ReviewReply>;
  reviewer?: Maybe<Reviewer>;
  starRating?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type GoogleReviewReplyInput = {
  account?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  reviewId?: InputMaybe<Scalars["String"]>;
};

export type GoogleReviewReplyResponse = {
  __typename?: "GoogleReviewReplyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type GoogleReviewRespose = {
  __typename?: "GoogleReviewRespose";
  averageRating?: Maybe<Scalars["Float"]>;
  nextPageToken?: Maybe<Scalars["String"]>;
  reviews?: Maybe<Array<GoogleReview>>;
  totalReviewCount?: Maybe<Scalars["Int"]>;
};

export type GrammarTestQuestion = {
  __typename?: "GrammarTestQuestion";
  answers: Array<GrammarTestQuestionAnswer>;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  prompt: Scalars["String"];
  totalAttempts: Scalars["Int"];
  totalCorrectAttempts: Scalars["Int"];
};

export type GrammarTestQuestionAnswer = {
  __typename?: "GrammarTestQuestionAnswer";
  content: Scalars["String"];
  grammarTestQuestionId: Scalars["Int"];
  id: Scalars["Int"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
};

export type GrammarTestQuestionAnswerInput = {
  content: Scalars["String"];
  id?: InputMaybe<Scalars["Int"]>;
  isCorrect: Scalars["Boolean"];
};

export type GrammarTestQuestionConnection = {
  __typename?: "GrammarTestQuestionConnection";
  nodes: Array<GrammarTestQuestion>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ImportInvoicesInput = {
  content: Scalars["String"];
};

export type ImportInvoicesResponse = {
  __typename?: "ImportInvoicesResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  results: Array<ImportInvoicesResult>;
};

export type ImportInvoicesResult = {
  __typename?: "ImportInvoicesResult";
  message: Scalars["String"];
  ok: Scalars["Boolean"];
  orderId: Scalars["Int"];
};

export type InputFieldError = {
  __typename?: "InputFieldError";
  fieldName: Scalars["String"];
  message: Scalars["String"];
};

export type InvoiceData = {
  __typename?: "InvoiceData";
  invoiceCharges: Array<CustomerInvoiceCharge>;
  invoicePayments: Array<CustomerInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type KeywordLocation = {
  __typename?: "KeywordLocation";
  country_iso_code: Scalars["String"];
  location_code: Scalars["Int"];
  location_code_parent?: Maybe<Scalars["Int"]>;
  location_name: Scalars["String"];
  location_type: Scalars["String"];
};

export type KeywordSuggestion = {
  __typename?: "KeywordSuggestion";
  avgMonthlySearches?: Maybe<Scalars["Int"]>;
  competitionIndex?: Maybe<Scalars["Int"]>;
  keyword: Scalars["String"];
};

export type KeywordsSuggestionRespose = {
  __typename?: "KeywordsSuggestionRespose";
  keywordSuggestions?: Maybe<Array<KeywordSuggestion>>;
};

export type LinkLocation = {
  __typename?: "LinkLocation";
  allowsCbdContent?: Maybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: Maybe<Scalars["Boolean"]>;
  authorityScore?: Maybe<Scalars["Int"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  contactPhone?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["DateTime"];
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  id: Scalars["Int"];
  internalQuality?: Maybe<Scalars["Int"]>;
  lastMetricsUpdatedAt?: Maybe<Scalars["DateTime"]>;
  linkLocationCategories: Array<LinkLocationCategory>;
  linkLocationCredential?: Maybe<LinkLocationCredential>;
  minimumWordCount?: Maybe<Scalars["Int"]>;
  monthlyTraffic?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  publishedOrders: OrderConnection;
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
  spam?: Maybe<Scalars["Int"]>;
  status: LinkLocationStatus;
  statusId: Scalars["String"];
  type: LinkLocationType;
  typeId: Scalars["String"];
};

export type LinkLocationCategory = {
  __typename?: "LinkLocationCategory";
  category: Category;
  categoryId: Scalars["Int"];
  id: Scalars["Int"];
  linkLocation: LinkLocation;
  linkLocationId: Scalars["Int"];
};

export type LinkLocationConnection = {
  __typename?: "LinkLocationConnection";
  nodes: Array<LinkLocation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type LinkLocationCredential = {
  __typename?: "LinkLocationCredential";
  adminUrl: Scalars["String"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type LinkLocationStatus = {
  __typename?: "LinkLocationStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type LinkLocationType = {
  __typename?: "LinkLocationType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum LinkLocationsSort {
  CostAsc = "cost_asc",
  CostDesc = "cost_desc",
  DaAsc = "da_asc",
  DaDesc = "da_desc",
  IdAsc = "id_asc",
  IdDesc = "id_desc",
  TrafficAsc = "traffic_asc",
  TrafficDesc = "traffic_desc",
}

export type LocationResponse = {
  __typename?: "LocationResponse";
  nodes: Array<KeywordLocation>;
};

export type LoginGoogleCallbackInput = {
  code: Scalars["String"];
};

export type LoginGoogleCallbackResponse = {
  __typename?: "LoginGoogleCallbackResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type LoginGoogleResponse = {
  __typename?: "LoginGoogleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  googleAccessToken?: InputMaybe<Scalars["String"]>;
  magicLink?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  ssoCode?: InputMaybe<Scalars["String"]>;
  temporaryLoginCode?: InputMaybe<Scalars["String"]>;
};

export type LoginUserResponse = {
  __typename?: "LoginUserResponse";
  isLoggedIn?: Maybe<Scalars["Boolean"]>;
};

export type LogoutUserResponse = {
  __typename?: "LogoutUserResponse";
  isLoggedOut: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  approvePaymentBatchFunding: ApprovePaymentBatchFundingResponse;
  approvePaymentBatchFundingPaypal: ApprovePaymentBatchFundingPaypalResponse;
  approveUserWritingSample: ApproveUserWritingSampleResponse;
  changePassword: ChangePasswordResponse;
  checkout: CheckoutResponse;
  claimTask: ClaimTaskResponse;
  clearProposedCustomerKeywordsRejection: ClearProposedCustomerKeywordsRejectionResponse;
  confirmEmail: ConfirmEmailResponse;
  createAmTask: CreateAmTaskResponse;
  createBlockDomainCustomers: CreateBlockDomainCustomersResponse;
  createBulkProposedCustomerKeywords: CreateBulkProposedCustomerKeywordsResponse;
  createBusinessSubscriptionOrders: CreateBusinessSubscriptionOrdersResponse;
  createCategory: CreateCategoryResponse;
  createChecklistDefinition: CreateChecklistDefinitionResponse;
  createCustomer: CreateCustomerResponse;
  createCustomerBusinessListingSpec: CreateCustomerBusinessListingSpecResponse;
  createCustomerCampaign: CreateCustomerCampaignResponse;
  createCustomerConversation: CreateCustomerConversationResponse;
  createCustomerInvoice: CreateCustomerInvoiceResponse;
  createCustomerKeyword: CreateCustomerKeywordResponse;
  createCustomerLinkBuild: CreateCustomerLinkBuildResponse;
  createCustomerMessage: CreateCustomerMessageResponse;
  createCustomerNote: CreateNoteResponse;
  createCustomerPremiumBusinessListingSpecImage: CreateCustomerPremiumBusinessListingSpecImageResponse;
  createCustomerSiteAudit: CreateCustomerSiteAuditResponse;
  createCustomerStatusChange: CreateCustomerStatusChangeResponse;
  createCustomerUser: CreateCustomerUserResponse;
  createCustomerUserSsoCode: CreateCustomerUserSsoCodeResponse;
  createDomain: CreateDomainResponse;
  createDomainCategory: CreateDomainCategoryResponse;
  createDomains: CreateDomainsResponse;
  createFeedbackContentItem: CreateFeedbackContentItemResponse;
  createFulfillmentRule: CreateFulfillmentRuleResponse;
  createLinkLocation: CreateLinkLocationResponse;
  createOrder: CreateOrderResponse;
  createOrderExternalContentSpec: CreateOrderExternalContentSpecResponse;
  createOrderHaloKeyword: CreateOrderHaloKeywordResponse;
  createOrders: CreateOrdersResponse;
  createPartner: CreatePartnerResponse;
  createPartnerAvailableProduct: CreatePartnerAvailableProductResponse;
  createPartnerBillingContact: CreatePartnerBillingContactResponse;
  createPartnerInvoice: CreatePartnerInvoiceResponse;
  createPartnerPackage: CreatePartnerPackageResponse;
  createPartnerPackageItem: CreatePartnerPackageItemResponse;
  createPartnerPaymentMethodSetupIntent: CreatePartnerPaymentMethodSetupIntentResponse;
  createPartnerProduct: CreatePartnerProductResponse;
  createPartnerProductPrice: CreatePartnerProductPriceResponse;
  createPartnerProductWholesalePrice: CreatePartnerProductWholesalePriceResponse;
  createPartnerTaskTypeVendor: CreatePartnerTaskTypeVendorResponse;
  createPartnerUser: CreatePartnerUserResponse;
  createProposedCustomerKeyword: CreateProposedCustomerKeywordResponse;
  createPublisher: CreatePublisherResponse;
  createPublisherLinkLocation: CreatePublisherLinkLocationResponse;
  createPublisherPayment: CreatePublisherPaymentResponse;
  createPublisherUser: CreatePublisherUserResponse;
  createSite: CreateSiteResponse;
  createSitePost: CreateSitePostResponse;
  createUser: CreateUserResponse;
  createUserInteractionEvent: CreateUserInteractionEventResponse;
  createUserProfessional: CreateUserProfessionalResponse;
  createUserTaskPermission: CreateUserTaskPermissionResponse;
  createVendor: CreateVendorResponse;
  createVendorUser: CreateVendorUserResponse;
  createWorkflowNote: CreateNoteResponse;
  createWritingSampleTopic: CreateWritingSampleTopicResponse;
  deleteAmTask: DeleteAmTaskResponse;
  deleteCustomerCampaign: DeleteCustomerCampaignResponse;
  deleteCustomerCampaignInvoice: DeleteCustomerCampaignInvoiceResponse;
  deleteCustomerPremiumBusinessListingSpecImage: DeleteCustomerPremiumBusinessListingSpecImageResponse;
  deleteCustomerStatusChange: DeleteCustomerStatusChangeResponse;
  deleteDomainCategory: DeleteDomainCategoryResponse;
  deleteFulfillmentRule: DeleteFulfillmentRuleResponse;
  deleteLinkLocation: DeleteLinkLocationResponse;
  deleteOrder: DeleteOrderResponse;
  deleteOrderContentStrategy: DeleteOrderContentStrategyResponse;
  deletePartnerBillingContact: DeletePartnerBillingContactResponse;
  deletePartnerPaymentMethod: DeletePartnerPaymentMethodResponse;
  deletePartnerProduct: DeletePartnerProductResponse;
  deletePartnerProductPrice: DeletePartnerProductPriceResponse;
  deletePartnerProductWholesalePrice: DeletePartnerProductWholesalePriceResponse;
  deletePartnerTaskTypeVendor: DeletePartnerTaskTypeVendorResponse;
  deleteProposedCustomerKeyword: DeleteProposedCustomerKeywordResponse;
  deleteSitePost: DeleteSitePostResponse;
  deleteUserTaskPermission: DeleteUserTaskPermissionResponse;
  generateUrlForFileUpload: GenerateUrlForFileUploadResponse;
  generateUrlForFileUploadSignup: GenerateUrlForFileUploadSignupResponse;
  generateUrlForStripeDashboard: GenerateUrlForStripeDashboardResponse;
  generateUrlForStripeOnboarding: GenerateUrlForStripeOnboardingResponse;
  googleReviewReply: GoogleReviewReplyResponse;
  importInvoices: ImportInvoicesResponse;
  loginGoogleCallback: LoginGoogleCallbackResponse;
  loginGoogleService: LoginGoogleResponse;
  loginUser: LoginUserResponse;
  logoutUser: LogoutUserResponse;
  payPartnerInvoice: PayPartnerInvoiceResponse;
  purchaseDomain: PurchaseDomainResponse;
  reCreateGuestOutreachTask: ReCreateGuestOutreachTaskResponse;
  recordCustomerCampaignInvoicePayment: RecordCustomerCampaignInvoicePaymentResponse;
  recordCustomerInvoicePayment: RecordCustomerInvoicePaymentResponse;
  recordPartnerInvoicePayment: RecordPartnerInvoicePaymentResponse;
  refreshDomainAvailability: RefreshDomainAvailabilityResponse;
  rejectUserWritingSample: RejectUserWritingSampleResponse;
  requestPasswordReset: RequestPasswordResetResponse;
  resendConfirmationEmail: ResendConfirmationEmailResponse;
  resetPassword: ResetPasswordResponse;
  resolveOrderContentStrategyReview: ResolveOrderContentStrategyReviewResponse;
  saveCustomerBillingConfig: SaveCustomerBillingConfigResponse;
  saveCustomerBusinessListingSpec: SaveCustomerBusinessListingSpecResponse;
  saveCustomerHoursOfOperationItems: SaveCustomerHoursOfOperationItemsResponse;
  saveCustomerPremiumBusinessListingSpec: SaveCustomerPremiumBusinessListingSpecResponse;
  saveCustomerQuestionnaire: SaveCustomerQuestionnaireResponse;
  saveCustomerSiteCredential: SaveCustomerSiteCredentialResponse;
  saveGrammarTestQuestion: SaveGrammarTestQuestionResponse;
  saveLinkLocationCredential: SaveLinkLocationCredentialResponse;
  saveOrderContentItem: SaveOrderContentItemResponse;
  savePartnerAnalyticCredential: SavePartnerAnalyticCredentialResponse;
  saveProposedOrderTopic: SaveProposedOrderTopicResponse;
  saveTaskContentIdeation: SaveTaskContentIdeationResponse;
  saveTaskContentItem: SaveTaskContentItemResponse;
  saveTaskGbpItem: SaveTaskGbpItemResponse;
  saveTaskTitleMetaDescriptions: SaveTaskTitleMetaDescriptionResponse;
  saveUserGrammarTestResponse: SaveUserGrammarTestResponseResponse;
  saveUserProfessional: SaveUserProfessionalResponse;
  saveUserWorkInterests: SaveUserWorkInterestsResponse;
  saveWritingSample: SaveWritingSampleResponse;
  selectWritingSampleTopic: SelectWritingSampleTopicResponse;
  sendEmailManagedSeo: SendEmailManagedSeoResponse;
  signupCSUser: SignupCsUserResponse;
  startGrammarTest: StartGrammarTestResponse;
  startWritingSample: StartWritingSampleResponse;
  submitProposalReview: SubmitProposalReviewResponse;
  syncUberallLocation: SyncUberallLocationResponse;
  updateAmTask: UpdateAmTaskResponse;
  updateCategory: UpdateCategoryResponse;
  updateChecklistItem: UpdateChecklistItemResponse;
  updateCreativesScaleUser: UpdateCreativeScaleUserResponse;
  updateCustomer: UpdateCustomerResponse;
  updateCustomerBusinessListingSpec: UpdateCustomerBusinessListingSpecResponse;
  updateCustomerCampaign: UpdateCustomerCampaignResponse;
  updateCustomerConversation: UpdateCustomerConversationResponse;
  updateCustomerKeyword: UpdateCustomerKeywordResponse;
  updateCustomerKeywordProposal: UpdateCustomerKeywordProposalResponse;
  updateCustomerNote: UpdateCustomerNoteResponse;
  updateCustomerProposal: UpdateCustomerProposalResponse;
  updateCustomerStatusChange: UpdateCustomerStatusChangeResponse;
  updateCustomerUser: UpdateCustomerUserResponse;
  updateFeedbackContentItem: UpdateFeedbackContentItemResponse;
  updateFulfillmentRule: UpdateFulfillmentRuleResponse;
  updateLinkLocation: UpdateLinkLocationResponse;
  updateLinkLocationCategories: UpdateLinkLocationCategoriesResponse;
  updateLinkLocationMetrics: UpdateLinkLocationMetricsResponse;
  updateOrder: UpdateOrderResponse;
  updateOrderContentItem: UpdateOrderContentItemResponse;
  updateOrderContentStrategy: UpdateOrderContentStrategyResponse;
  updateOrderExternalContentSpec: UpdateOrderExternalContentSpecResponse;
  updateOrderGbpItem: UpdateOrderGbpItemResponse;
  updateOrderTitleMetaDescriptionItem: UpdateOrderTitleMetaDescriptionItemResponse;
  updatePartner: UpdatePartnerResponse;
  updatePartnerBillingContact: UpdatePartnerBillingContactResponse;
  updatePartnerPackage: UpdatePartnerPackageResponse;
  updatePartnerPackageItem: UpdatePartnerPackageItemResponse;
  updatePartnerProduct?: Maybe<UpdatePartnerProductResponse>;
  updatePartnerProductPrice: UpdatePartnerProductPriceResponse;
  updatePartnerProductWholesalePrice: UpdatePartnerProductWholesalePriceResponse;
  updatePartnerUser: UpdatePartnerUserResponse;
  updateProposedCustomerKeyword: UpdateProposedCustomerKeywordResponse;
  updatePublisher: UpdatePublisherResponse;
  updatePublisherLinkLocation: UpdatePublisherLinkLocationResponse;
  updatePublisherPayment: UpdatePublisherPaymentResponse;
  updateRcmWriterTasks: UpdateRcmWriterTasksResponse;
  updateSitePost: UpdateSitePostResponse;
  updateSitePostForTask: UpdateSitePostForTaskResponse;
  updateTask: UpdateTaskResponse;
  updateUser: UpdateUserResponse;
  updateUserProfessional: UpdateUserProfessionalResponse;
  updateVendor: UpdateVendorResponse;
  updateVendorUser: UpdateVendorUserResponse;
  updateWritingSampleTopic: UpdateWritingSampleTopicResponse;
  verifyGuestPost: VerifyGuestPostResponse;
  version?: Maybe<Scalars["String"]>;
};

export type MutationApprovePaymentBatchFundingArgs = {
  input: ApprovePaymentBatchFundingInput;
};

export type MutationApprovePaymentBatchFundingPaypalArgs = {
  input: ApprovePaymentBatchFundingPaypalInput;
};

export type MutationApproveUserWritingSampleArgs = {
  input: ApproveUserWritingSampleInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCheckoutArgs = {
  input: CheckoutInput;
};

export type MutationClaimTaskArgs = {
  input: ClaimTaskInput;
};

export type MutationClearProposedCustomerKeywordsRejectionArgs = {
  input: ClearProposedCustomerKeywordsRejectionInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationCreateAmTaskArgs = {
  input: CreateAmTaskInput;
};

export type MutationCreateBlockDomainCustomersArgs = {
  input: CreateBlockDomainCustomersInput;
};

export type MutationCreateBulkProposedCustomerKeywordsArgs = {
  input: CreateBulkProposedCustomerKeywordsInput;
};

export type MutationCreateBusinessSubscriptionOrdersArgs = {
  input: CreateBusinessSubscriptionOrdersInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationCreateChecklistDefinitionArgs = {
  input: CreateChecklistDefinitionInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerBusinessListingSpecArgs = {
  input: CreateCustomerBusinessListingSpecInput;
};

export type MutationCreateCustomerCampaignArgs = {
  input: CreateCustomerCampaignInput;
};

export type MutationCreateCustomerConversationArgs = {
  input: CreateCustomerConversationInput;
};

export type MutationCreateCustomerInvoiceArgs = {
  input: CreateCustomerInvoiceInput;
};

export type MutationCreateCustomerKeywordArgs = {
  input: CreateCustomerKeywordInput;
};

export type MutationCreateCustomerLinkBuildArgs = {
  input: CreateCustomerLinkBuildInput;
};

export type MutationCreateCustomerMessageArgs = {
  input: CreateCustomerMessageInput;
};

export type MutationCreateCustomerNoteArgs = {
  input: CreateCustomerNoteInput;
};

export type MutationCreateCustomerPremiumBusinessListingSpecImageArgs = {
  input: CreateCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationCreateCustomerSiteAuditArgs = {
  input: CreateCustomerSiteAuditInput;
};

export type MutationCreateCustomerStatusChangeArgs = {
  input: CreateCustomerStatusChangeInput;
};

export type MutationCreateCustomerUserArgs = {
  input: CreateCustomerUserInput;
};

export type MutationCreateCustomerUserSsoCodeArgs = {
  input: CreateCustomerUserSsoCodeInput;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateDomainCategoryArgs = {
  input: CreateDomainCategoryInput;
};

export type MutationCreateDomainsArgs = {
  input: CreateDomainsInput;
};

export type MutationCreateFeedbackContentItemArgs = {
  input: CreateFeedbackContentItemInput;
};

export type MutationCreateFulfillmentRuleArgs = {
  input: CreateFulfillmentRuleInput;
};

export type MutationCreateLinkLocationArgs = {
  input: CreateLinkLocationInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreateOrderExternalContentSpecArgs = {
  input: CreateOrderExternalContentSpecInput;
};

export type MutationCreateOrderHaloKeywordArgs = {
  input: CreateOrderHaloKeywordInput;
};

export type MutationCreateOrdersArgs = {
  input: CreateOrdersInput;
};

export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};

export type MutationCreatePartnerAvailableProductArgs = {
  input: CreatePartnerAvailableProductInput;
};

export type MutationCreatePartnerBillingContactArgs = {
  input: CreatePartnerBillingContactInput;
};

export type MutationCreatePartnerInvoiceArgs = {
  input: CreatePartnerInvoiceInput;
};

export type MutationCreatePartnerPackageArgs = {
  input: CreatePartnerPackageInput;
};

export type MutationCreatePartnerPackageItemArgs = {
  input: CreatePartnerPackageItemInput;
};

export type MutationCreatePartnerPaymentMethodSetupIntentArgs = {
  input: CreatePartnerPaymentMethodSetupIntentInput;
};

export type MutationCreatePartnerProductArgs = {
  input: CreatePartnerProductInput;
};

export type MutationCreatePartnerProductPriceArgs = {
  input: CreatePartnerProductPriceInput;
};

export type MutationCreatePartnerProductWholesalePriceArgs = {
  input: CreatePartnerProductWholesalePriceInput;
};

export type MutationCreatePartnerTaskTypeVendorArgs = {
  input: CreatePartnerTaskTypeVendorInput;
};

export type MutationCreatePartnerUserArgs = {
  input: CreatePartnerUserInput;
};

export type MutationCreateProposedCustomerKeywordArgs = {
  input: CreateProposedCustomerKeywordInput;
};

export type MutationCreatePublisherArgs = {
  input: CreatePublisherInput;
};

export type MutationCreatePublisherLinkLocationArgs = {
  input: CreatePublisherLinkLocationInput;
};

export type MutationCreatePublisherPaymentArgs = {
  input: CreatePublisherPaymentInput;
};

export type MutationCreatePublisherUserArgs = {
  input: CreatePublisherUserInput;
};

export type MutationCreateSiteArgs = {
  input: CreateSiteInput;
};

export type MutationCreateSitePostArgs = {
  input: CreateSitePostInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserInteractionEventArgs = {
  input: CreateUserInteractionEventInput;
};

export type MutationCreateUserProfessionalArgs = {
  input: CreateUserProfessionalInput;
};

export type MutationCreateUserTaskPermissionArgs = {
  input: CreateUserTaskPermissionInput;
};

export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};

export type MutationCreateVendorUserArgs = {
  input: CreateVendorUserInput;
};

export type MutationCreateWorkflowNoteArgs = {
  input: CreateWorkflowNoteInput;
};

export type MutationCreateWritingSampleTopicArgs = {
  input: CreateWritingSampleTopicInput;
};

export type MutationDeleteAmTaskArgs = {
  input: DeleteAmTaskInput;
};

export type MutationDeleteCustomerCampaignArgs = {
  input: DeleteCustomerCampaignInput;
};

export type MutationDeleteCustomerCampaignInvoiceArgs = {
  input: DeleteCustomerCampaignInvoiceInput;
};

export type MutationDeleteCustomerPremiumBusinessListingSpecImageArgs = {
  input: DeleteCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationDeleteCustomerStatusChangeArgs = {
  input: DeleteCustomerStatusChangeInput;
};

export type MutationDeleteDomainCategoryArgs = {
  input: DeleteDomainCategoryInput;
};

export type MutationDeleteFulfillmentRuleArgs = {
  input: DeleteFulfillmentRuleInput;
};

export type MutationDeleteLinkLocationArgs = {
  input: DeleteLinkLocationInput;
};

export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};

export type MutationDeleteOrderContentStrategyArgs = {
  input: DeleteOrderContentStrategyInput;
};

export type MutationDeletePartnerBillingContactArgs = {
  input: DeletePartnerBillingContactInput;
};

export type MutationDeletePartnerPaymentMethodArgs = {
  input: DeletePartnerPaymentMethodInput;
};

export type MutationDeletePartnerProductArgs = {
  input: DeletePartnerProductInput;
};

export type MutationDeletePartnerProductPriceArgs = {
  input: DeletePartnerProductPriceInput;
};

export type MutationDeletePartnerProductWholesalePriceArgs = {
  input: DeletePartnerProductWholesalePriceInput;
};

export type MutationDeletePartnerTaskTypeVendorArgs = {
  input: DeletePartnerTaskTypeVendorInput;
};

export type MutationDeleteProposedCustomerKeywordArgs = {
  input: DeleteProposedCustomerKeywordInput;
};

export type MutationDeleteSitePostArgs = {
  input: DeleteSitePostInput;
};

export type MutationDeleteUserTaskPermissionArgs = {
  input: DeleteUserTaskPermissionInput;
};

export type MutationGenerateUrlForFileUploadArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadInput>;
};

export type MutationGenerateUrlForFileUploadSignupArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadSignupInput>;
};

export type MutationGoogleReviewReplyArgs = {
  input: GoogleReviewReplyInput;
};

export type MutationImportInvoicesArgs = {
  input: ImportInvoicesInput;
};

export type MutationLoginGoogleCallbackArgs = {
  input?: InputMaybe<LoginGoogleCallbackInput>;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationPayPartnerInvoiceArgs = {
  input: PayPartnerInvoiceInput;
};

export type MutationPurchaseDomainArgs = {
  input: PurchaseDomainInput;
};

export type MutationReCreateGuestOutreachTaskArgs = {
  input: ReCreateGuestOutreachTaskInput;
};

export type MutationRecordCustomerCampaignInvoicePaymentArgs = {
  input: RecordCustomerCampaignInvoicePaymentInput;
};

export type MutationRecordCustomerInvoicePaymentArgs = {
  input: RecordCustomerInvoicePaymentInput;
};

export type MutationRecordPartnerInvoicePaymentArgs = {
  input: RecordPartnerInvoicePaymentInput;
};

export type MutationRefreshDomainAvailabilityArgs = {
  input?: InputMaybe<RefreshDomainAvailabilityInput>;
};

export type MutationRejectUserWritingSampleArgs = {
  input: RejectUserWritingSampleInput;
};

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type MutationResendConfirmationEmailArgs = {
  input: ResendConfirmationEmailInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationResolveOrderContentStrategyReviewArgs = {
  input: ResolveOrderContentStrategyReviewInput;
};

export type MutationSaveCustomerBillingConfigArgs = {
  input: SaveCustomerBillingConfigInput;
};

export type MutationSaveCustomerBusinessListingSpecArgs = {
  input: SaveCustomerBusinessListingSpecInput;
};

export type MutationSaveCustomerHoursOfOperationItemsArgs = {
  input: SaveCustomerHoursOfOperationItemsInput;
};

export type MutationSaveCustomerPremiumBusinessListingSpecArgs = {
  input: SaveCustomerPremiumBusinessListingSpecInput;
};

export type MutationSaveCustomerQuestionnaireArgs = {
  input: SaveCustomerQuestionnaireInput;
};

export type MutationSaveCustomerSiteCredentialArgs = {
  input: SaveCustomerSiteCredentialInput;
};

export type MutationSaveGrammarTestQuestionArgs = {
  input: SaveGrammarTestQuestionInput;
};

export type MutationSaveLinkLocationCredentialArgs = {
  input: SaveLinkLocationCredentialInput;
};

export type MutationSaveOrderContentItemArgs = {
  input: SaveOrderContentItemInput;
};

export type MutationSavePartnerAnalyticCredentialArgs = {
  input: SavePartnerAnalyticCredentialInput;
};

export type MutationSaveProposedOrderTopicArgs = {
  input: SaveProposedOrderTopicInput;
};

export type MutationSaveTaskContentIdeationArgs = {
  input: SaveTaskContentIdeationInput;
};

export type MutationSaveTaskContentItemArgs = {
  input: SaveTaskContentItemInput;
};

export type MutationSaveTaskGbpItemArgs = {
  input: SaveTaskGbpItemInput;
};

export type MutationSaveTaskTitleMetaDescriptionsArgs = {
  input: SaveTaskTitleMetaDescriptionInput;
};

export type MutationSaveUserGrammarTestResponseArgs = {
  input: SaveUserGrammarTestResponseInput;
};

export type MutationSaveUserProfessionalArgs = {
  input: SaveUserProfessionalInput;
};

export type MutationSaveUserWorkInterestsArgs = {
  input: SaveUserWorkInterestsInput;
};

export type MutationSaveWritingSampleArgs = {
  input: SaveWritingSampleInput;
};

export type MutationSelectWritingSampleTopicArgs = {
  input: SelectWritingSampleTopicInput;
};

export type MutationSendEmailManagedSeoArgs = {
  input: SendEmailManagedSeoInput;
};

export type MutationSignupCsUserArgs = {
  input?: InputMaybe<SignupCsUserInput>;
};

export type MutationSubmitProposalReviewArgs = {
  input: SubmitProposalReviewInput;
};

export type MutationSyncUberallLocationArgs = {
  input: SyncUberallLocationInput;
};

export type MutationUpdateAmTaskArgs = {
  input: UpdateAmTaskInput;
};

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type MutationUpdateChecklistItemArgs = {
  input: UpdateChecklistItemInput;
};

export type MutationUpdateCreativesScaleUserArgs = {
  input?: InputMaybe<UpdateCreativeScaleUserInput>;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerBusinessListingSpecArgs = {
  input: UpdateCustomerBusinessListingSpecInput;
};

export type MutationUpdateCustomerCampaignArgs = {
  input: UpdateCustomerCampaignInput;
};

export type MutationUpdateCustomerConversationArgs = {
  input: UpdateCustomerConversationInput;
};

export type MutationUpdateCustomerKeywordArgs = {
  input: UpdateCustomerKeywordInput;
};

export type MutationUpdateCustomerKeywordProposalArgs = {
  input: UpdateCustomerKeywordProposalInput;
};

export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput;
};

export type MutationUpdateCustomerProposalArgs = {
  input: UpdateCustomerProposalInput;
};

export type MutationUpdateCustomerStatusChangeArgs = {
  input: UpdateCustomerStatusChangeInput;
};

export type MutationUpdateCustomerUserArgs = {
  input: UpdateCustomerUserInput;
};

export type MutationUpdateFeedbackContentItemArgs = {
  input: UpdateFeedbackContentItemInput;
};

export type MutationUpdateFulfillmentRuleArgs = {
  input: UpdateFulfillmentRuleInput;
};

export type MutationUpdateLinkLocationArgs = {
  input: UpdateLinkLocationInput;
};

export type MutationUpdateLinkLocationCategoriesArgs = {
  input: UpdateLinkLocationCategoriesInput;
};

export type MutationUpdateLinkLocationMetricsArgs = {
  input: UpdateLinkLocationMetricsInput;
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationUpdateOrderContentItemArgs = {
  input: UpdateOrderContentItemInput;
};

export type MutationUpdateOrderContentStrategyArgs = {
  input: UpdateOrderContentStrategyInput;
};

export type MutationUpdateOrderExternalContentSpecArgs = {
  input: UpdateOrderExternalContentSpecInput;
};

export type MutationUpdateOrderGbpItemArgs = {
  input: UpdateOrderGbpItemInput;
};

export type MutationUpdateOrderTitleMetaDescriptionItemArgs = {
  input: UpdateOrderTitleMetaDescriptionItemInput;
};

export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};

export type MutationUpdatePartnerBillingContactArgs = {
  input: UpdatePartnerBillingContactInput;
};

export type MutationUpdatePartnerPackageArgs = {
  input: UpdatePartnerPackageInput;
};

export type MutationUpdatePartnerPackageItemArgs = {
  input: UpdatePartnerPackageItemInput;
};

export type MutationUpdatePartnerProductArgs = {
  input: UpdatePartnerProductInput;
};

export type MutationUpdatePartnerProductPriceArgs = {
  input: UpdatePartnerProductPriceInput;
};

export type MutationUpdatePartnerProductWholesalePriceArgs = {
  input: UpdatePartnerProductWholesalePriceInput;
};

export type MutationUpdatePartnerUserArgs = {
  input: UpdatePartnerUserInput;
};

export type MutationUpdateProposedCustomerKeywordArgs = {
  input: UpdateProposedCustomerKeywordInput;
};

export type MutationUpdatePublisherArgs = {
  input: UpdatePublisherInput;
};

export type MutationUpdatePublisherLinkLocationArgs = {
  input: UpdatePublisherLinkLocationInput;
};

export type MutationUpdatePublisherPaymentArgs = {
  input: UpdatePublisherPaymentInput;
};

export type MutationUpdateRcmWriterTasksArgs = {
  input: UpdateRcmWriterTasksInput;
};

export type MutationUpdateSitePostArgs = {
  input: UpdateSitePostInput;
};

export type MutationUpdateSitePostForTaskArgs = {
  input: UpdateSitePostForTaskInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserProfessionalArgs = {
  input: UpdateUserProfessionalInput;
};

export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};

export type MutationUpdateVendorUserArgs = {
  input: UpdateVendorUserInput;
};

export type MutationUpdateWritingSampleTopicArgs = {
  input: UpdateWritingSampleTopicInput;
};

export type MutationVerifyGuestPostArgs = {
  input: VerifyGuestPostInput;
};

export type Note = {
  __typename?: "Note";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  user: User;
  userId: Scalars["Int"];
};

export type NoteConnection = {
  __typename?: "NoteConnection";
  nodes: Array<Note>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OnboardingStatus = {
  __typename?: "OnboardingStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  activeOrderContentStrategy?: Maybe<OrderContentStrategy>;
  amount: Scalars["Float"];
  billingStatus: OrderBillingStatus;
  canDelete: Scalars["Boolean"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerBillingStatus: OrderBillingStatus;
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordId?: Maybe<Scalars["Int"]>;
  customerProposal?: Maybe<CustomerProposal>;
  customerReviewTask?: Maybe<Task>;
  customerSiteAudit?: Maybe<CustomerSiteAudit>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationId?: Maybe<Scalars["Int"]>;
  orderContentItems: Array<OrderContentItem>;
  orderContentStrategies: Array<OrderContentStrategy>;
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
  orderGbpItem?: Maybe<OrderGbpItem>;
  orderIssues: Array<OrderIssue>;
  orderTitleMetaDescriptionItem?: Maybe<OrderTitleMetaDescriptionItem>;
  partnerInvoiceCharges: Array<PartnerInvoiceCharge>;
  partnerProduct: PartnerProduct;
  proposalTask?: Maybe<Task>;
  publisherPayment?: Maybe<PublisherPayment>;
  publisherPaymentStatus: OrderBillingStatus;
  quantity: Scalars["Int"];
  resultUrl?: Maybe<Scalars["String"]>;
  resultUrlDomainAuthority?: Maybe<Scalars["Int"]>;
  startDate: Scalars["DateTime"];
  status: OrderStatus;
  targetLinkLocation?: Maybe<LinkLocation>;
  targetLinkLocationId?: Maybe<Scalars["Int"]>;
  targetResultUrl?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  totalRetailAmount: Scalars["Float"];
  totalWholesaleAmount: Scalars["Float"];
  trackingId?: Maybe<Scalars["String"]>;
  user: User;
  userId: Scalars["Int"];
  workflow?: Maybe<Workflow>;
  workflowInternalStatus?: Maybe<Scalars["String"]>;
};

export type OrderOrderContentItemsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type OrderPublisherPaymentArgs = {
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type OrderBillingStatus = {
  __typename?: "OrderBillingStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderConnection = {
  __typename?: "OrderConnection";
  nodes: Array<Order>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OrderContentItem = {
  __typename?: "OrderContentItem";
  contentItem: ContentItem;
  contentItemDelivery: ContentItemDelivery;
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isPostPublished: Scalars["Boolean"];
  lastVerifiedPostAt?: Maybe<Scalars["DateTime"]>;
  order: Order;
  orderId: Scalars["Int"];
  publicId?: Maybe<Scalars["String"]>;
};

export type OrderContentItemConnection = {
  __typename?: "OrderContentItemConnection";
  nodes: Array<OrderContentItem>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum OrderContentStrategiesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  OrderStartDateAsc = "ORDER_START_DATE_ASC",
  OrderStartDateDesc = "ORDER_START_DATE_DESC",
}

export type OrderContentStrategy = {
  __typename?: "OrderContentStrategy";
  clientProvidedInstructions?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordId?: Maybe<Scalars["Int"]>;
  customerProposal?: Maybe<CustomerProposal>;
  customerProposalId?: Maybe<Scalars["Int"]>;
  focusPoints?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isComplete: Scalars["Boolean"];
  isProposed: Scalars["Boolean"];
  linkTo?: Maybe<Scalars["String"]>;
  order?: Maybe<Order>;
  orderContentStrategyReviews: Array<OrderContentStrategyReview>;
  orderHaloKeywords: Array<OrderHaloKeyword>;
  orderId: Scalars["Int"];
  pageType?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  sampleContent?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  voice?: Maybe<Scalars["String"]>;
  webPage?: Maybe<Scalars["String"]>;
};

export type OrderContentStrategyReview = {
  __typename?: "OrderContentStrategyReview";
  comments?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdByUser: User;
  createdByUserId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  orderContentStrategyId: Scalars["Int"];
  orderId: Scalars["Int"];
  resolvedAt?: Maybe<Scalars["DateTime"]>;
};

export type OrderExternalContentSpec = {
  __typename?: "OrderExternalContentSpec";
  addedByUserId: Scalars["Int"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  cost: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  domainAuthority?: Maybe<Scalars["Int"]>;
  externalOrderId: Scalars["String"];
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  submittedAt: Scalars["DateTime"];
  trustFlow?: Maybe<Scalars["Int"]>;
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type OrderGbpItem = {
  __typename?: "OrderGbpItem";
  createdAt: Scalars["DateTime"];
  gbpItem: GbpItem;
  gbpItemId: Scalars["Int"];
  id: Scalars["Int"];
  isProfilePublished: Scalars["Boolean"];
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderHaloKeyword = {
  __typename?: "OrderHaloKeyword";
  createdAt: Scalars["DateTime"];
  customerKeyword: CustomerKeyword;
  customerKeywordId: Scalars["Int"];
  customerProposal?: Maybe<CustomerProposal>;
  customerProposalId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isProposed: Scalars["Boolean"];
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderInput = {
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  keywordId?: InputMaybe<Scalars["Int"]>;
  partnerProductId: Scalars["Int"];
  startDate?: InputMaybe<Scalars["Date"]>;
  targetResultUrl?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  trackingId?: InputMaybe<Scalars["String"]>;
};

export type OrderIssue = {
  __typename?: "OrderIssue";
  message: Scalars["String"];
  title: Scalars["String"];
};

export type OrderStatus = {
  __typename?: "OrderStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderTitleMetaDescriptionItem = {
  __typename?: "OrderTitleMetaDescriptionItem";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isPublished: Scalars["Boolean"];
  orderId: Scalars["Int"];
  titleMetaDescriptionItem: TitleMetaDescriptionItem;
  titleMetaDescriptionItemId: Scalars["Int"];
};

export enum OrdersSort {
  CompletedAtAsc = "COMPLETED_AT_ASC",
  CompletedAtDesc = "COMPLETED_AT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
}

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
};

export type Partner = {
  __typename?: "Partner";
  autopayPartnerPaymentMethod?: Maybe<PartnerPaymentMethod>;
  autopayPartnerPaymentMethodId?: Maybe<Scalars["Int"]>;
  billableOrders: OrderConnection;
  billingEmail?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailLogoFile?: Maybe<File>;
  enableAutopayInformation?: Maybe<EnableAutopayInformation>;
  id: Scalars["Int"];
  isAutopayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
  name: Scalars["String"];
  partnerAnalyticCredential?: Maybe<PartnerAnalyticCredential>;
  partnerAvailableProducts: Array<PartnerAvailableProduct>;
  partnerBillingContacts: PartnerBillingContactConnection;
  partnerInvoices: PartnerInvoiceConnection;
  partnerPackages: PartnerPackageConnection;
  partnerPaymentMethods: PartnerPaymentMethodConnection;
  partnerProducts: PartnerProductConnection;
  partnerTaskTypeVendors: Array<PartnerTaskTypeVendor>;
  partnerUsers: PartnerUserConnection;
  portal?: Maybe<Scalars["String"]>;
  reportingLogoFile?: Maybe<File>;
  stripeAccountId?: Maybe<Scalars["String"]>;
};

export type PartnerBillableOrdersArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
};

export type PartnerPartnerBillingContactsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerPaymentMethodsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type PartnerPartnerUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type PartnerAnalyticCredential = {
  __typename?: "PartnerAnalyticCredential";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  password?: Maybe<Scalars["String"]>;
  passwordHash: Scalars["String"];
  userName: Scalars["String"];
};

export type PartnerAvailableProduct = {
  __typename?: "PartnerAvailableProduct";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerProductWholesalePrices: PartnerProductWholesalePriceConnection;
  product: Product;
  productId: Scalars["String"];
  wholesalePrice: Scalars["Float"];
};

export type PartnerAvailableProductPartnerProductWholesalePricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductWholesalePriceSort>;
};

export type PartnerBillingContact = {
  __typename?: "PartnerBillingContact";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type PartnerBillingContactConnection = {
  __typename?: "PartnerBillingContactConnection";
  nodes: Array<PartnerBillingContact>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerConnection = {
  __typename?: "PartnerConnection";
  nodes: Array<Partner>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoice = {
  __typename?: "PartnerInvoice";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  invoiceDate: Scalars["DateTime"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerInvoiceCharges: PartnerInvoiceChargeConnection;
  partnerInvoicePayments: PartnerInvoicePaymentConnection;
  partnerInvoiceRefunds: PartnerInvoiceRefundConnection;
  status?: Maybe<PartnerInvoiceStatus>;
  statusId: Scalars["String"];
  totalChargesAmount: Scalars["Float"];
  totalDueAmount: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type PartnerInvoiceCharge = {
  __typename?: "PartnerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["Int"]>;
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerInvoiceId: Scalars["Int"];
  status?: Maybe<PartnerInvoiceChargeStatus>;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type PartnerInvoiceChargeConnection = {
  __typename?: "PartnerInvoiceChargeConnection";
  nodes: Array<PartnerInvoiceCharge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoiceChargeStatus = {
  __typename?: "PartnerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerInvoiceConnection = {
  __typename?: "PartnerInvoiceConnection";
  nodes: Array<PartnerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoicePayment = {
  __typename?: "PartnerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerInvoiceId: Scalars["Int"];
  partnerPaymentMethod: PartnerPaymentMethod;
  partnerPaymentMethodId: Scalars["Int"];
  paymentMethodDescription: Scalars["String"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status?: Maybe<PartnerInvoicePaymentStatus>;
  statusId: Scalars["String"];
};

export type PartnerInvoicePaymentConnection = {
  __typename?: "PartnerInvoicePaymentConnection";
  nodes: Array<PartnerInvoicePayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoicePaymentStatus = {
  __typename?: "PartnerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerInvoiceRefund = {
  __typename?: "PartnerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerInvoice: PartnerInvoice;
  partnerInvoiceId: Scalars["Int"];
  partnerInvoicePayment: PartnerInvoicePayment;
  partnerInvoicePaymentId: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  status?: Maybe<PartnerInvoicePaymentStatus>;
  statusId: Scalars["String"];
};

export type PartnerInvoiceRefundConnection = {
  __typename?: "PartnerInvoiceRefundConnection";
  nodes: Array<PartnerInvoiceRefund>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoiceRefundStatus = {
  __typename?: "PartnerInvoiceRefundStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum PartnerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PartnerInvoiceStatus = {
  __typename?: "PartnerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerPackage = {
  __typename?: "PartnerPackage";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  partnerPackageItemCount: Scalars["Int"];
  partnerPackageItems: Array<PartnerPackageItem>;
};

export type PartnerPackageConnection = {
  __typename?: "PartnerPackageConnection";
  nodes: Array<PartnerPackage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerPackageItem = {
  __typename?: "PartnerPackageItem";
  id: Scalars["Int"];
  isRecurring: Scalars["Boolean"];
  monthIndex: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerPackage: PartnerPackage;
  partnerPackageId: Scalars["Int"];
  partnerProduct: PartnerProduct;
  partnerProductId: Scalars["Int"];
  quantity: Scalars["Int"];
  totalPrice: Scalars["Float"];
  unitPrice: Scalars["Float"];
};

export enum PartnerPackageSort {
  MonthlySpendAsc = "MONTHLY_SPEND_ASC",
  MonthlySpendDesc = "MONTHLY_SPEND_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PartnerPaymentMethod = {
  __typename?: "PartnerPaymentMethod";
  createdAt: Scalars["DateTime"];
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerPaymentMethodCard?: Maybe<PartnerPaymentMethodCard>;
  type: Scalars["String"];
};

export type PartnerPaymentMethodCard = {
  __typename?: "PartnerPaymentMethodCard";
  brand: Scalars["String"];
  brandDisplayName: Scalars["String"];
  expirationMonth: Scalars["Int"];
  expirationYear: Scalars["Int"];
  last4Digits: Scalars["String"];
};

export type PartnerPaymentMethodConnection = {
  __typename?: "PartnerPaymentMethodConnection";
  nodes: Array<PartnerPaymentMethod>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProduct = {
  __typename?: "PartnerProduct";
  activeAt: Scalars["DateTime"];
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  canDelete: Scalars["Boolean"];
  checklistDefinition?: Maybe<ChecklistDefinition>;
  checklistDefinitionId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentPartnerProductPrice?: Maybe<PartnerProductPrice>;
  currentPartnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
  id: Scalars["Int"];
  inactiveAt?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerProductPrices: PartnerProductPriceConnection;
  partnerProductWholesalePrices: PartnerProductWholesalePriceConnection;
  product: Product;
  productId: Scalars["String"];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars["Int"]>;
  wholesalePrice?: Maybe<Scalars["Float"]>;
};

export type PartnerProductPartnerProductPricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductPriceSort>;
  startsAt?: InputMaybe<DateQuery>;
};

export type PartnerProductPartnerProductWholesalePricesArgs = {
  effectiveAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerProductConnection = {
  __typename?: "PartnerProductConnection";
  nodes: Array<PartnerProduct>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProductPrice = {
  __typename?: "PartnerProductPrice";
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerProduct: PartnerProduct;
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type PartnerProductPriceConnection = {
  __typename?: "PartnerProductPriceConnection";
  nodes: Array<PartnerProductPrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductPriceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartsAtAsc = "STARTS_AT_ASC",
  StartsAtDesc = "STARTS_AT_DESC",
}

export type PartnerProductWholesalePrice = {
  __typename?: "PartnerProductWholesalePrice";
  canDelete: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesaleAmount: Scalars["Float"];
};

export type PartnerProductWholesalePriceConnection = {
  __typename?: "PartnerProductWholesalePriceConnection";
  nodes: Array<PartnerProductWholesalePrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductWholesalePriceSort {
  EffectiveAtAsc = "EFFECTIVE_AT_ASC",
  EffectiveAtDesc = "EFFECTIVE_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PartnerTaskTypeVendor = {
  __typename?: "PartnerTaskTypeVendor";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type PartnerUser = {
  __typename?: "PartnerUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isAdmin: Scalars["Boolean"];
  partner: Partner;
  partnerId: Scalars["Int"];
  user: User;
  userId: Scalars["Int"];
};

export type PartnerUserConnection = {
  __typename?: "PartnerUserConnection";
  nodes: Array<PartnerUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnersSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PayPartnerInvoiceInput = {
  partnerInvoiceId: Scalars["Int"];
  partnerPaymentMethodId: Scalars["Int"];
};

export type PayPartnerInvoiceResponse = {
  __typename?: "PayPartnerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type PaymentBatch = {
  __typename?: "PaymentBatch";
  createdAt: Scalars["DateTime"];
  creativesScaleUserPayments?: Maybe<Array<CreativesScaleUserPayment>>;
  externalFundingTransferId?: Maybe<Scalars["String"]>;
  feesTotalAmount: Scalars["Float"];
  fundingApprovedAt?: Maybe<Scalars["DateTime"]>;
  fundingApprovedByUserId?: Maybe<Scalars["Int"]>;
  fundingStatus: PaymentBatchFundingStatus;
  fundingStatusId: Scalars["String"];
  id: Scalars["Int"];
  paymentTotalAmount: Scalars["Float"];
  publisherPayments?: Maybe<Array<PublisherPayment>>;
  status: PaymentBatchStatus;
  statusId: Scalars["String"];
  totalAmount: Scalars["Float"];
};

export type PaymentBatchFundingStatus = {
  __typename?: "PaymentBatchFundingStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PaymentBatchStatus = {
  __typename?: "PaymentBatchStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum PaymentsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PostalAddress = {
  __typename?: "PostalAddress";
  addressLines?: Maybe<Array<Scalars["String"]>>;
  administrativeArea?: Maybe<Scalars["String"]>;
  languageCode?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  regionCode?: Maybe<Scalars["String"]>;
  revision?: Maybe<Scalars["Int"]>;
  sortingCode?: Maybe<Scalars["String"]>;
  sublocality?: Maybe<Scalars["String"]>;
};

export type Product = {
  __typename?: "Product";
  campaignCategory: CampaignCategory;
  campaignCategoryId: Scalars["String"];
  defaultWholesalePrice: Scalars["Int"];
  deprecatedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isChecklistRequired: Scalars["Boolean"];
  isKeywordRequired: Scalars["Boolean"];
  isSystemManaged: Scalars["Boolean"];
  isSystemOrdered: Scalars["Boolean"];
  isTopicRequired: Scalars["Boolean"];
  isVendorRequired: Scalars["Boolean"];
  isWebsiteRequired: Scalars["Boolean"];
  maxOrderQuantity: Scalars["Int"];
  maxWordCount?: Maybe<Scalars["Int"]>;
  minDA?: Maybe<Scalars["Int"]>;
  minSV?: Maybe<Scalars["Int"]>;
  minWordCount?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type ProductConnection = {
  __typename?: "ProductConnection";
  nodes: Array<Product>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ProductTaskType = {
  __typename?: "ProductTaskType";
  id: Scalars["String"];
  product: Product;
  productId: Scalars["String"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
};

export type ProductTaskTypeConnection = {
  __typename?: "ProductTaskTypeConnection";
  nodes: Array<ProductTaskType>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ProductTaskTypePair = {
  productId: Scalars["String"];
  taskTypeId: Scalars["String"];
};

export enum ProductsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ProposedCustomerKeyword = {
  __typename?: "ProposedCustomerKeyword";
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeywordProposalId: Scalars["Int"];
  id: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
};

export enum ProposedCustomerKeywordSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ProposedOrderTopic = {
  __typename?: "ProposedOrderTopic";
  customerKeyword: CustomerKeyword;
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  rejectionComment?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
};

export type Publisher = {
  __typename?: "Publisher";
  contactEmail?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars["Int"]>;
  emailMessages?: Maybe<EmailMessageConnection>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isAutoPayEnabled?: Maybe<Scalars["Boolean"]>;
  isInvoiceRequired?: Maybe<Scalars["Boolean"]>;
  isSlowPartner?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  paidAmount: Scalars["Float"];
  paymentMethod?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  publishedOrders: OrderConnection;
  publisherLinkLocations: PublisherLinkLocationConnection;
  publisherUsers: PublisherUserConnection;
  requestedOrders: OrderConnection;
};

export type PublisherEmailMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PublisherPaidAmountArgs = {
  daysAgo?: InputMaybe<Scalars["Int"]>;
};

export type PublisherPublishedOrdersArgs = {
  daysAgo?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
};

export type PublisherPublisherLinkLocationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type PublisherPublisherUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherUsersSort>;
};

export type PublisherRequestedOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
};

export type PublisherConnection = {
  __typename?: "PublisherConnection";
  nodes: Array<Publisher>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PublisherLinkLocation = {
  __typename?: "PublisherLinkLocation";
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  isRCMPublishing?: Maybe<Scalars["Boolean"]>;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationId: Scalars["Int"];
  publisher?: Maybe<Publisher>;
  publisherId: Scalars["Int"];
};

export type PublisherLinkLocationConnection = {
  __typename?: "PublisherLinkLocationConnection";
  nodes: Array<PublisherLinkLocation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PublisherLinkLocationSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PublisherPayment = {
  __typename?: "PublisherPayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  orderId?: Maybe<Scalars["Int"]>;
  paymentBatchId?: Maybe<Scalars["Int"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Publisher>;
  publisherId?: Maybe<Scalars["Int"]>;
  status?: Maybe<PublisherPaymentStatus>;
};

export type PublisherPaymentConnection = {
  __typename?: "PublisherPaymentConnection";
  nodes: Array<PublisherPayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PublisherPaymentStatus = {
  __typename?: "PublisherPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PublisherUser = {
  __typename?: "PublisherUser";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isAdmin: Scalars["Boolean"];
  publisher: Publisher;
  publisherId: Scalars["Int"];
  user: User;
  userId: Scalars["Int"];
};

export type PublisherUserConnection = {
  __typename?: "PublisherUserConnection";
  nodes: Array<PublisherUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PublisherUsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export enum PublishersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PurchaseDomainInput = {
  domainId: Scalars["String"];
};

export type PurchaseDomainResponse = {
  __typename?: "PurchaseDomainResponse";
  domain: Domain;
};

export type Query = {
  __typename?: "Query";
  adminReports: AdminReports;
  amTask: AmTask;
  amTasks: AmTaskConnection;
  blockDomains: BlockDomainConnection;
  campaignCategories: CampaignCategoryConnection;
  categories: CategoryConnection;
  category?: Maybe<Category>;
  checklistDefinition?: Maybe<ChecklistDefinition>;
  checklistDefinitions: ChecklistDefinitionConnection;
  creativesScaleConfiguration: CreativesScaleConfiguration;
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  creativesScaleUsers: CreativesScaleUserConnection;
  customer?: Maybe<Customer>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerCampaignInvoice?: Maybe<CustomerCampaignInvoice>;
  customerCampaignInvoices: CustomerCampaignInvoiceConnection;
  customerConversation?: Maybe<CustomerConversation>;
  customerConversations: CustomerConversationConnection;
  customerInvoice?: Maybe<CustomerInvoice>;
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordRankings: CustomerKeywordRankingConnection;
  customerKeywords: CustomerKeywordConnection;
  customerUser?: Maybe<CustomerUser>;
  customers: CustomerConnection;
  domain?: Maybe<Domain>;
  domainStatuses: Array<DomainStatus>;
  domains: DomainConnection;
  emailMessage?: Maybe<EmailMessage>;
  fulfillmentRules: FulfillmentRuleConnection;
  getDomainMetrics: DomainMetricsRespose;
  getGoogleAccounts: GoogleAccountsResponse;
  getGoogleLocations: GoogleLocationsResponse;
  getGoogleReviews: GoogleReviewRespose;
  getKeywordsKeywords: KeywordsSuggestionRespose;
  getKeywordsSite: KeywordsSuggestionRespose;
  getTopicKeyword: TopicKeywordResponse;
  grammarTestQuestion?: Maybe<GrammarTestQuestion>;
  grammarTestQuestions: GrammarTestQuestionConnection;
  keywordLocations: LocationResponse;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationStatuses: Array<LinkLocationStatus>;
  linkLocationTypes: Array<LinkLocationType>;
  linkLocations: LinkLocationConnection;
  order?: Maybe<Order>;
  orderContentItems: OrderContentItemConnection;
  orderStatuses: Array<OrderStatus>;
  orders: OrderConnection;
  partner?: Maybe<Partner>;
  partnerAvailableProduct?: Maybe<PartnerAvailableProduct>;
  partnerInvoice?: Maybe<PartnerInvoice>;
  partnerInvoices: PartnerInvoiceConnection;
  partnerPackage?: Maybe<PartnerPackage>;
  partnerPackages: PartnerPackageConnection;
  partnerProduct?: Maybe<PartnerProduct>;
  partnerProducts: PartnerProductConnection;
  partners: PartnerConnection;
  paymentBatch?: Maybe<PaymentBatch>;
  productTaskTypes: ProductTaskTypeConnection;
  products: ProductConnection;
  publisher?: Maybe<Publisher>;
  publisherLinkLocations: PublisherLinkLocationConnection;
  publisherPayments: PublisherPaymentConnection;
  publishers: PublisherConnection;
  site?: Maybe<Site>;
  siteDeployStatuses: Array<SiteDeployStatus>;
  sitePost?: Maybe<SitePost>;
  sitePostStatuses: Array<SitePostStatus>;
  sitePosts: SitePostConnection;
  sites: SiteConnection;
  task?: Maybe<Task>;
  taskStatuses: Array<TaskStatus>;
  taskTypes: TaskTypeConnection;
  tasks: TaskConnection;
  uberallCategories?: Maybe<Array<UberallCategory>>;
  uberallListingDetails: UberallListingDetailsResponse;
  user?: Maybe<User>;
  userTaskReport?: Maybe<UserTaskReport>;
  userWritingSample?: Maybe<UserWritingSample>;
  userWritingSamples: UserWritingSampleConnection;
  users: UserConnection;
  vendor?: Maybe<Vendor>;
  vendors: VendorConnection;
  version?: Maybe<Scalars["String"]>;
  viewer: Viewer;
  workflow?: Maybe<Workflow>;
  workflows: WorkflowConnection;
  writingSampleTopics: WritingSampleTopicConnection;
};

export type QueryAmTaskArgs = {
  id: Scalars["Int"];
};

export type QueryAmTasksArgs = {
  completedAt?: InputMaybe<DateQuery>;
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryBlockDomainsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCampaignCategoriesArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CampaignCategoriesSort>;
};

export type QueryCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CategoriesSort>;
};

export type QueryCategoryArgs = {
  id: Scalars["Int"];
};

export type QueryChecklistDefinitionArgs = {
  id: Scalars["Int"];
};

export type QueryChecklistDefinitionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryCreativesScaleUserArgs = {
  creativesScaleUserId: Scalars["Int"];
};

export type QueryCreativesScaleUsersArgs = {
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onboardingStatusIds?: InputMaybe<Array<Scalars["String"]>>;
  permissionTaskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryCustomerArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerBusinessListingSpecArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoicesArgs = {
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerCampaignInvoiceSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCustomerConversationArgs = {
  conversationId: Scalars["String"];
};

export type QueryCustomerConversationsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerConversationsSort>;
  wasStartedByCustomer?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCustomerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordRankingsArgs = {
  createdAt?: InputMaybe<DateQuery>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchEngine?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerKeywordRankingSort>;
};

export type QueryCustomerKeywordsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  locationNotNull?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersKeywordSort>;
};

export type QueryCustomerUserArgs = {
  id: Scalars["Int"];
};

export type QueryCustomersArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]>>;
  partnerPortal?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryDomainArgs = {
  id: Scalars["String"];
};

export type QueryDomainsArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DomainsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryEmailMessageArgs = {
  id: Scalars["Int"];
};

export type QueryFulfillmentRulesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetDomainMetricsArgs = {
  domain: Scalars["String"];
};

export type QueryGetGoogleAccountsArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleLocationsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleReviewsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
};

export type QueryGetKeywordsKeywordsArgs = {
  keywords: Array<Scalars["String"]>;
};

export type QueryGetKeywordsSiteArgs = {
  domain: Scalars["String"];
};

export type QueryGetTopicKeywordArgs = {
  keyword: Scalars["String"];
};

export type QueryGrammarTestQuestionArgs = {
  id: Scalars["Int"];
};

export type QueryGrammarTestQuestionsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryKeywordLocationsArgs = {
  country: Scalars["String"];
  search: Scalars["String"];
};

export type QueryLinkLocationArgs = {
  id: Scalars["Int"];
};

export type QueryLinkLocationsArgs = {
  associatedToPublisher?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  excludeCustomerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  minDa?: InputMaybe<Scalars["Int"]>;
  minTraffic?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  secondSort?: InputMaybe<LinkLocationsSort>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryOrderArgs = {
  id: Scalars["Int"];
};

export type QueryOrderContentItemsArgs = {
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
  lastVerifiedPostAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
};

export type QueryOrdersArgs = {
  completedAt?: InputMaybe<DateQuery>;
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<OrdersSort>;
  startDate?: InputMaybe<DateQuery>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
  workflowInternalStatusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerAvailableProductArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnerInvoiceSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerPackageArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerPackagesArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerPackageSort>;
};

export type QueryPartnerProductArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryPartnersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  portal?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnersSort>;
};

export type QueryPaymentBatchArgs = {
  id: Scalars["Int"];
};

export type QueryProductTaskTypesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ProductsSort>;
};

export type QueryPublisherArgs = {
  id: Scalars["Int"];
};

export type QueryPublisherLinkLocationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPublisherPaymentsArgs = {
  checkPublisherAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderIds?: InputMaybe<Array<Scalars["Int"]>>;
  paymentBatchId?: InputMaybe<Scalars["Int"]>;
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  statusLastUpdatedAt?: InputMaybe<DateQuery>;
};

export type QueryPublishersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublishersSort>;
};

export type QuerySiteArgs = {
  siteId: Scalars["String"];
};

export type QuerySitePostArgs = {
  id: Scalars["Int"];
};

export type QuerySitePostsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  siteIds?: InputMaybe<Array<Scalars["String"]>>;
  sort?: InputMaybe<SitePostsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySitesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SitesSort>;
};

export type QueryTaskArgs = {
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
};

export type QueryTaskTypesArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryTasksArgs = {
  assignedAt?: InputMaybe<DateQuery>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]>>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  completedAt?: InputMaybe<DateQuery>;
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  excludedVendorIds?: InputMaybe<Array<Scalars["Int"]>>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  notTaskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  offset?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  productTaskTypes?: InputMaybe<Array<InputMaybe<ProductTaskTypePair>>>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]>>;
  workflowStatuses?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryUberallCategoriesArgs = {
  categories?: InputMaybe<Scalars["String"]>;
  language: Scalars["String"];
  max?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  q?: InputMaybe<Scalars["String"]>;
  roots?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryUberallListingDetailsArgs = {
  locationId: Scalars["Int"];
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type QueryUserTaskReportArgs = {
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  userId: Scalars["Int"];
};

export type QueryUserWritingSampleArgs = {
  userWritingSampleId: Scalars["Int"];
};

export type QueryUserWritingSamplesArgs = {
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  reviewedAt?: InputMaybe<DateQuery>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<UserWritingSamplesSort>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  isAdminOrSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<UsersSort>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type QueryVendorArgs = {
  id: Scalars["Int"];
};

export type QueryVendorsArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  showExternalVendors?: InputMaybe<Scalars["Boolean"]>;
  showSystemVendors?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<VendorsSort>;
};

export type QueryWorkflowArgs = {
  workflowId: Scalars["Int"];
};

export type QueryWorkflowsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryWritingSampleTopicsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type ReCreateGuestOutreachTaskInput = {
  orderId: Scalars["Int"];
};

export type ReCreateGuestOutreachTaskResponse = {
  __typename?: "ReCreateGuestOutreachTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type RecordCustomerCampaignInvoicePaymentInput = {
  amount: Scalars["Float"];
  customerCampaignInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordCustomerCampaignInvoicePaymentResponse = {
  __typename?: "RecordCustomerCampaignInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RecordCustomerInvoicePaymentInput = {
  amount: Scalars["Float"];
  customerInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordCustomerInvoicePaymentResponse = {
  __typename?: "RecordCustomerInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RecordPartnerInvoicePaymentInput = {
  amount: Scalars["Float"];
  partnerInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordPartnerInvoicePaymentResponse = {
  __typename?: "RecordPartnerInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RefreshDomainAvailabilityInput = {
  domainId: Scalars["String"];
};

export type RefreshDomainAvailabilityResponse = {
  __typename?: "RefreshDomainAvailabilityResponse";
  ok: Scalars["Boolean"];
};

export type RejectUserWritingSampleInput = {
  comments: Scalars["String"];
  userWritingSampleId: Scalars["Int"];
};

export type RejectUserWritingSampleResponse = {
  __typename?: "RejectUserWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type RequestPasswordResetInput = {
  email: Scalars["String"];
  origin?: InputMaybe<Scalars["String"]>;
};

export type RequestPasswordResetResponse = {
  __typename?: "RequestPasswordResetResponse";
  wasRequested: Scalars["Boolean"];
};

export type ResendConfirmationEmailInput = {
  creativesScaleUserId?: InputMaybe<Scalars["Int"]>;
  email?: InputMaybe<Scalars["String"]>;
};

export type ResendConfirmationEmailResponse = {
  __typename?: "ResendConfirmationEmailResponse";
  ok: Scalars["Boolean"];
};

export type ResetPasswordInput = {
  id: Scalars["String"];
  password: Scalars["String"];
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  wasReset: Scalars["Boolean"];
};

export type ResolveOrderContentStrategyReviewInput = {
  orderContentStrategyReviewId: Scalars["Int"];
};

export type ResolveOrderContentStrategyReviewResponse = {
  __typename?: "ResolveOrderContentStrategyReviewResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentStrategyReview?: Maybe<OrderContentStrategyReview>;
};

export type ResponseError = {
  __typename?: "ResponseError";
  inputFieldErrors: Array<InputFieldError>;
  message: Scalars["String"];
};

export type ReviewReply = {
  __typename?: "ReviewReply";
  comment?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["String"]>;
};

export type Reviewer = {
  __typename?: "Reviewer";
  displayName?: Maybe<Scalars["String"]>;
  isAnonymous?: Maybe<Scalars["Boolean"]>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
};

export type SaveCustomerBillingConfigInput = {
  customerId: Scalars["Int"];
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveCustomerBillingConfigResponse = {
  __typename?: "SaveCustomerBillingConfigResponse";
  customerBillingConfig: CustomerBillingConfig;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerBusinessListingSpecResponse = {
  __typename?: "SaveCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec: CustomerBusinessListingSpec;
};

export type SaveCustomerHoursOfOperationItemsInput = {
  itemsToCreate: Array<CreateCustomerHoursOfOperationItemInput>;
  itemsToDelete: Array<Scalars["Int"]>;
  itemsToUpdate: Array<UpdateCustomerHoursOfOperationItemInput>;
};

export type SaveCustomerHoursOfOperationItemsResponse = {
  __typename?: "SaveCustomerHoursOfOperationItemsResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveCustomerPremiumBusinessListingSpecInput = {
  customerId: Scalars["Int"];
  hotelAmenities?: InputMaybe<Array<Scalars["String"]>>;
  hotelBathroom?: InputMaybe<Scalars["String"]>;
  hotelFrontDesk?: InputMaybe<Scalars["String"]>;
  hotelHousekeeping?: InputMaybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: InputMaybe<Scalars["String"]>;
  hotelMinimumStay?: InputMaybe<Scalars["String"]>;
  hotelOnSiteStaff?: InputMaybe<Scalars["Boolean"]>;
  hotelSecurity?: InputMaybe<Scalars["String"]>;
  isAlwaysOpen?: InputMaybe<Scalars["Boolean"]>;
  isBlackOwned?: InputMaybe<Scalars["Boolean"]>;
  isWomenOwned?: InputMaybe<Scalars["Boolean"]>;
  priceRangeLevel?: InputMaybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: InputMaybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: InputMaybe<Scalars["String"]>;
  yearsInBusiness?: InputMaybe<Scalars["Int"]>;
};

export type SaveCustomerPremiumBusinessListingSpecResponse = {
  __typename?: "SaveCustomerPremiumBusinessListingSpecResponse";
  customerPremiumBusinessListingSpec: CustomerPremiumBusinessListingSpec;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerQuestionnaireInput = {
  businessDescription?: InputMaybe<Scalars["String"]>;
  callToActionUrls?: InputMaybe<Scalars["String"]>;
  competitorWebsites?: InputMaybe<Scalars["String"]>;
  contentPreference?: InputMaybe<Scalars["String"]>;
  contentPublishingPermissions?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  customerMarketSize?: InputMaybe<Scalars["String"]>;
  isAddImage?: InputMaybe<Scalars["Boolean"]>;
  isRCMPublishing?: InputMaybe<Scalars["Boolean"]>;
  preferredVoice?: InputMaybe<Scalars["String"]>;
  productsOffered?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  serviceArea?: InputMaybe<Scalars["String"]>;
  targetArea?: InputMaybe<Scalars["String"]>;
  targetAudience?: InputMaybe<Scalars["String"]>;
  targetInternalLinks?: InputMaybe<Scalars["String"]>;
  targetKeywords?: InputMaybe<Scalars["String"]>;
  websiteCms?: InputMaybe<Scalars["String"]>;
  websiteHosting?: InputMaybe<Scalars["String"]>;
  whoCanChangeWebsite?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerQuestionnaireResponse = {
  __typename?: "SaveCustomerQuestionnaireResponse";
  customerQuestionnaire: CustomerQuestionnaire;
};

export type SaveCustomerSiteCredentialInput = {
  adminUrl?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerSiteCredentialResponse = {
  __typename?: "SaveCustomerSiteCredentialResponse";
  customerSiteCredential: CustomerSiteCredential;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveGrammarTestQuestionInput = {
  answers?: InputMaybe<Array<GrammarTestQuestionAnswerInput>>;
  grammarTestQuestionId?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  prompt?: InputMaybe<Scalars["String"]>;
};

export type SaveGrammarTestQuestionResponse = {
  __typename?: "SaveGrammarTestQuestionResponse";
  error?: Maybe<ResponseError>;
  grammarTestQuestion?: Maybe<GrammarTestQuestion>;
  ok: Scalars["Boolean"];
};

export type SaveLinkLocationCredentialInput = {
  adminUrl: Scalars["String"];
  instructions?: InputMaybe<Scalars["String"]>;
  linkLocationId: Scalars["Int"];
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type SaveLinkLocationCredentialResponse = {
  __typename?: "SaveLinkLocationCredentialResponse";
  error?: Maybe<ResponseError>;
  linkLocationCredential?: Maybe<LinkLocationCredential>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveOrderContentItemInput = {
  content: Scalars["String"];
  orderId: Scalars["Int"];
  title: Scalars["String"];
};

export type SaveOrderContentItemResponse = {
  __typename?: "SaveOrderContentItemResponse";
  contentItem: ContentItem;
};

export type SavePartnerAnalyticCredentialInput = {
  instructions?: InputMaybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  password?: InputMaybe<Scalars["String"]>;
  userName?: InputMaybe<Scalars["String"]>;
};

export type SavePartnerAnalyticCredentialResponse = {
  __typename?: "SavePartnerAnalyticCredentialResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
  partnerAnalyticCredential?: Maybe<PartnerAnalyticCredential>;
};

export type SaveProposedOrderTopicInput = {
  customerKeywordId: Scalars["Int"];
  customerProposalId: Scalars["Int"];
  orderId: Scalars["Int"];
  taskId: Scalars["Int"];
  topic: Scalars["String"];
};

export type SaveProposedOrderTopicResponse = {
  __typename?: "SaveProposedOrderTopicResponse";
  proposedOrderTopic: ProposedOrderTopic;
};

export type SaveTaskContentIdeationInput = {
  idea: Scalars["String"];
  taskId: Scalars["Int"];
};

export type SaveTaskContentIdeationResponse = {
  __typename?: "SaveTaskContentIdeationResponse";
  contentIdeation: ContentIdeation;
  task: Task;
};

export type SaveTaskContentItemInput = {
  content: Scalars["String"];
  ignoreContentTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SaveTaskContentItemResponse = {
  __typename?: "SaveTaskContentItemResponse";
  contentItem: ContentItem;
  task?: Maybe<Task>;
};

export type SaveTaskGbpItemInput = {
  content: Scalars["String"];
  readMoreUrl?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
};

export type SaveTaskGbpItemResponse = {
  __typename?: "SaveTaskGbpItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveTaskTitleMetaDescriptionInput = {
  isFromReview?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
  titleMetaDescriptions: Array<TitleMetaDescriptionInput>;
};

export type SaveTaskTitleMetaDescriptionResponse = {
  __typename?: "SaveTaskTitleMetaDescriptionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveUserGrammarTestResponseInput = {
  answerId: Scalars["Int"];
};

export type SaveUserGrammarTestResponseResponse = {
  __typename?: "SaveUserGrammarTestResponseResponse";
  ok: Scalars["Boolean"];
};

export type SaveUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type SaveUserProfessionalResponse = {
  __typename?: "SaveUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userProfessional: UserProfessional;
};

export type SaveUserWorkInterestsInput = {
  interests: Array<Scalars["String"]>;
};

export type SaveUserWorkInterestsResponse = {
  __typename?: "SaveUserWorkInterestsResponse";
  interests: Array<Scalars["String"]>;
};

export type SaveWritingSampleInput = {
  content: Scalars["String"];
  isFinished: Scalars["Boolean"];
  title: Scalars["String"];
};

export type SaveWritingSampleResponse = {
  __typename?: "SaveWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userWritingSample?: Maybe<UserWritingSample>;
};

export type SelectWritingSampleTopicInput = {
  writingSampleTopicId?: InputMaybe<Scalars["Int"]>;
};

export type SelectWritingSampleTopicResponse = {
  __typename?: "SelectWritingSampleTopicResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SendEmailManagedSeoInput = {
  customerId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type SendEmailManagedSeoResponse = {
  __typename?: "SendEmailManagedSeoResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SignupCsUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAuthorizedToWorkInUS: Scalars["Boolean"];
  lastName: Scalars["String"];
  password: Scalars["String"];
};

export type SignupCsUserResponse = {
  __typename?: "SignupCSUserResponse";
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Site = {
  __typename?: "Site";
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  deployStatus: SiteDeployStatus;
  domainId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastPublishedAt?: Maybe<Scalars["DateTime"]>;
  siteTemplate?: Maybe<SiteTemplate>;
  siteTemplateId?: Maybe<Scalars["Int"]>;
};

export type SiteConnection = {
  __typename?: "SiteConnection";
  nodes: Array<Site>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type SiteDeployStatus = {
  __typename?: "SiteDeployStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SitePost = {
  __typename?: "SitePost";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  category?: Maybe<Category>;
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars["String"]>;
  status: SitePostStatus;
  statusId: Scalars["String"];
  taskId?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type SitePostConnection = {
  __typename?: "SitePostConnection";
  nodes: Array<SitePost>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type SitePostStatus = {
  __typename?: "SitePostStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum SitePostsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type SiteTemplate = {
  __typename?: "SiteTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export enum SitesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type StartGrammarTestResponse = {
  __typename?: "StartGrammarTestResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type StartWritingSampleResponse = {
  __typename?: "StartWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type StripeOnboardingStatus = {
  __typename?: "StripeOnboardingStatus";
  helpText: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SubmitProposalReviewInput = {
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  reviewComment?: InputMaybe<Scalars["String"]>;
  reviewStatusId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
};

export type SubmitProposalReviewResponse = {
  __typename?: "SubmitProposalReviewResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SyncUberallLocationInput = {
  uberallLocationId: Scalars["Int"];
};

export type SyncUberallLocationResponse = {
  __typename?: "SyncUberallLocationResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type Task = {
  __typename?: "Task";
  assignedAt?: Maybe<Scalars["DateTime"]>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars["Int"]>;
  availableAt: Scalars["DateTime"];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  checklist?: Maybe<Checklist>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  contentCalendarOrders: Array<Order>;
  contentIdeation?: Maybe<ContentIdeation>;
  contentItem?: Maybe<ContentItem>;
  createdAt: Scalars["DateTime"];
  creativesScaleTaskStatus?: Maybe<CreativesScaleTaskStatus>;
  currentSubTask?: Maybe<Task>;
  customer: Customer;
  customerId: Scalars["Int"];
  customerKeywordProposal?: Maybe<CustomerKeywordProposal>;
  customerProposal?: Maybe<CustomerProposal>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  gbpItem?: Maybe<GbpItem>;
  id: Scalars["Int"];
  isAssignedToViewer: Scalars["Boolean"];
  keyword?: Maybe<Scalars["String"]>;
  landingPageUrl?: Maybe<Scalars["String"]>;
  order: Order;
  orderId: Scalars["Int"];
  paymentAmount?: Maybe<Scalars["Float"]>;
  paymentStatus?: Maybe<TaskPaymentStatus>;
  product: Product;
  productId: Scalars["String"];
  publishPostOrders: Array<Order>;
  reviewComment?: Maybe<Scalars["String"]>;
  reviewStatus?: Maybe<TaskReviewStatus>;
  reviewTargetTask?: Maybe<Task>;
  reviewTargetTaskId?: Maybe<Scalars["Int"]>;
  reviewTasks: TaskConnection;
  sitePost?: Maybe<SitePost>;
  status: TaskStatus;
  subTasks: TaskConnection;
  taskType: TaskType;
  taskTypeId: Scalars["String"];
  titleMetaDescriptionItem?: Maybe<TitleMetaDescriptionItem>;
  topic?: Maybe<Scalars["String"]>;
  validationCheckSummary?: Maybe<ValidationCheckSummary>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars["Int"]>;
  workflow?: Maybe<Workflow>;
  workflowId: Scalars["Int"];
};

export type TaskReviewTasksArgs = {
  isFulfilledByCustomer?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  reviewStatusId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
};

export type TaskSubTasksArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type TaskConnection = {
  __typename?: "TaskConnection";
  nodes: Array<Task>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type TaskCount = {
  __typename?: "TaskCount";
  count: Scalars["Int"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  rejectedCount: Scalars["Int"];
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
};

export type TaskPaymentStatus = {
  __typename?: "TaskPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskReport = {
  __typename?: "TaskReport";
  completedAt: Scalars["DateTime"];
  dayNumber: Scalars["Int"];
  taskCount?: Maybe<Array<TaskCount>>;
};

export type TaskReviewStatus = {
  __typename?: "TaskReviewStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskStatus = {
  __typename?: "TaskStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskType = {
  __typename?: "TaskType";
  defaultVendorId: Scalars["Int"];
  id: Scalars["String"];
  isFulfilledByCustomer: Scalars["Boolean"];
  name: Scalars["String"];
};

export type TaskTypeConnection = {
  __typename?: "TaskTypeConnection";
  nodes: Array<TaskType>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum TasksSort {
  CompletedAtAsc = "completed_at_asc",
  CompletedAtDesc = "completed_at_desc",
  IdAsc = "id_asc",
  IdDesc = "id_desc",
  OrderStartAsc = "order_start_asc",
  OrderStartDesc = "order_start_desc",
  Priority = "priority",
  WriterAsc = "writer_asc",
  WriterDesc = "writer_desc",
}

export type TitleMetaDescription = {
  __typename?: "TitleMetaDescription";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  titleTag: Scalars["String"];
  url: Scalars["String"];
};

export type TitleMetaDescriptionInput = {
  id?: InputMaybe<Scalars["Int"]>;
  metaDescription: Scalars["String"];
  titleTag: Scalars["String"];
  url: Scalars["String"];
};

export type TitleMetaDescriptionItem = {
  __typename?: "TitleMetaDescriptionItem";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  titleMetaDescriptions: Array<TitleMetaDescription>;
};

export type TopicKeywordResponse = {
  __typename?: "TopicKeywordResponse";
  topicSuggestions: Scalars["String"];
};

export type UberallCategory = {
  __typename?: "UberallCategory";
  fullName: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  parent: Scalars["Int"];
  selectable: Scalars["Boolean"];
};

export type UberallListingDetails = {
  __typename?: "UberallListingDetails";
  action?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  listingUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  typeName?: Maybe<Scalars["String"]>;
};

export type UberallListingDetailsResponse = {
  __typename?: "UberallListingDetailsResponse";
  countListingsActionRequired?: Maybe<Scalars["Int"]>;
  countListingsBeingUpdated?: Maybe<Scalars["Int"]>;
  countListingsInSync?: Maybe<Scalars["Int"]>;
  countListingsLinked?: Maybe<Scalars["Int"]>;
  countListingsSubmitted?: Maybe<Scalars["Int"]>;
  listings?: Maybe<Array<UberallListingDetails>>;
  totalListings?: Maybe<Scalars["Int"]>;
};

export type UpdateAmTaskInput = {
  amTaskId: Scalars["Int"];
  completedAt?: InputMaybe<Scalars["Date"]>;
  info?: InputMaybe<Scalars["String"]>;
  taskType?: InputMaybe<Scalars["String"]>;
};

export type UpdateAmTaskResponse = {
  __typename?: "UpdateAmTaskResponse";
  amTask?: Maybe<AmTask>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCategoryInput = {
  categoryId: Scalars["Int"];
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateCategoryResponse = {
  __typename?: "UpdateCategoryResponse";
  category: Category;
};

export type UpdateChecklistItemInput = {
  checklistItemId: Scalars["Int"];
  completedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateChecklistItemResponse = {
  __typename?: "UpdateChecklistItemResponse";
  checklistItem: ChecklistItem;
};

export type UpdateCreativeScaleUserInput = {
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  isEligibleForPayments?: InputMaybe<Scalars["Boolean"]>;
  isOptingInForUpdates?: InputMaybe<Scalars["Boolean"]>;
  isRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  isStripeOnboardingReminderEnabled?: InputMaybe<Scalars["Boolean"]>;
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  writerClassification?: InputMaybe<Scalars["String"]>;
};

export type UpdateCreativeScaleUserResponse = {
  __typename?: "UpdateCreativeScaleUserResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDisplay?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  isListed?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  uberallCategoryId?: InputMaybe<Scalars["Int"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerBusinessListingSpecResponse = {
  __typename?: "UpdateCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  customerCampaignId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  startsAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateCustomerCampaignResponse = {
  __typename?: "UpdateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
};

export type UpdateCustomerConversationInput = {
  customerConversationId: Scalars["String"];
  helpRequestedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateCustomerConversationResponse = {
  __typename?: "UpdateCustomerConversationResponse";
  customerConversation: CustomerConversation;
};

export type UpdateCustomerHoursOfOperationItemInput = {
  closeAt?: InputMaybe<Scalars["String"]>;
  dayIndex?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isOpen?: InputMaybe<Scalars["Boolean"]>;
  openAt?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  customerId: Scalars["Int"];
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isBlogFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  isGuestArticleFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  isTrackingRank?: InputMaybe<Scalars["Boolean"]>;
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordProposalInput = {
  customerConversationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordProposalResponse = {
  __typename?: "UpdateCustomerKeywordProposalResponse";
  customerKeywordProposal?: Maybe<CustomerKeywordProposal>;
};

export type UpdateCustomerKeywordResponse = {
  __typename?: "UpdateCustomerKeywordResponse";
  customerKeyword?: Maybe<CustomerKeyword>;
};

export type UpdateCustomerNoteInput = {
  content: Scalars["String"];
  noteId: Scalars["Int"];
};

export type UpdateCustomerNoteResponse = {
  __typename?: "UpdateCustomerNoteResponse";
  note?: Maybe<Note>;
};

export type UpdateCustomerProposalInput = {
  customerProposalId: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerProposalResponse = {
  __typename?: "UpdateCustomerProposalResponse";
  customerProposal?: Maybe<CustomerProposal>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerResponse = {
  __typename?: "UpdateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type UpdateCustomerStatusChangeInput = {
  customerStatusChangeId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerStatusChangeResponse = {
  __typename?: "UpdateCustomerStatusChangeResponse";
  customerStatusChange: CustomerStatusChange;
};

export type UpdateCustomerUserInput = {
  customerUserId: Scalars["Int"];
  isActive: Scalars["Boolean"];
};

export type UpdateCustomerUserResponse = {
  __typename?: "UpdateCustomerUserResponse";
  customerUser: CustomerUser;
};

export type UpdateFeedbackContentItemInput = {
  feedback?: InputMaybe<Scalars["String"]>;
  feedbackContentItemId: Scalars["Int"];
  rating?: InputMaybe<Scalars["Int"]>;
};

export type UpdateFeedbackContentItemResponse = {
  __typename?: "UpdateFeedbackContentItemResponse";
  error?: Maybe<ResponseError>;
  feedbackContentItem?: Maybe<FeedbackContentItem>;
  ok: Scalars["Boolean"];
};

export type UpdateFulfillmentRuleInput = {
  fulfillmentRuleId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type UpdateFulfillmentRuleResponse = {
  __typename?: "UpdateFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  fulfillmentRule?: Maybe<FulfillmentRule>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationCategoriesInput = {
  categoryIds: Array<Scalars["Int"]>;
  linkLocationId: Scalars["Int"];
};

export type UpdateLinkLocationCategoriesResponse = {
  __typename?: "UpdateLinkLocationCategoriesResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationInput = {
  allowsCbdContent?: InputMaybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: InputMaybe<Scalars["Boolean"]>;
  authorityScore?: InputMaybe<Scalars["Int"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  internalQuality?: InputMaybe<Scalars["Int"]>;
  linkLocationId: Scalars["Int"];
  minimumWordCount?: InputMaybe<Scalars["Int"]>;
  monthlyTraffic?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  spam?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["String"]>;
  typeId?: InputMaybe<Scalars["String"]>;
};

export type UpdateLinkLocationMetricsInput = {
  linkLocationId: Scalars["Int"];
};

export type UpdateLinkLocationMetricsResponse = {
  __typename?: "UpdateLinkLocationMetricsResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationResponse = {
  __typename?: "UpdateLinkLocationResponse";
  error?: Maybe<ResponseError>;
  linkLocation?: Maybe<LinkLocation>;
  ok: Scalars["Boolean"];
};

export type UpdateOrderContentItemInput = {
  isPostPublished: Scalars["Boolean"];
  orderContentItemId: Scalars["Int"];
};

export type UpdateOrderContentItemResponse = {
  __typename?: "UpdateOrderContentItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentItem?: Maybe<OrderContentItem>;
};

export type UpdateOrderContentStrategyInput = {
  clientProvidedInstructions?: InputMaybe<Scalars["String"]>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  focusPoints?: InputMaybe<Scalars["String"]>;
  isProposed?: InputMaybe<Scalars["Boolean"]>;
  linkTo?: InputMaybe<Scalars["String"]>;
  orderContentStrategyId: Scalars["Int"];
  orderHaloKeywords?: InputMaybe<Array<Scalars["Int"]>>;
  pageType?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  sampleContent?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  voice?: InputMaybe<Scalars["String"]>;
  webPage?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderContentStrategyResponse = {
  __typename?: "UpdateOrderContentStrategyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentStrategy?: Maybe<OrderContentStrategy>;
};

export type UpdateOrderExternalContentSpecInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  orderExternalContentSpecId: Scalars["Int"];
  trustFlow?: InputMaybe<Scalars["Int"]>;
};

export type UpdateOrderExternalContentSpecResponse = {
  __typename?: "UpdateOrderExternalContentSpecResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
};

export type UpdateOrderGbpItemInput = {
  content: Scalars["String"];
  isProfilePublished: Scalars["Boolean"];
  orderGbpItemId: Scalars["Int"];
  readMoreUrl?: InputMaybe<Scalars["String"]>;
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderGbpItemResponse = {
  __typename?: "UpdateOrderGbpItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderGbpItem?: Maybe<OrderGbpItem>;
};

export type UpdateOrderInput = {
  customerCampaignId?: InputMaybe<Scalars["Int"]>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  linkLocationId?: InputMaybe<Scalars["Int"]>;
  orderId: Scalars["Int"];
  publisherId?: InputMaybe<Scalars["Int"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  resultUrl?: InputMaybe<Scalars["String"]>;
  resultUrlDomainAuthority?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  targetLinkLocationId?: InputMaybe<Scalars["Int"]>;
  targetResultUrl?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  validateResultUrl?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateOrderResponse = {
  __typename?: "UpdateOrderResponse";
  order: Order;
};

export type UpdateOrderTitleMetaDescriptionItemInput = {
  isPublished: Scalars["Boolean"];
  orderTitleMetaDescriptionItemId: Scalars["Int"];
};

export type UpdateOrderTitleMetaDescriptionItemResponse = {
  __typename?: "UpdateOrderTitleMetaDescriptionItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderTitleMetaDescriptionItem?: Maybe<OrderTitleMetaDescriptionItem>;
};

export type UpdatePartnerBillingContactInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  partnerBillingContactId: Scalars["Int"];
};

export type UpdatePartnerBillingContactResponse = {
  __typename?: "UpdatePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerBillingContact?: Maybe<PartnerBillingContact>;
};

export type UpdatePartnerInput = {
  autopayPartnerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  billingEmail?: InputMaybe<Scalars["String"]>;
  emailLogoS3FileId?: InputMaybe<Scalars["String"]>;
  isAutopayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  portal?: InputMaybe<Scalars["String"]>;
  reportingLogoS3FileId?: InputMaybe<Scalars["String"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]>;
};

export type UpdatePartnerPackageInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerPackageId: Scalars["Int"];
};

export type UpdatePartnerPackageItemInput = {
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  partnerPackageItemId: Scalars["Int"];
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePartnerPackageItemResponse = {
  __typename?: "UpdatePartnerPackageItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackageItem?: Maybe<PartnerPackageItem>;
};

export type UpdatePartnerPackageResponse = {
  __typename?: "UpdatePartnerPackageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackage?: Maybe<PartnerPackage>;
};

export type UpdatePartnerProductInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]>;
  checklistDefinitionId?: InputMaybe<Scalars["Int"]>;
  inactiveAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerProductId: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePartnerProductPriceInput = {
  partnerProductPriceId: Scalars["Int"];
  price?: InputMaybe<Scalars["Float"]>;
  startsAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdatePartnerProductPriceResponse = {
  __typename?: "UpdatePartnerProductPriceResponse";
  partnerProductPrice: PartnerProductPrice;
};

export type UpdatePartnerProductResponse = {
  __typename?: "UpdatePartnerProductResponse";
  partnerProduct?: Maybe<PartnerProduct>;
};

export type UpdatePartnerProductWholesalePriceInput = {
  effectiveAt?: InputMaybe<Scalars["DateTime"]>;
  partnerProductWholesalePriceId: Scalars["Int"];
  wholesaleAmount?: InputMaybe<Scalars["Float"]>;
};

export type UpdatePartnerProductWholesalePriceResponse = {
  __typename?: "UpdatePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
};

export type UpdatePartnerResponse = {
  __typename?: "UpdatePartnerResponse";
  partner?: Maybe<Partner>;
};

export type UpdatePartnerUserInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  partnerUserId: Scalars["Int"];
};

export type UpdatePartnerUserResponse = {
  __typename?: "UpdatePartnerUserResponse";
  partnerUser: PartnerUser;
};

export type UpdateProposedCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  proposedCustomerKeywordId: Scalars["Int"];
};

export type UpdateProposedCustomerKeywordResponse = {
  __typename?: "UpdateProposedCustomerKeywordResponse";
  proposedCustomerKeyword: ProposedCustomerKeyword;
};

export type UpdatePublisherInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isInvoiceRequired?: InputMaybe<Scalars["Boolean"]>;
  isSlowPartner?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentMethod?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  publisherId: Scalars["Int"];
};

export type UpdatePublisherLinkLocationInput = {
  isRCMPublishing?: InputMaybe<Scalars["Boolean"]>;
  publisherLinkLocationId: Scalars["Int"];
};

export type UpdatePublisherLinkLocationResponse = {
  __typename?: "UpdatePublisherLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
};

export type UpdatePublisherPaymentInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherPaymentId: Scalars["Int"];
  statusId?: InputMaybe<Scalars["String"]>;
};

export type UpdatePublisherPaymentResponse = {
  __typename?: "UpdatePublisherPaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherPayment: PublisherPayment;
};

export type UpdatePublisherResponse = {
  __typename?: "UpdatePublisherResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisher?: Maybe<Publisher>;
};

export type UpdateRcmWriterTasksInput = {
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  taskIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type UpdateRcmWriterTasksResponse = {
  __typename?: "UpdateRcmWriterTasksResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateSitePostForTaskInput = {
  content: Scalars["String"];
  taskId: Scalars["Int"];
  title: Scalars["String"];
};

export type UpdateSitePostForTaskResponse = {
  __typename?: "UpdateSitePostForTaskResponse";
  sitePost: SitePost;
};

export type UpdateSitePostInput = {
  categoryId?: InputMaybe<Scalars["Int"]>;
  content?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["String"]>;
  sitePostId: Scalars["Int"];
  statusId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateSitePostResponse = {
  __typename?: "UpdateSitePostResponse";
  sitePost: SitePost;
};

export type UpdateTaskInput = {
  assignedUserId?: InputMaybe<Scalars["Int"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  isApproved?: InputMaybe<Scalars["Boolean"]>;
  reviewComment?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UpdateTaskResponse = {
  __typename?: "UpdateTaskResponse";
  task: Task;
};

export type UpdateUserInput = {
  calendarUrl?: InputMaybe<Scalars["String"]>;
  emailPreference?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  profilePictureS3FileId?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserProfessionalResponse = {
  __typename?: "UpdateUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userPorfessional?: Maybe<UserProfessional>;
};

export type UpdateUserResponse = {
  __typename?: "UpdateUserResponse";
  user?: Maybe<User>;
};

export type UpdateVendorInput = {
  name?: InputMaybe<Scalars["String"]>;
  vendorId: Scalars["Int"];
};

export type UpdateVendorResponse = {
  __typename?: "UpdateVendorResponse";
  vendor?: Maybe<Vendor>;
};

export type UpdateVendorUserInput = {
  isActive: Scalars["Boolean"];
  vendorUserId: Scalars["Int"];
};

export type UpdateVendorUserResponse = {
  __typename?: "UpdateVendorUserResponse";
  vendorUser: VendorUser;
};

export type UpdateWritingSampleTopicInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  primaryKeyword?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  voice?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  writingSampleTopicId: Scalars["Int"];
};

export type UpdateWritingSampleTopicResponse = {
  __typename?: "UpdateWritingSampleTopicResponse";
  writingSampleTopic: WritingSampleTopic;
};

export type User = {
  __typename?: "User";
  calendarUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  emailPreference?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isAdmin: Scalars["Boolean"];
  isEmailConfirmed: Scalars["Boolean"];
  isPasswordSet: Scalars["Boolean"];
  isSuperAdmin: Scalars["Boolean"];
  isTracking: Scalars["Boolean"];
  lastName: Scalars["String"];
  partnerUsers: Array<PartnerUser>;
  partners: Array<Partner>;
  profilePictureFile?: Maybe<File>;
  timezone: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  userProfessional?: Maybe<UserProfessional>;
  userTaskPermissions: Array<UserTaskPermission>;
  vendorUsers: Array<VendorUser>;
};

export type UserUserTaskPermissionsArgs = {
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  nodes: Array<User>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type UserGrammarTest = {
  __typename?: "UserGrammarTest";
  activeQuestion?: Maybe<UserGrammarTestQuestion>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  expiresAt: Scalars["DateTime"];
  id: Scalars["Int"];
  result?: Maybe<Scalars["String"]>;
  startedAt: Scalars["DateTime"];
  userId: Scalars["Int"];
};

export type UserGrammarTestQuestion = {
  __typename?: "UserGrammarTestQuestion";
  createdAt: Scalars["DateTime"];
  grammarTestQuestion: GrammarTestQuestion;
  grammarTestQuestionId: Scalars["Int"];
  id: Scalars["Int"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
  option1Answer?: Maybe<GrammarTestQuestionAnswer>;
  option1AnswerId: Scalars["Int"];
  option2Answer?: Maybe<GrammarTestQuestionAnswer>;
  option2AnswerId: Scalars["Int"];
  option3Answer?: Maybe<GrammarTestQuestionAnswer>;
  option3AnswerId?: Maybe<Scalars["Int"]>;
  option4Answer?: Maybe<GrammarTestQuestionAnswer>;
  option4AnswerId?: Maybe<Scalars["Int"]>;
  questionIndex: Scalars["Int"];
  selectedAnswerId?: Maybe<Scalars["Int"]>;
  userId: Scalars["Int"];
};

export type UserProfessional = {
  __typename?: "UserProfessional";
  createdAt: Scalars["DateTime"];
  linkedInProfileUrl?: Maybe<Scalars["String"]>;
  resumeFile?: Maybe<File>;
  user: User;
  userId: Scalars["Int"];
};

export type UserTaskPermission = {
  __typename?: "UserTaskPermission";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
  userId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type UserTaskReport = {
  __typename?: "UserTaskReport";
  taskReports?: Maybe<Array<TaskReport>>;
  user: User;
  userId: Scalars["Int"];
};

export type UserWritingSample = {
  __typename?: "UserWritingSample";
  contentItem?: Maybe<ContentItem>;
  contentItemId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  reviewStatus?: Maybe<UserWritingSampleReviewStatus>;
  reviewerUser?: Maybe<User>;
  user: User;
  validationCheckSummary?: Maybe<ValidationCheckSummary>;
  writingSampleTopic?: Maybe<WritingSampleTopic>;
  writingSampleTopicId?: Maybe<Scalars["Int"]>;
};

export type UserWritingSampleConnection = {
  __typename?: "UserWritingSampleConnection";
  nodes: Array<UserWritingSample>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type UserWritingSampleReviewStatus = {
  __typename?: "UserWritingSampleReviewStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum UserWritingSamplesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export enum UsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ValidationCheck = {
  __typename?: "ValidationCheck";
  actualValue: Scalars["String"];
  failureCriteria: Scalars["String"];
  helpText: Scalars["String"];
  id: Scalars["String"];
  isRequiredToPass: Scalars["Boolean"];
  isValid: Scalars["Boolean"];
  name: Scalars["String"];
  successCriteria: Scalars["String"];
};

export type ValidationCheckSummary = {
  __typename?: "ValidationCheckSummary";
  checks: Array<ValidationCheck>;
  isValid: Scalars["Boolean"];
};

export type Vendor = {
  __typename?: "Vendor";
  associatedPartnerProducts: PartnerProductConnection;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isExternal: Scalars["Boolean"];
  name: Scalars["String"];
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars["Int"]>;
  vendorUsers: VendorUserConnection;
};

export type VendorAssociatedPartnerProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type VendorVendorUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type VendorConnection = {
  __typename?: "VendorConnection";
  nodes: Array<Vendor>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type VendorUser = {
  __typename?: "VendorUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  user: User;
  userId: Scalars["Int"];
  userTaskPermissions: Array<UserTaskPermission>;
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type VendorUserConnection = {
  __typename?: "VendorUserConnection";
  nodes: Array<VendorUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum VendorsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type VerifyGuestPostInput = {
  orderIds: Array<Scalars["Int"]>;
};

export type VerifyGuestPostResponse = {
  __typename?: "VerifyGuestPostResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type Viewer = {
  __typename?: "Viewer";
  accessibleCustomerIds: Array<Scalars["Int"]>;
  accessiblePublisherIds: Array<Scalars["Int"]>;
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  hasSLPCustomer?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<User>;
};

export type WorkInterest = {
  __typename?: "WorkInterest";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Workflow = {
  __typename?: "Workflow";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  id: Scalars["Int"];
  internalStatus: Scalars["String"];
  lastUpdatedAt: Scalars["DateTime"];
  notes: NoteConnection;
  order: Order;
  status: WorkflowStatus;
  tasks: TaskConnection;
  type: Scalars["String"];
};

export type WorkflowNotesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type WorkflowTasksArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<TasksSort>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type WorkflowConnection = {
  __typename?: "WorkflowConnection";
  nodes: Array<Workflow>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type WorkflowStatus = {
  __typename?: "WorkflowStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type WritingSampleTopic = {
  __typename?: "WritingSampleTopic";
  additionalInformation?: Maybe<Scalars["String"]>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  primaryKeyword?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
  voice?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type WritingSampleTopicConnection = {
  __typename?: "WritingSampleTopicConnection";
  nodes: Array<WritingSampleTopic>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CategoriesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type CategoriesForSelectorQuery = {
  __typename?: "Query";
  categories: {
    __typename?: "CategoryConnection";
    nodes: Array<{ __typename?: "Category"; id: number; name: string }>;
  };
};

export type CustomerKeywordSelector_KeywordFragment = {
  __typename?: "CustomerKeyword";
  id: number;
  displayName: string;
  isActive: boolean;
  isPrimary: boolean;
};

export type CustomerKeywordSelectorQueryVariables = Exact<{
  customerId: Scalars["Int"];
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomerKeywordSelectorQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerKeywords: {
      __typename?: "CustomerKeywordConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "CustomerKeyword";
        id: number;
        displayName: string;
        isActive: boolean;
        isPrimary: boolean;
      }>;
    };
  } | null;
};

export type CustomersForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type CustomersForSelectorQuery = {
  __typename?: "Query";
  customers: { __typename?: "CustomerConnection"; nodes: Array<{ __typename?: "Customer"; id: number; name: string }> };
};

export type JourneyStepGrammarAssessmentQueryVariables = Exact<{ [key: string]: never }>;

export type JourneyStepGrammarAssessmentQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      retryGrammarTestAt?: Date | null;
      activeUserGrammarTest?: { __typename?: "UserGrammarTest"; id: number; startedAt: Date; expiresAt: Date } | null;
    } | null;
  };
};

export type StartGrammarTestMutationVariables = Exact<{ [key: string]: never }>;

export type StartGrammarTestMutation = {
  __typename?: "Mutation";
  startGrammarTest: {
    __typename?: "StartGrammarTestResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type JourneyStepWritingSampleQueryVariables = Exact<{ [key: string]: never }>;

export type JourneyStepWritingSampleQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      retryWritingSampleAt?: Date | null;
      activeWritingSample?: { __typename?: "UserWritingSample"; id: number; endsAt: Date } | null;
    } | null;
  };
};

export type StartWritingSampleMutationVariables = Exact<{ [key: string]: never }>;

export type StartWritingSampleMutation = {
  __typename?: "Mutation";
  startWritingSample: {
    __typename?: "StartWritingSampleResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type OnboardingRouteAuthQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingRouteAuthQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: { __typename?: "CreativesScaleUser"; id: number; onboardingStatusId: string } | null;
  };
};

export type Comment_ReviewTaskFragment = {
  __typename?: "Task";
  id: number;
  createdAt: Date;
  completedAt?: Date | null;
  taskTypeId: string;
  reviewComment?: string | null;
  reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
};

export type RouteAuthorizationQueryVariables = Exact<{ [key: string]: never }>;

export type RouteAuthorizationQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: { __typename?: "CreativesScaleUser"; id: number; onboardingStatusId: string } | null;
  };
};

export type StripeActionBarQueryVariables = Exact<{ [key: string]: never }>;

export type StripeActionBarQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      isEligibleForPayments: boolean;
      stripeAccountStatus: { __typename?: "StripeOnboardingStatus"; id: string; name: string; helpText: string };
    } | null;
  };
};

export type TaskTypeSelectorQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type TaskTypeSelectorQuery = {
  __typename?: "Query";
  taskTypes: {
    __typename?: "TaskTypeConnection";
    nodes: Array<{ __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean }>;
  };
};

export type UploadFileDialog_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadInput;
}>;

export type UploadFileDialog_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUpload: { __typename?: "GenerateUrlForFileUploadResponse"; url: string; fileId: string };
};

export type UserSelectorV2QueryVariables = Exact<{ [key: string]: never }>;

export type UserSelectorV2Query = {
  __typename?: "Query";
  creativesScaleUsers: {
    __typename?: "CreativesScaleUserConnection";
    nodes: Array<{
      __typename?: "CreativesScaleUser";
      id: number;
      user: { __typename?: "User"; id: number; fullName: string };
    }>;
  };
};

export type TaskReport_UserTaskReportFragment = {
  __typename?: "TaskReport";
  dayNumber: number;
  completedAt: Date;
  taskCount?: Array<{
    __typename?: "TaskCount";
    count: number;
    rejectedCount: number;
    taskType?: { __typename?: "TaskType"; id: string; name: string } | null;
  }> | null;
};

export type UserTaskReportQueryVariables = Exact<{
  userId: Scalars["Int"];
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type UserTaskReportQuery = {
  __typename?: "Query";
  userTaskReport?: {
    __typename?: "UserTaskReport";
    user: { __typename?: "User"; id: number; fullName: string };
    taskReports?: Array<{
      __typename?: "TaskReport";
      dayNumber: number;
      completedAt: Date;
      taskCount?: Array<{
        __typename?: "TaskCount";
        count: number;
        rejectedCount: number;
        taskType?: { __typename?: "TaskType"; id: string; name: string } | null;
      }> | null;
    }> | null;
  } | null;
};

export type ValidationChecklistPanel_SummaryFragment = {
  __typename?: "ValidationCheckSummary";
  isValid: boolean;
  checks: Array<{
    __typename?: "ValidationCheck";
    id: string;
    name: string;
    helpText: string;
    isValid: boolean;
    actualValue: string;
    successCriteria: string;
    failureCriteria: string;
    isRequiredToPass: boolean;
  }>;
};

export type AuthManager_ViewerFragment = {
  __typename?: "Viewer";
  user?: {
    __typename?: "User";
    id: number;
    fullName: string;
    userTaskPermissions: Array<{
      __typename?: "UserTaskPermission";
      id: number;
      productId?: string | null;
      taskTypeId?: string | null;
    }>;
  } | null;
  creativesScaleUser?: {
    __typename?: "CreativesScaleUser";
    id: number;
    onboardingStatusId: string;
    isAdmin: boolean;
    unpaidBalance: number;
    allTimeEarnedAmount: number;
    isRCMWriter: boolean;
    countryOfResidenceCountries: Array<{ __typename?: "Country"; id: string; name: string }>;
    workInterests: Array<{ __typename?: "WorkInterest"; id: string }>;
  } | null;
};

export type AuthManager_ViewerUserFragment = {
  __typename?: "User";
  id: number;
  fullName: string;
  userTaskPermissions: Array<{
    __typename?: "UserTaskPermission";
    id: number;
    productId?: string | null;
    taskTypeId?: string | null;
  }>;
};

export type AuthManager_CreativesScaleUserFragment = {
  __typename?: "CreativesScaleUser";
  id: number;
  onboardingStatusId: string;
  isAdmin: boolean;
  unpaidBalance: number;
  allTimeEarnedAmount: number;
  isRCMWriter: boolean;
  countryOfResidenceCountries: Array<{ __typename?: "Country"; id: string; name: string }>;
  workInterests: Array<{ __typename?: "WorkInterest"; id: string }>;
};

export type AuthManagerQueryVariables = Exact<{ [key: string]: never }>;

export type AuthManagerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    user?: {
      __typename?: "User";
      id: number;
      fullName: string;
      userTaskPermissions: Array<{
        __typename?: "UserTaskPermission";
        id: number;
        productId?: string | null;
        taskTypeId?: string | null;
      }>;
    } | null;
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      onboardingStatusId: string;
      isAdmin: boolean;
      unpaidBalance: number;
      allTimeEarnedAmount: number;
      isRCMWriter: boolean;
      countryOfResidenceCountries: Array<{ __typename?: "Country"; id: string; name: string }>;
      workInterests: Array<{ __typename?: "WorkInterest"; id: string }>;
    } | null;
  };
};

export type LogoutUserMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutUserMutation = {
  __typename?: "Mutation";
  logoutUser: { __typename?: "LogoutUserResponse"; isLoggedOut: boolean };
};

export type AdminGrammarTestQuestionFragment = {
  __typename?: "GrammarTestQuestion";
  id: number;
  prompt: string;
  isActive: boolean;
  totalAttempts: number;
  totalCorrectAttempts: number;
  answers: Array<{ __typename?: "GrammarTestQuestionAnswer"; id: number; content: string; isCorrect?: boolean | null }>;
};

export type AdminGrammarTestQuestionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type AdminGrammarTestQuestionsQuery = {
  __typename?: "Query";
  grammarTestQuestions: {
    __typename?: "GrammarTestQuestionConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "GrammarTestQuestion";
      id: number;
      prompt: string;
      isActive: boolean;
      totalAttempts: number;
      totalCorrectAttempts: number;
      answers: Array<{
        __typename?: "GrammarTestQuestionAnswer";
        id: number;
        content: string;
        isCorrect?: boolean | null;
      }>;
    }>;
  };
};

export type SaveGrammarTestQuestionMutationVariables = Exact<{
  input: SaveGrammarTestQuestionInput;
}>;

export type SaveGrammarTestQuestionMutation = {
  __typename?: "Mutation";
  saveGrammarTestQuestion: {
    __typename?: "SaveGrammarTestQuestionResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GrammarTestQuestionQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GrammarTestQuestionQuery = {
  __typename?: "Query";
  grammarTestQuestion?: {
    __typename?: "GrammarTestQuestion";
    id: number;
    prompt: string;
    isActive: boolean;
    answers: Array<{
      __typename?: "GrammarTestQuestionAnswer";
      id: number;
      content: string;
      isCorrect?: boolean | null;
    }>;
  } | null;
};

export type AdminQaView_TaskFragment = {
  __typename?: "Task";
  id: number;
  availableAt: Date;
  createdAt: Date;
  completedAt?: Date | null;
  assignedAt?: Date | null;
  keyword?: string | null;
  creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
  assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  customer: { __typename?: "Customer"; id: number; name: string };
  order: { __typename?: "Order"; id: number; topic?: string | null };
  contentItem?: {
    __typename?: "ContentItem";
    id: number;
    feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
  } | null;
  reviewTasksByQa: {
    __typename?: "TaskConnection";
    nodes: Array<{
      __typename?: "Task";
      id: number;
      completedAt?: Date | null;
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
    }>;
  };
  reviewTasksQaCount: { __typename?: "TaskConnection"; totalCount: number };
  reviewTasksByCustomer: { __typename?: "TaskConnection"; totalCount: number };
  product: { __typename?: "Product"; id: string; name: string };
};

export type AdminQaViewTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  completedAt?: InputMaybe<DateQuery>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  sort?: InputMaybe<TasksSort>;
}>;

export type AdminQaViewTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      availableAt: Date;
      createdAt: Date;
      completedAt?: Date | null;
      assignedAt?: Date | null;
      keyword?: string | null;
      creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      customer: { __typename?: "Customer"; id: number; name: string };
      order: { __typename?: "Order"; id: number; topic?: string | null };
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        feedbackContentItems: Array<{
          __typename?: "FeedbackContentItem";
          id: number;
          rating: number;
          feedback: string;
        }>;
      } | null;
      reviewTasksByQa: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          completedAt?: Date | null;
          assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
        }>;
      };
      reviewTasksQaCount: { __typename?: "TaskConnection"; totalCount: number };
      reviewTasksByCustomer: { __typename?: "TaskConnection"; totalCount: number };
      product: { __typename?: "Product"; id: string; name: string };
    }>;
  };
};

export type AdminTasks_TaskFragment = {
  __typename?: "Task";
  id: number;
  availableAt: Date;
  createdAt: Date;
  completedAt?: Date | null;
  assignedAt?: Date | null;
  keyword?: string | null;
  creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
  assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  customer: { __typename?: "Customer"; id: number; name: string };
  order: { __typename?: "Order"; id: number };
  product: { __typename?: "Product"; id: string; name: string };
};

export type AdminTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type AdminTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      availableAt: Date;
      createdAt: Date;
      completedAt?: Date | null;
      assignedAt?: Date | null;
      keyword?: string | null;
      creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      customer: { __typename?: "Customer"; id: number; name: string };
      order: { __typename?: "Order"; id: number };
      product: { __typename?: "Product"; id: string; name: string };
    }>;
  };
};

export type TeamMemberProfile_CreatePermissionMutationVariables = Exact<{
  input: CreateUserTaskPermissionInput;
}>;

export type TeamMemberProfile_CreatePermissionMutation = {
  __typename?: "Mutation";
  createUserTaskPermission: {
    __typename?: "CreateUserTaskPermissionResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type TeamMemberProfile_DeletePermissionMutationVariables = Exact<{
  input: DeleteUserTaskPermissionInput;
}>;

export type TeamMemberProfile_DeletePermissionMutation = {
  __typename?: "Mutation";
  deleteUserTaskPermission: {
    __typename?: "DeleteUserTaskPermissionResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type TeamMemberProfile_ProductTaskTypeFragment = {
  __typename?: "ProductTaskType";
  id: string;
  product: { __typename?: "Product"; id: string; name: string };
  taskType: { __typename?: "TaskType"; id: string; name: string };
};

export type TeamMemberProfile_UserTaskPermissionFragment = {
  __typename?: "UserTaskPermission";
  id: number;
  product?: { __typename?: "Product"; id: string; name: string } | null;
  taskType?: { __typename?: "TaskType"; id: string; name: string } | null;
};

export type TeamMemberProfileQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type TeamMemberProfileQuery = {
  __typename?: "Query";
  creativesScaleUser?: {
    __typename?: "CreativesScaleUser";
    id: number;
    avgStarRating: number;
    isRCMWriter: boolean;
    onboardingStatusLastUpdatedAt?: Date | null;
    stripeAccountId?: string | null;
    writerClassification?: string | null;
    user: {
      __typename?: "User";
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      userTaskPermissions: Array<{
        __typename?: "UserTaskPermission";
        id: number;
        product?: { __typename?: "Product"; id: string; name: string } | null;
        taskType?: { __typename?: "TaskType"; id: string; name: string } | null;
      }>;
      userProfessional?: {
        __typename?: "UserProfessional";
        linkedInProfileUrl?: string | null;
        resumeFile?: { __typename?: "File"; id: string; url: string; name: string } | null;
      } | null;
    };
    onboardingStatus: { __typename?: "OnboardingStatus"; id: string; name: string };
    workInterests: Array<{ __typename?: "WorkInterest"; id: string; name: string }>;
    latestWritingSample?: {
      __typename?: "UserWritingSample";
      reviewStatus?: { __typename?: "UserWritingSampleReviewStatus"; id: string; name: string } | null;
      reviewerUser?: { __typename?: "User"; id: number; fullName: string } | null;
    } | null;
    latestUserGrammarTest?: {
      __typename?: "UserGrammarTest";
      id: number;
      completedAt?: Date | null;
      result?: string | null;
    } | null;
  } | null;
  productTaskTypes: {
    __typename?: "ProductTaskTypeConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "ProductTaskType";
      id: string;
      product: { __typename?: "Product"; id: string; name: string };
      taskType: { __typename?: "TaskType"; id: string; name: string };
    }>;
  };
};

export type UpdateTeamMemberProfileMutationVariables = Exact<{
  input?: InputMaybe<UpdateCreativeScaleUserInput>;
}>;

export type UpdateTeamMemberProfileMutation = {
  __typename?: "Mutation";
  updateCreativesScaleUser: {
    __typename?: "UpdateCreativeScaleUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type TaskHistoryFragment = {
  __typename?: "Task";
  id: number;
  topic?: string | null;
  completedAt?: Date | null;
  paymentAmount?: number | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  category?: { __typename?: "Category"; id: number; name: string } | null;
  creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
  contentItem?: {
    __typename?: "ContentItem";
    id: number;
    probability?: number | null;
    feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
  } | null;
  order: { __typename?: "Order"; id: number };
  paymentStatus?: { __typename?: "TaskPaymentStatus"; id: string; name: string } | null;
  reviewTasksQa: { __typename?: "TaskConnection"; totalCount: number };
  reviewTasksCustomer: { __typename?: "TaskConnection"; totalCount: number };
};

export type TasksHistoryQueryVariables = Exact<{
  currentLimit?: InputMaybe<Scalars["Int"]>;
  currentOffset?: InputMaybe<Scalars["Int"]>;
  currentSort?: InputMaybe<TasksSort>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  currentStatusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  historyStatusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  historyLimit?: InputMaybe<Scalars["Int"]>;
  historyOffset?: InputMaybe<Scalars["Int"]>;
  historySort?: InputMaybe<TasksSort>;
}>;

export type TasksHistoryQuery = {
  __typename?: "Query";
  currentTasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      topic?: string | null;
      completedAt?: Date | null;
      paymentAmount?: number | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      category?: { __typename?: "Category"; id: number; name: string } | null;
      creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        probability?: number | null;
        feedbackContentItems: Array<{
          __typename?: "FeedbackContentItem";
          id: number;
          rating: number;
          feedback: string;
        }>;
      } | null;
      order: { __typename?: "Order"; id: number };
      paymentStatus?: { __typename?: "TaskPaymentStatus"; id: string; name: string } | null;
      reviewTasksQa: { __typename?: "TaskConnection"; totalCount: number };
      reviewTasksCustomer: { __typename?: "TaskConnection"; totalCount: number };
    }>;
  };
  historyTasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      topic?: string | null;
      completedAt?: Date | null;
      paymentAmount?: number | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      category?: { __typename?: "Category"; id: number; name: string } | null;
      creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        probability?: number | null;
        feedbackContentItems: Array<{
          __typename?: "FeedbackContentItem";
          id: number;
          rating: number;
          feedback: string;
        }>;
      } | null;
      order: { __typename?: "Order"; id: number };
      paymentStatus?: { __typename?: "TaskPaymentStatus"; id: string; name: string } | null;
      reviewTasksQa: { __typename?: "TaskConnection"; totalCount: number };
      reviewTasksCustomer: { __typename?: "TaskConnection"; totalCount: number };
    }>;
  };
};

export type TasksHistoryTeamMemberQueryVariables = Exact<{
  creativesScaleUserId: Scalars["Int"];
}>;

export type TasksHistoryTeamMemberQuery = {
  __typename?: "Query";
  creativesScaleUser?: {
    __typename?: "CreativesScaleUser";
    id: number;
    user: { __typename?: "User"; id: number };
  } | null;
};

export type UpdateTeamMemberMutationVariables = Exact<{
  input?: InputMaybe<UpdateCreativeScaleUserInput>;
}>;

export type UpdateTeamMemberMutation = {
  __typename?: "Mutation";
  updateCreativesScaleUser: {
    __typename?: "UpdateCreativeScaleUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type TeamMemberListFragment = {
  __typename?: "CreativesScaleUser";
  id: number;
  createdAt: Date;
  isAdmin: boolean;
  isEligibleForPayments: boolean;
  user: { __typename?: "User"; id: number; fullName: string; email: string };
  onboardingStatus: { __typename?: "OnboardingStatus"; id: string; name: string };
};

export type TeamMembersQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  onboardingStatusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type TeamMembersQuery = {
  __typename?: "Query";
  creativesScaleUsers: {
    __typename?: "CreativesScaleUserConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "CreativesScaleUser";
      id: number;
      createdAt: Date;
      isAdmin: boolean;
      isEligibleForPayments: boolean;
      user: { __typename?: "User"; id: number; fullName: string; email: string };
      onboardingStatus: { __typename?: "OnboardingStatus"; id: string; name: string };
    }>;
  };
};

export type AdminUserReportQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onboardingStatusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type AdminUserReportQuery = {
  __typename?: "Query";
  creativesScaleUsers: {
    __typename?: "CreativesScaleUserConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "CreativesScaleUser"; user: { __typename?: "User"; id: number } }>;
  };
};

export type UserWritingSampleQueryVariables = Exact<{
  userWritingSampleId: Scalars["Int"];
}>;

export type UserWritingSampleQuery = {
  __typename?: "Query";
  userWritingSample?: {
    __typename?: "UserWritingSample";
    id: number;
    endsAt: Date;
    writingSampleTopic?: {
      __typename?: "WritingSampleTopic";
      topic: string;
      category?: { __typename?: "Category"; id: number; name: string } | null;
    } | null;
    user: {
      __typename?: "User";
      id: number;
      fullName: string;
      userProfessional?: {
        __typename?: "UserProfessional";
        userId: number;
        linkedInProfileUrl?: string | null;
        resumeFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
      } | null;
    };
    contentItem?: { __typename?: "ContentItem"; title: string; content: string } | null;
    validationCheckSummary?: {
      __typename?: "ValidationCheckSummary";
      isValid: boolean;
      checks: Array<{
        __typename?: "ValidationCheck";
        id: string;
        name: string;
        helpText: string;
        isValid: boolean;
        actualValue: string;
        successCriteria: string;
        failureCriteria: string;
        isRequiredToPass: boolean;
      }>;
    } | null;
  } | null;
};

export type AdminWritingSampleTopics_CreateMutationVariables = Exact<{
  input: CreateWritingSampleTopicInput;
}>;

export type AdminWritingSampleTopics_CreateMutation = {
  __typename?: "Mutation";
  createWritingSampleTopic: {
    __typename?: "CreateWritingSampleTopicResponse";
    writingSampleTopic: { __typename?: "WritingSampleTopic"; id: number };
  };
};

export type AdminWritingSampleTopics_UpdateMutationVariables = Exact<{
  input: UpdateWritingSampleTopicInput;
}>;

export type AdminWritingSampleTopics_UpdateMutation = {
  __typename?: "Mutation";
  updateWritingSampleTopic: {
    __typename?: "UpdateWritingSampleTopicResponse";
    writingSampleTopic: { __typename?: "WritingSampleTopic"; id: number };
  };
};

export type AdminWritingSampleTopicFragment = {
  __typename?: "WritingSampleTopic";
  id: number;
  createdAt: string;
  topic: string;
  isActive: boolean;
  primaryKeyword?: string | null;
  voice?: string | null;
  website?: string | null;
  additionalInformation?: string | null;
  category?: { __typename?: "Category"; id: number; name: string } | null;
};

export type AdminWritingSampleTopicsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type AdminWritingSampleTopicsQuery = {
  __typename?: "Query";
  writingSampleTopics: {
    __typename?: "WritingSampleTopicConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "WritingSampleTopic";
      id: number;
      createdAt: string;
      topic: string;
      isActive: boolean;
      primaryKeyword?: string | null;
      voice?: string | null;
      website?: string | null;
      additionalInformation?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
    }>;
  };
};

export type ApproveUserWritingSampleMutationVariables = Exact<{
  input: ApproveUserWritingSampleInput;
}>;

export type ApproveUserWritingSampleMutation = {
  __typename?: "Mutation";
  approveUserWritingSample: {
    __typename?: "ApproveUserWritingSampleResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type RejectUserWritingSampleMutationVariables = Exact<{
  input: RejectUserWritingSampleInput;
}>;

export type RejectUserWritingSampleMutation = {
  __typename?: "Mutation";
  rejectUserWritingSample: {
    __typename?: "RejectUserWritingSampleResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type WritingSamplesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<UserWritingSamplesSort>;
  search?: InputMaybe<Scalars["String"]>;
  reviewedAt?: InputMaybe<DateQuery>;
}>;

export type WritingSamplesQuery = {
  __typename?: "Query";
  userWritingSamples: {
    __typename?: "UserWritingSampleConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "UserWritingSample";
      id: number;
      writingSampleTopic?: {
        __typename?: "WritingSampleTopic";
        topic: string;
        category?: { __typename?: "Category"; id: number; name: string } | null;
      } | null;
      user: { __typename?: "User"; id: number; fullName: string };
      contentItem?: { __typename?: "ContentItem"; title: string } | null;
    }>;
  };
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = {
  __typename?: "Mutation";
  confirmEmail: { __typename?: "ConfirmEmailResponse"; ok: boolean };
};

export type FindTasksFilter_ProductTaskTypeFragment = {
  __typename?: "ProductTaskType";
  id: string;
  product: { __typename?: "Product"; id: string; name: string };
  taskType: { __typename?: "TaskType"; id: string; name: string };
};

export type FindTasksFilterQueryVariables = Exact<{ [key: string]: never }>;

export type FindTasksFilterQuery = {
  __typename?: "Query";
  categories: {
    __typename?: "CategoryConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "Category"; id: number; name: string; isActive: boolean }>;
  };
  productTaskTypes: {
    __typename?: "ProductTaskTypeConnection";
    nodes: Array<{
      __typename?: "ProductTaskType";
      id: string;
      product: { __typename?: "Product"; id: string; name: string };
      taskType: { __typename?: "TaskType"; id: string; name: string };
    }>;
  };
  taskTypes: {
    __typename?: "TaskTypeConnection";
    nodes: Array<{ __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean }>;
  };
};

export type FindTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  statusIds: Array<Scalars["String"]> | Scalars["String"];
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  productTaskTypes?: InputMaybe<Array<InputMaybe<ProductTaskTypePair>> | InputMaybe<ProductTaskTypePair>>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type FindTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      paymentAmount?: number | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      category?: { __typename?: "Category"; id: number; name: string } | null;
      order: { __typename?: "Order"; id: number; topic?: string | null };
      product: { __typename?: "Product"; id: string; name: string; minWordCount?: number | null };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        isGuestArticleFulfilledByRCMWriter: boolean;
        isBlogFulfilledByRCMWriter: boolean;
      };
    }>;
  };
};

export type ClaimTaskMutationVariables = Exact<{
  input: ClaimTaskInput;
}>;

export type ClaimTaskMutation = {
  __typename?: "Mutation";
  claimTask: {
    __typename?: "ClaimTaskResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset: { __typename?: "RequestPasswordResetResponse"; wasRequested: boolean };
};

export type LoginMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null };
};

export type MyTaskFragment = {
  __typename?: "Task";
  id: number;
  completedAt?: Date | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
  status: { __typename?: "TaskStatus"; id: string; name: string };
  category?: { __typename?: "Category"; id: number; name: string } | null;
  order: { __typename?: "Order"; id: number; topic?: string | null };
  product: { __typename?: "Product"; id: string; name: string };
  contentItem?: {
    __typename?: "ContentItem";
    id: number;
    probability?: number | null;
    feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
  } | null;
};

export type MyTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type MyTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      completedAt?: Date | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      creativesScaleTaskStatus?: { __typename?: "CreativesScaleTaskStatus"; id: string; name: string } | null;
      status: { __typename?: "TaskStatus"; id: string; name: string };
      category?: { __typename?: "Category"; id: number; name: string } | null;
      order: { __typename?: "Order"; id: number; topic?: string | null };
      product: { __typename?: "Product"; id: string; name: string };
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        probability?: number | null;
        feedbackContentItems: Array<{
          __typename?: "FeedbackContentItem";
          id: number;
          rating: number;
          feedback: string;
        }>;
      } | null;
    }>;
  };
};

export type ResendConfirmationEmailMutationVariables = Exact<{
  input: ResendConfirmationEmailInput;
}>;

export type ResendConfirmationEmailMutation = {
  __typename?: "Mutation";
  resendConfirmationEmail: { __typename?: "ResendConfirmationEmailResponse"; ok: boolean };
};

export type LastGrammarTestResultQueryVariables = Exact<{ [key: string]: never }>;

export type LastGrammarTestResultQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      onboardingStatusId: string;
      latestUserGrammarTest?: {
        __typename?: "UserGrammarTest";
        id: number;
        result?: string | null;
        completedAt?: Date | null;
      } | null;
    } | null;
  };
};

export type SaveUserGrammarTestResponseMutationVariables = Exact<{
  input: SaveUserGrammarTestResponseInput;
}>;

export type SaveUserGrammarTestResponseMutation = {
  __typename?: "Mutation";
  saveUserGrammarTestResponse: { __typename?: "SaveUserGrammarTestResponseResponse"; ok: boolean };
};

export type GrammarTest_OptionFragment = { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string };

export type GrammarTest_ActiveQuestionFragment = {
  __typename?: "UserGrammarTestQuestion";
  id: number;
  questionIndex: number;
  grammarTestQuestion: { __typename?: "GrammarTestQuestion"; id: number; prompt: string };
  option1Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
  option2Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
  option3Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
  option4Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
};

export type GrammarTestQueryVariables = Exact<{ [key: string]: never }>;

export type GrammarTestQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      onboardingStatusId: string;
      latestUserGrammarTest?: { __typename?: "UserGrammarTest"; id: number } | null;
      activeUserGrammarTest?: {
        __typename?: "UserGrammarTest";
        id: number;
        startedAt: Date;
        expiresAt: Date;
        activeQuestion?: {
          __typename?: "UserGrammarTestQuestion";
          id: number;
          questionIndex: number;
          grammarTestQuestion: { __typename?: "GrammarTestQuestion"; id: number; prompt: string };
          option1Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
          option2Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
          option3Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
          option4Answer?: { __typename?: "GrammarTestQuestionAnswer"; id: number; content: string } | null;
        } | null;
      } | null;
    } | null;
  };
};

export type JourneyQueryVariables = Exact<{ [key: string]: never }>;

export type JourneyQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: { __typename?: "CreativesScaleUser"; id: number; onboardingStatusId: string } | null;
  };
};

export type NonWriterQueryVariables = Exact<{ [key: string]: never }>;

export type NonWriterQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: { __typename?: "CreativesScaleUser"; id: number; optedInForUpdatesAt?: Date | null } | null;
  };
};

export type NonWriter_UpdateCreativesScaleUserMutationVariables = Exact<{
  input: UpdateCreativeScaleUserInput;
}>;

export type NonWriter_UpdateCreativesScaleUserMutation = {
  __typename?: "Mutation";
  updateCreativesScaleUser: {
    __typename?: "UpdateCreativeScaleUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CreateUserProfessionalMutationVariables = Exact<{
  input: CreateUserProfessionalInput;
}>;

export type CreateUserProfessionalMutation = {
  __typename?: "Mutation";
  createUserProfessional: {
    __typename?: "CreateUserProfessionalResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type Signup_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadSignupInput;
}>;

export type Signup_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUploadSignup: {
    __typename?: "GenerateUrlForFileUploadSignupResponse";
    fileId: string;
    url: string;
  };
};

export type CreativesScaleSignupMutationVariables = Exact<{
  input: SignupCsUserInput;
}>;

export type CreativesScaleSignupMutation = {
  __typename?: "Mutation";
  signupCSUser: {
    __typename?: "SignupCSUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    creativesScaleUser?: { __typename?: "CreativesScaleUser"; id: number; userId: number } | null;
  };
};

export type SignupViewerQueryVariables = Exact<{ [key: string]: never }>;

export type SignupViewerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    user?: {
      __typename?: "User";
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      isEmailConfirmed: boolean;
      isPasswordSet: boolean;
    } | null;
    creativesScaleUser?: { __typename?: "CreativesScaleUser"; id: number } | null;
  };
};

export type GenerateUrlForStripeOnboardingMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateUrlForStripeOnboardingMutation = {
  __typename?: "Mutation";
  generateUrlForStripeOnboarding: {
    __typename?: "GenerateUrlForStripeOnboardingResponse";
    ok: boolean;
    url?: string | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UnsupportedCountryQueryVariables = Exact<{ [key: string]: never }>;

export type UnsupportedCountryQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      optedInForUpdatesAt?: Date | null;
      countryOfResidence?: string | null;
    } | null;
  };
};

export type UnsupportedCountry_UpdateCsUserMutationVariables = Exact<{
  input: UpdateCreativeScaleUserInput;
}>;

export type UnsupportedCountry_UpdateCsUserMutation = {
  __typename?: "Mutation";
  updateCreativesScaleUser: {
    __typename?: "UpdateCreativeScaleUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type WelcomeQueryVariables = Exact<{ [key: string]: never }>;

export type WelcomeQuery = {
  __typename?: "Query";
  creativesScaleConfiguration: {
    __typename?: "CreativesScaleConfiguration";
    workInterests?: Array<{ __typename?: "WorkInterest"; id: string; name: string }> | null;
  };
};

export type SaveUserWorkInterestsMutationVariables = Exact<{
  input: SaveUserWorkInterestsInput;
}>;

export type SaveUserWorkInterestsMutation = {
  __typename?: "Mutation";
  saveUserWorkInterests: { __typename?: "SaveUserWorkInterestsResponse"; interests: Array<string> };
};

export type SelectWritingSampleTopicMutationVariables = Exact<{
  input: SelectWritingSampleTopicInput;
}>;

export type SelectWritingSampleTopicMutation = {
  __typename?: "Mutation";
  selectWritingSampleTopic: {
    __typename?: "SelectWritingSampleTopicResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type SaveWritingSampleMutationVariables = Exact<{
  input: SaveWritingSampleInput;
}>;

export type SaveWritingSampleMutation = {
  __typename?: "Mutation";
  saveWritingSample: {
    __typename?: "SaveWritingSampleResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    userWritingSample?: {
      __typename?: "UserWritingSample";
      id: number;
      validationCheckSummary?: {
        __typename?: "ValidationCheckSummary";
        isValid: boolean;
        checks: Array<{
          __typename?: "ValidationCheck";
          id: string;
          name: string;
          helpText: string;
          isValid: boolean;
          actualValue: string;
          successCriteria: string;
          failureCriteria: string;
          isRequiredToPass: boolean;
        }>;
      } | null;
    } | null;
  };
};

export type WritingSample_TopicFragment = {
  __typename?: "WritingSampleTopic";
  id: number;
  topic: string;
  primaryKeyword?: string | null;
  voice?: string | null;
  website?: string | null;
  additionalInformation?: string | null;
  category?: { __typename?: "Category"; id: number; name: string } | null;
};

export type WritingFragment = {
  __typename?: "UserWritingSample";
  id: number;
  endsAt: Date;
  writingSampleTopic?: {
    __typename?: "WritingSampleTopic";
    id: number;
    topic: string;
    primaryKeyword?: string | null;
    voice?: string | null;
    website?: string | null;
    additionalInformation?: string | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
  } | null;
  contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
  validationCheckSummary?: {
    __typename?: "ValidationCheckSummary";
    isValid: boolean;
    checks: Array<{
      __typename?: "ValidationCheck";
      id: string;
      name: string;
      helpText: string;
      isValid: boolean;
      actualValue: string;
      successCriteria: string;
      failureCriteria: string;
      isRequiredToPass: boolean;
    }>;
  } | null;
};

export type WritingSampleQueryVariables = Exact<{ [key: string]: never }>;

export type WritingSampleQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      activeWritingSample?: {
        __typename?: "UserWritingSample";
        id: number;
        endsAt: Date;
        writingSampleTopic?: {
          __typename?: "WritingSampleTopic";
          id: number;
          topic: string;
          primaryKeyword?: string | null;
          voice?: string | null;
          website?: string | null;
          additionalInformation?: string | null;
          category?: { __typename?: "Category"; id: number; name: string } | null;
        } | null;
        contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
        validationCheckSummary?: {
          __typename?: "ValidationCheckSummary";
          isValid: boolean;
          checks: Array<{
            __typename?: "ValidationCheck";
            id: string;
            name: string;
            helpText: string;
            isValid: boolean;
            actualValue: string;
            successCriteria: string;
            failureCriteria: string;
            isRequiredToPass: boolean;
          }>;
        } | null;
      } | null;
      writingSampleTopics: Array<{
        __typename?: "WritingSampleTopic";
        id: number;
        topic: string;
        primaryKeyword?: string | null;
        voice?: string | null;
        website?: string | null;
        additionalInformation?: string | null;
        category?: { __typename?: "Category"; id: number; name: string } | null;
      }>;
    } | null;
  };
};

export type ProfileTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  statusIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type ProfileTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      completedAt?: Date | null;
      topic?: string | null;
      reviewTargetTaskId?: number | null;
      taskType: { __typename?: "TaskType"; name: string };
      status: { __typename?: "TaskStatus"; id: string; name: string };
      category?: { __typename?: "Category"; id: number; name: string } | null;
      reviewTasks: {
        __typename?: "TaskConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "Task";
          completedAt?: Date | null;
          status: { __typename?: "TaskStatus"; id: string; name: string };
          reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
        }>;
      };
    }>;
  };
};

export type SaveProfileMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type SaveProfileMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "UpdateUserResponse"; user?: { __typename?: "User"; id: number } | null };
};

export type ResumeFileFragment = { __typename?: "File"; id: string; name: string; url: string };

export type ProfileUserFragment = {
  __typename?: "User";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  emailPreference?: string | null;
  userProfessional?: {
    __typename?: "UserProfessional";
    linkedInProfileUrl?: string | null;
    resumeFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
  } | null;
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    user?: {
      __typename?: "User";
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      timezone: string;
      emailPreference?: string | null;
      userProfessional?: {
        __typename?: "UserProfessional";
        linkedInProfileUrl?: string | null;
        resumeFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
      } | null;
    } | null;
  };
};

export type Profile_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadSignupInput;
}>;

export type Profile_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUploadSignup: {
    __typename?: "GenerateUrlForFileUploadSignupResponse";
    fileId: string;
    url: string;
  };
};

export type SaveUserProfessionalMutationVariables = Exact<{
  input: SaveUserProfessionalInput;
}>;

export type SaveUserProfessionalMutation = {
  __typename?: "Mutation";
  saveUserProfessional: {
    __typename?: "SaveUserProfessionalResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; message: string; fieldName: string }>;
    } | null;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: { __typename?: "ResetPasswordResponse"; wasReset: boolean };
};

export type GenerateUrlForStripeDashboardMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateUrlForStripeDashboardMutation = {
  __typename?: "Mutation";
  generateUrlForStripeDashboard: {
    __typename?: "GenerateUrlForStripeDashboardResponse";
    ok: boolean;
    url?: string | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type ContentViewQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentViewQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
  } | null;
};

export type TasksDetails_TaskFragment = {
  __typename?: "Task";
  id: number;
  assignedUserId?: number | null;
  status: { __typename?: "TaskStatus"; id: string };
  taskType: { __typename?: "TaskType"; id: string; name: string };
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    amount: number;
    customer: { __typename?: "Customer"; category?: { __typename?: "Category"; name: string } | null };
  };
};

export type TaskDetailsQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TaskDetailsQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    assignedUserId?: number | null;
    status: { __typename?: "TaskStatus"; id: string };
    taskType: { __typename?: "TaskType"; id: string; name: string };
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      amount: number;
      customer: { __typename?: "Customer"; category?: { __typename?: "Category"; name: string } | null };
    };
  } | null;
};

export type TaskDetails_UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type TaskDetails_UpdateTaskMutation = {
  __typename?: "Mutation";
  updateTask: {
    __typename?: "UpdateTaskResponse";
    task: {
      __typename?: "Task";
      id: number;
      assignedUserId?: number | null;
      status: { __typename?: "TaskStatus"; id: string };
      taskType: { __typename?: "TaskType"; id: string; name: string };
      order: {
        __typename?: "Order";
        id: number;
        topic?: string | null;
        amount: number;
        customer: { __typename?: "Customer"; category?: { __typename?: "Category"; name: string } | null };
      };
    };
  };
};

export type ContentIdeationTaskWorkspace_ContentIdeationFragment = {
  __typename?: "ContentIdeation";
  id: number;
  idea: string;
};

export type ContentIdeationTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  expiresAt?: Date | null;
  keyword?: string | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      businessDescription?: string | null;
      customerMarketSize?: string | null;
      targetArea?: string | null;
      targetAudience?: string | null;
      competitorWebsites?: string | null;
    } | null;
  };
  contentIdeation?: { __typename?: "ContentIdeation"; id: number; idea: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
};

export type ContentIdeationTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentIdeationTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    expiresAt?: Date | null;
    keyword?: string | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
        customerMarketSize?: string | null;
        targetArea?: string | null;
        targetAudience?: string | null;
        competitorWebsites?: string | null;
      } | null;
    };
    contentIdeation?: { __typename?: "ContentIdeation"; id: number; idea: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
  } | null;
};

export type SaveTaskContentIdeationMutationVariables = Exact<{
  input: SaveTaskContentIdeationInput;
}>;

export type SaveTaskContentIdeationMutation = {
  __typename?: "Mutation";
  saveTaskContentIdeation: {
    __typename?: "SaveTaskContentIdeationResponse";
    contentIdeation: { __typename?: "ContentIdeation"; id: number };
  };
};

export type ContentReviewTaskWorkspace_ContentItemFragment = {
  __typename?: "ContentItem";
  id: number;
  title: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  probability?: number | null;
  thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
  feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
};

export type ContentReviewTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  landingPageUrl?: string | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      businessDescription?: string | null;
      customerMarketSize?: string | null;
      targetArea?: string | null;
      targetAudience?: string | null;
      preferredVoice?: string | null;
      isAddImage?: boolean | null;
    } | null;
  };
  reviewTargetTask?: {
    __typename?: "Task";
    id: number;
    taskTypeId: string;
    contentItem?: {
      __typename?: "ContentItem";
      id: number;
      title: string;
      content: string;
      metaTitle: string;
      metaDescription: string;
      probability?: number | null;
      thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
      feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
    } | null;
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
    assignedUser?: { __typename?: "User"; id: number; fullName: string; email: string } | null;
  } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
};

export type ContentReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    landingPageUrl?: string | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
        customerMarketSize?: string | null;
        targetArea?: string | null;
        targetAudience?: string | null;
        preferredVoice?: string | null;
        isAddImage?: boolean | null;
      } | null;
    };
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      taskTypeId: string;
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        title: string;
        content: string;
        metaTitle: string;
        metaDescription: string;
        probability?: number | null;
        thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
        feedbackContentItems: Array<{
          __typename?: "FeedbackContentItem";
          id: number;
          rating: number;
          feedback: string;
        }>;
      } | null;
      reviewTasks: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          createdAt: Date;
          completedAt?: Date | null;
          taskTypeId: string;
          reviewComment?: string | null;
          reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
        }>;
      };
      assignedUser?: { __typename?: "User"; id: number; fullName: string; email: string } | null;
    } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
  } | null;
};

export type CreateFeedbackContentItemMutationVariables = Exact<{
  input: CreateFeedbackContentItemInput;
}>;

export type CreateFeedbackContentItemMutation = {
  __typename?: "Mutation";
  createFeedbackContentItem: {
    __typename?: "CreateFeedbackContentItemResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type ContentReview_SaveTaskContentItemMutationVariables = Exact<{
  input: SaveTaskContentItemInput;
}>;

export type ContentReview_SaveTaskContentItemMutation = {
  __typename?: "Mutation";
  saveTaskContentItem: {
    __typename?: "SaveTaskContentItemResponse";
    contentItem: { __typename?: "ContentItem"; id: number };
  };
};

export type UpdateFeedbackContentItemMutationVariables = Exact<{
  input: UpdateFeedbackContentItemInput;
}>;

export type UpdateFeedbackContentItemMutation = {
  __typename?: "Mutation";
  updateFeedbackContentItem: {
    __typename?: "UpdateFeedbackContentItemResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type ContentReviewValidationCheckSummaryQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentReviewValidationCheckSummaryQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      validationCheckSummary?: {
        __typename?: "ValidationCheckSummary";
        isValid: boolean;
        checks: Array<{
          __typename?: "ValidationCheck";
          id: string;
          name: string;
          helpText: string;
          isValid: boolean;
          actualValue: string;
          successCriteria: string;
          failureCriteria: string;
          isRequiredToPass: boolean;
        }>;
      } | null;
    } | null;
  } | null;
};

export type ContentStrategyV2_OrderStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type ContentStrategyV2_OrderStatusesQuery = {
  __typename?: "Query";
  orderStatuses: Array<{ __typename?: "OrderStatus"; id: string; name: string }>;
};

export type ContentStrategyV2_OrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  search?: InputMaybe<Scalars["String"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ContentStrategyV2_OrdersQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      topic?: string | null;
      startDate: Date;
      instructions?: string | null;
      billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
      status: { __typename?: "OrderStatus"; id: string; name: string };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        partner: { __typename?: "Partner"; id: number; name: string };
      };
      user: { __typename?: "User"; id: number; firstName: string; lastName: string };
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
      orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    }>;
  };
};

export type ContentStrategyV2TaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentStrategyV2TaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    taskType: { __typename?: "TaskType"; id: string; name: string };
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
    order: { __typename?: "Order"; id: number };
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
        customerMarketSize?: string | null;
        targetAudience?: string | null;
        competitorWebsites?: string | null;
      } | null;
    };
    customerProposal?: {
      __typename?: "CustomerProposal";
      id: number;
      summary?: string | null;
      orderContentStrategies: Array<{
        __typename?: "OrderContentStrategy";
        id: number;
        topic?: string | null;
        webPage?: string | null;
        pageType?: string | null;
        purposeOfContent?: string | null;
        linkTo?: string | null;
        voice?: string | null;
        focusPoints?: string | null;
        sampleContent?: string | null;
        clientProvidedInstructions?: string | null;
        customerKeywordId?: number | null;
        isComplete: boolean;
        orderContentStrategyReviews: Array<{
          __typename?: "OrderContentStrategyReview";
          id: number;
          createdAt: Date;
          resolvedAt?: Date | null;
          comments?: string | null;
          createdByUser: { __typename?: "User"; id: number; fullName: string };
        }>;
        orderHaloKeywords: Array<{
          __typename?: "OrderHaloKeyword";
          id: number;
          isProposed: boolean;
          customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
        }>;
        order?: {
          __typename?: "Order";
          id: number;
          startDate: Date;
          customerId: number;
          instructions?: string | null;
          partnerProduct: {
            __typename?: "PartnerProduct";
            id: number;
            product: {
              __typename?: "Product";
              id: string;
              name: string;
              minWordCount?: number | null;
              maxWordCount?: number | null;
            };
          };
          customer: {
            __typename?: "Customer";
            customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; contentPreference?: string | null } | null;
          };
        } | null;
      }>;
    } | null;
  } | null;
};

export type ContentStrategyV2_OrderContentStrategyFragment = {
  __typename?: "OrderContentStrategy";
  id: number;
  topic?: string | null;
  webPage?: string | null;
  pageType?: string | null;
  purposeOfContent?: string | null;
  linkTo?: string | null;
  voice?: string | null;
  focusPoints?: string | null;
  sampleContent?: string | null;
  clientProvidedInstructions?: string | null;
  customerKeywordId?: number | null;
  isComplete: boolean;
  orderContentStrategyReviews: Array<{
    __typename?: "OrderContentStrategyReview";
    id: number;
    createdAt: Date;
    resolvedAt?: Date | null;
    comments?: string | null;
    createdByUser: { __typename?: "User"; id: number; fullName: string };
  }>;
  orderHaloKeywords: Array<{
    __typename?: "OrderHaloKeyword";
    id: number;
    isProposed: boolean;
    customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
  }>;
  order?: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    customerId: number;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: {
        __typename?: "Product";
        id: string;
        name: string;
        minWordCount?: number | null;
        maxWordCount?: number | null;
      };
    };
    customer: {
      __typename?: "Customer";
      customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; contentPreference?: string | null } | null;
    };
  } | null;
};

export type ResolveOrderContentStrategyReviewMutationVariables = Exact<{
  input: ResolveOrderContentStrategyReviewInput;
}>;

export type ResolveOrderContentStrategyReviewMutation = {
  __typename?: "Mutation";
  resolveOrderContentStrategyReview: {
    __typename?: "ResolveOrderContentStrategyReviewResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    orderContentStrategyReview?: {
      __typename?: "OrderContentStrategyReview";
      id: number;
      resolvedAt?: Date | null;
    } | null;
  };
};

export type ContentStrategyV2_UpdateCustomerProposalMutationVariables = Exact<{
  input: UpdateCustomerProposalInput;
}>;

export type ContentStrategyV2_UpdateCustomerProposalMutation = {
  __typename?: "Mutation";
  updateCustomerProposal: {
    __typename?: "UpdateCustomerProposalResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerProposal?: { __typename?: "CustomerProposal"; id: number; summary?: string | null } | null;
  };
};

export type ContentStrategyV2_UpdateOrderContentStrategyMutationVariables = Exact<{
  input: UpdateOrderContentStrategyInput;
}>;

export type ContentStrategyV2_UpdateOrderContentStrategyMutation = {
  __typename?: "Mutation";
  updateOrderContentStrategy: {
    __typename?: "UpdateOrderContentStrategyResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    orderContentStrategy?: {
      __typename?: "OrderContentStrategy";
      id: number;
      topic?: string | null;
      webPage?: string | null;
      pageType?: string | null;
      purposeOfContent?: string | null;
      linkTo?: string | null;
      voice?: string | null;
      focusPoints?: string | null;
      sampleContent?: string | null;
      clientProvidedInstructions?: string | null;
      customerKeywordId?: number | null;
      isComplete: boolean;
      orderContentStrategyReviews: Array<{
        __typename?: "OrderContentStrategyReview";
        id: number;
        createdAt: Date;
        resolvedAt?: Date | null;
        comments?: string | null;
        createdByUser: { __typename?: "User"; id: number; fullName: string };
      }>;
      orderHaloKeywords: Array<{
        __typename?: "OrderHaloKeyword";
        id: number;
        isProposed: boolean;
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
      }>;
      order?: {
        __typename?: "Order";
        id: number;
        startDate: Date;
        customerId: number;
        instructions?: string | null;
        partnerProduct: {
          __typename?: "PartnerProduct";
          id: number;
          product: {
            __typename?: "Product";
            id: string;
            name: string;
            minWordCount?: number | null;
            maxWordCount?: number | null;
          };
        };
        customer: {
          __typename?: "Customer";
          customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; contentPreference?: string | null } | null;
        };
      } | null;
    } | null;
  };
};

export type ContentWorkspace_ContentItemFragment = {
  __typename?: "ContentItem";
  id: number;
  title: string;
  content: string;
};

export type ContentWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  expiresAt?: Date | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      businessDescription?: string | null;
      customerMarketSize?: string | null;
      targetArea?: string | null;
      targetAudience?: string | null;
      competitorWebsites?: string | null;
    } | null;
  };
  contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
  category?: { __typename?: "Category"; id: number; name: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: {
        __typename?: "Product";
        id: string;
        name: string;
        minWordCount?: number | null;
        maxWordCount?: number | null;
      };
    };
    activeOrderContentStrategy?: {
      __typename?: "OrderContentStrategy";
      sampleContent?: string | null;
      webPage?: string | null;
      pageType?: string | null;
      focusPoints?: string | null;
      linkTo?: string | null;
      voice?: string | null;
      purposeOfContent?: string | null;
      customerKeyword?: {
        __typename?: "CustomerKeyword";
        id: number;
        name: string;
        landingPageUrl?: string | null;
      } | null;
      orderHaloKeywords: Array<{
        __typename?: "OrderHaloKeyword";
        id: number;
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
      }>;
    } | null;
  };
  reviewTasks: {
    __typename?: "TaskConnection";
    nodes: Array<{
      __typename?: "Task";
      id: number;
      createdAt: Date;
      completedAt?: Date | null;
      taskTypeId: string;
      reviewComment?: string | null;
      reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
    }>;
  };
};

export type ContentWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    expiresAt?: Date | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
        customerMarketSize?: string | null;
        targetArea?: string | null;
        targetAudience?: string | null;
        competitorWebsites?: string | null;
      } | null;
    };
    contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: {
          __typename?: "Product";
          id: string;
          name: string;
          minWordCount?: number | null;
          maxWordCount?: number | null;
        };
      };
      activeOrderContentStrategy?: {
        __typename?: "OrderContentStrategy";
        sampleContent?: string | null;
        webPage?: string | null;
        pageType?: string | null;
        focusPoints?: string | null;
        linkTo?: string | null;
        voice?: string | null;
        purposeOfContent?: string | null;
        customerKeyword?: {
          __typename?: "CustomerKeyword";
          id: number;
          name: string;
          landingPageUrl?: string | null;
        } | null;
        orderHaloKeywords: Array<{
          __typename?: "OrderHaloKeyword";
          id: number;
          customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
        }>;
      } | null;
    };
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
  } | null;
};

export type ContentWorkspace_SaveTaskContentItemMutationVariables = Exact<{
  input: SaveTaskContentItemInput;
}>;

export type ContentWorkspace_SaveTaskContentItemMutation = {
  __typename?: "Mutation";
  saveTaskContentItem: {
    __typename?: "SaveTaskContentItemResponse";
    contentItem: { __typename?: "ContentItem"; id: number };
  };
};

export type ContentValidationCheckSummaryQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ContentValidationCheckSummaryQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    validationCheckSummary?: {
      __typename?: "ValidationCheckSummary";
      isValid: boolean;
      checks: Array<{
        __typename?: "ValidationCheck";
        id: string;
        name: string;
        helpText: string;
        isValid: boolean;
        actualValue: string;
        successCriteria: string;
        failureCriteria: string;
        isRequiredToPass: boolean;
      }>;
    } | null;
  } | null;
};

export type GbpWorkspace_GbpItemFragment = {
  __typename?: "GbpItem";
  id: number;
  content: string;
  readMoreUrl?: string | null;
  thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
};

export type GbpWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  expiresAt?: Date | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    gbpUrl?: string | null;
    partner: { __typename?: "Partner"; id: number; name: string };
    customerKeywords: {
      __typename?: "CustomerKeywordConnection";
      nodes: Array<{ __typename?: "CustomerKeyword"; id: number; name: string }>;
    };
    customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; serviceArea?: string | null } | null;
  };
  gbpItem?: {
    __typename?: "GbpItem";
    id: number;
    content: string;
    readMoreUrl?: string | null;
    thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
  } | null;
  category?: { __typename?: "Category"; id: number; name: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
};

export type GbpWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GbpWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    expiresAt?: Date | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      gbpUrl?: string | null;
      partner: { __typename?: "Partner"; id: number; name: string };
      customerKeywords: {
        __typename?: "CustomerKeywordConnection";
        nodes: Array<{ __typename?: "CustomerKeyword"; id: number; name: string }>;
      };
      customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; serviceArea?: string | null } | null;
    };
    gbpItem?: {
      __typename?: "GbpItem";
      id: number;
      content: string;
      readMoreUrl?: string | null;
      thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    } | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
  } | null;
};

export type GbpWorkspace_SaveTaskGbpItemMutationVariables = Exact<{
  input: SaveTaskGbpItemInput;
}>;

export type GbpWorkspace_SaveTaskGbpItemMutation = {
  __typename?: "Mutation";
  saveTaskGbpItem: {
    __typename?: "SaveTaskGbpItemResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type SaveTaskTitleMetaDescriptionMutationVariables = Exact<{
  input: SaveTaskTitleMetaDescriptionInput;
}>;

export type SaveTaskTitleMetaDescriptionMutation = {
  __typename?: "Mutation";
  saveTaskTitleMetaDescriptions: {
    __typename?: "SaveTaskTitleMetaDescriptionResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; message: string; fieldName: string }>;
    } | null;
  };
};

export type TitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItemFragment = {
  __typename?: "TitleMetaDescriptionItem";
  id: number;
  titleMetaDescriptions: Array<{
    __typename?: "TitleMetaDescription";
    id: number;
    titleTag: string;
    metaDescription: string;
    url: string;
  }>;
};

export type TitleMetaDescriptionTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  expiresAt?: Date | null;
  customer: { __typename?: "Customer"; id: number; name: string; website: string };
  order: { __typename?: "Order"; id: number; instructions?: string | null };
  titleMetaDescriptionItem?: {
    __typename?: "TitleMetaDescriptionItem";
    id: number;
    titleMetaDescriptions: Array<{
      __typename?: "TitleMetaDescription";
      id: number;
      titleTag: string;
      metaDescription: string;
      url: string;
    }>;
  } | null;
};

export type TitleMetaDescriptionTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type TitleMetaDescriptionTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    expiresAt?: Date | null;
    customer: { __typename?: "Customer"; id: number; name: string; website: string };
    order: { __typename?: "Order"; id: number; instructions?: string | null };
    titleMetaDescriptionItem?: {
      __typename?: "TitleMetaDescriptionItem";
      id: number;
      titleMetaDescriptions: Array<{
        __typename?: "TitleMetaDescription";
        id: number;
        titleTag: string;
        metaDescription: string;
        url: string;
      }>;
    } | null;
  } | null;
};

export type PaymentIdentityReminderQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentIdentityReminderQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    creativesScaleUser?: {
      __typename?: "CreativesScaleUser";
      id: number;
      isStripeOnboardingReminderEnabled: boolean;
    } | null;
  };
};

export type UnsubscribePaymentIdentityReminder_UpdateCsUserMutationVariables = Exact<{
  input: UpdateCreativeScaleUserInput;
}>;

export type UnsubscribePaymentIdentityReminder_UpdateCsUserMutation = {
  __typename?: "Mutation";
  updateCreativesScaleUser: {
    __typename?: "UpdateCreativeScaleUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export const CustomerKeywordSelector_KeywordFragmentDoc = gql`
  fragment CustomerKeywordSelector_Keyword on CustomerKeyword {
    id
    displayName
    isActive
    isPrimary
  }
`;
export const TaskReport_UserTaskReportFragmentDoc = gql`
  fragment TaskReport_UserTaskReport on TaskReport {
    dayNumber
    completedAt
    taskCount {
      count
      rejectedCount
      taskType {
        id
        name
      }
    }
  }
`;
export const AuthManager_ViewerUserFragmentDoc = gql`
  fragment AuthManager_ViewerUser on User {
    id
    fullName
    userTaskPermissions {
      id
      productId
      taskTypeId
    }
  }
`;
export const AuthManager_CreativesScaleUserFragmentDoc = gql`
  fragment AuthManager_CreativesScaleUser on CreativesScaleUser {
    id
    onboardingStatusId
    isAdmin
    countryOfResidenceCountries {
      id
      name
    }
    unpaidBalance
    allTimeEarnedAmount
    workInterests {
      id
    }
    isRCMWriter
  }
`;
export const AuthManager_ViewerFragmentDoc = gql`
  fragment AuthManager_Viewer on Viewer {
    user {
      ...AuthManager_ViewerUser
    }
    creativesScaleUser {
      ...AuthManager_CreativesScaleUser
    }
  }
  ${AuthManager_ViewerUserFragmentDoc}
  ${AuthManager_CreativesScaleUserFragmentDoc}
`;
export const AdminGrammarTestQuestionFragmentDoc = gql`
  fragment AdminGrammarTestQuestion on GrammarTestQuestion {
    id
    prompt
    isActive
    answers {
      id
      content
      isCorrect
    }
    totalAttempts
    totalCorrectAttempts
  }
`;
export const AdminQaView_TaskFragmentDoc = gql`
  fragment AdminQaView_Task on Task {
    id
    creativesScaleTaskStatus {
      id
      name
    }
    availableAt
    createdAt
    completedAt
    assignedAt
    assignedUser {
      id
      fullName
    }
    taskType {
      id
      name
    }
    keyword
    customer {
      id
      name
    }
    order {
      id
      topic
    }
    contentItem {
      id
      feedbackContentItems {
        id
        rating
        feedback
      }
    }
    reviewTasksByQa: reviewTasks(isFulfilledByCustomer: false, sort: $sort) {
      nodes {
        id
        assignedUser {
          id
          fullName
        }
        completedAt
      }
    }
    reviewTasksQaCount: reviewTasks(isFulfilledByCustomer: false, reviewStatusId: "rejected") {
      totalCount
    }
    reviewTasksByCustomer: reviewTasks(isFulfilledByCustomer: true, reviewStatusId: "rejected") {
      totalCount
    }
    product {
      id
      name
    }
  }
`;
export const AdminTasks_TaskFragmentDoc = gql`
  fragment AdminTasks_Task on Task {
    id
    creativesScaleTaskStatus {
      id
      name
    }
    availableAt
    createdAt
    completedAt
    assignedAt
    assignedUser {
      id
      fullName
    }
    taskType {
      id
      name
    }
    keyword
    customer {
      id
      name
    }
    order {
      id
    }
    product {
      id
      name
    }
  }
`;
export const TeamMemberProfile_ProductTaskTypeFragmentDoc = gql`
  fragment TeamMemberProfile_ProductTaskType on ProductTaskType {
    id
    product {
      id
      name
    }
    taskType {
      id
      name
    }
  }
`;
export const TeamMemberProfile_UserTaskPermissionFragmentDoc = gql`
  fragment TeamMemberProfile_UserTaskPermission on UserTaskPermission {
    id
    product {
      id
      name
    }
    taskType {
      id
      name
    }
  }
`;
export const TaskHistoryFragmentDoc = gql`
  fragment TaskHistory on Task {
    id
    taskType {
      id
      name
    }
    category {
      id
      name
    }
    topic
    creativesScaleTaskStatus {
      id
      name
    }
    contentItem {
      id
      feedbackContentItems {
        id
        rating
        feedback
      }
      probability
    }
    order {
      id
    }
    completedAt
    paymentAmount
    paymentStatus {
      id
      name
    }
    reviewTasksQa: reviewTasks(reviewStatusId: "rejected", isFulfilledByCustomer: false) {
      totalCount
    }
    reviewTasksCustomer: reviewTasks(reviewStatusId: "rejected", isFulfilledByCustomer: true) {
      totalCount
    }
  }
`;
export const TeamMemberListFragmentDoc = gql`
  fragment TeamMemberList on CreativesScaleUser {
    id
    user {
      id
      fullName
      email
    }
    onboardingStatus {
      id
      name
    }
    createdAt
    isAdmin
    isEligibleForPayments
  }
`;
export const AdminWritingSampleTopicFragmentDoc = gql`
  fragment AdminWritingSampleTopic on WritingSampleTopic {
    id
    createdAt
    topic
    isActive
    primaryKeyword
    voice
    website
    additionalInformation
    category {
      id
      name
    }
  }
`;
export const FindTasksFilter_ProductTaskTypeFragmentDoc = gql`
  fragment FindTasksFilter_ProductTaskType on ProductTaskType {
    id
    product {
      id
      name
    }
    taskType {
      id
      name
    }
  }
`;
export const MyTaskFragmentDoc = gql`
  fragment MyTask on Task {
    id
    taskType {
      id
      name
    }
    creativesScaleTaskStatus {
      id
      name
    }
    status {
      id
      name
    }
    category {
      id
      name
    }
    order {
      id
      topic
    }
    product {
      id
      name
    }
    contentItem {
      id
      feedbackContentItems {
        id
        rating
        feedback
      }
      probability
    }
    completedAt
  }
`;
export const GrammarTest_OptionFragmentDoc = gql`
  fragment GrammarTest_Option on GrammarTestQuestionAnswer {
    id
    content
  }
`;
export const GrammarTest_ActiveQuestionFragmentDoc = gql`
  fragment GrammarTest_ActiveQuestion on UserGrammarTestQuestion {
    id
    questionIndex
    grammarTestQuestion {
      id
      prompt
    }
    option1Answer {
      ...GrammarTest_Option
    }
    option2Answer {
      ...GrammarTest_Option
    }
    option3Answer {
      ...GrammarTest_Option
    }
    option4Answer {
      ...GrammarTest_Option
    }
  }
  ${GrammarTest_OptionFragmentDoc}
`;
export const WritingSample_TopicFragmentDoc = gql`
  fragment WritingSample_Topic on WritingSampleTopic {
    id
    topic
    primaryKeyword
    voice
    website
    additionalInformation
    category {
      id
      name
    }
  }
`;
export const ValidationChecklistPanel_SummaryFragmentDoc = gql`
  fragment ValidationChecklistPanel_Summary on ValidationCheckSummary {
    isValid
    checks {
      id
      name
      helpText
      isValid
      actualValue
      successCriteria
      failureCriteria
      isRequiredToPass
    }
  }
`;
export const WritingFragmentDoc = gql`
  fragment Writing on UserWritingSample {
    id
    endsAt
    writingSampleTopic {
      ...WritingSample_Topic
    }
    contentItem {
      id
      title
      content
    }
    validationCheckSummary {
      ...ValidationChecklistPanel_Summary
    }
  }
  ${WritingSample_TopicFragmentDoc}
  ${ValidationChecklistPanel_SummaryFragmentDoc}
`;
export const ResumeFileFragmentDoc = gql`
  fragment ResumeFile on File {
    id
    name
    url
  }
`;
export const ProfileUserFragmentDoc = gql`
  fragment ProfileUser on User {
    id
    firstName
    lastName
    email
    timezone
    emailPreference
    userProfessional {
      linkedInProfileUrl
      resumeFile {
        ...ResumeFile
      }
    }
  }
  ${ResumeFileFragmentDoc}
`;
export const TasksDetails_TaskFragmentDoc = gql`
  fragment TasksDetails_Task on Task {
    id
    assignedUserId
    status {
      id
    }
    taskType {
      id
      name
    }
    order {
      id
      topic
      amount
      customer {
        category {
          name
        }
      }
    }
  }
`;
export const ContentIdeationTaskWorkspace_ContentIdeationFragmentDoc = gql`
  fragment ContentIdeationTaskWorkspace_ContentIdeation on ContentIdeation {
    id
    idea
  }
`;
export const ContentIdeationTaskWorkspace_TaskFragmentDoc = gql`
  fragment ContentIdeationTaskWorkspace_Task on Task {
    id
    expiresAt
    customer {
      id
      name
      website
      instructions
      customerQuestionnaire {
        id
        businessDescription
        customerMarketSize
        targetArea
        targetAudience
        competitorWebsites
      }
    }
    keyword
    contentIdeation {
      ...ContentIdeationTaskWorkspace_ContentIdeation
    }
    order {
      id
      topic
      instructions
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
  }
  ${ContentIdeationTaskWorkspace_ContentIdeationFragmentDoc}
`;
export const ContentReviewTaskWorkspace_ContentItemFragmentDoc = gql`
  fragment ContentReviewTaskWorkspace_ContentItem on ContentItem {
    id
    title
    content
    metaTitle
    metaDescription
    probability
    thumbnailFile {
      id
      name
      url
    }
    feedbackContentItems {
      id
      rating
      feedback
    }
  }
`;
export const Comment_ReviewTaskFragmentDoc = gql`
  fragment Comment_ReviewTask on Task {
    id
    createdAt
    completedAt
    reviewStatus {
      id
      name
    }
    taskTypeId
    reviewComment
  }
`;
export const ContentReviewTaskWorkspace_TaskFragmentDoc = gql`
  fragment ContentReviewTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      website
      instructions
      customerQuestionnaire {
        id
        businessDescription
        customerMarketSize
        targetArea
        targetAudience
        preferredVoice
        isAddImage
      }
    }
    keyword
    landingPageUrl
    reviewTargetTask {
      id
      taskTypeId
      contentItem {
        ...ContentReviewTaskWorkspace_ContentItem
      }
      reviewTasks {
        nodes {
          ...Comment_ReviewTask
        }
      }
      assignedUser {
        id
        fullName
        email
      }
    }
    order {
      id
      topic
      instructions
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
  }
  ${ContentReviewTaskWorkspace_ContentItemFragmentDoc}
  ${Comment_ReviewTaskFragmentDoc}
`;
export const ContentStrategyV2_OrderContentStrategyFragmentDoc = gql`
  fragment ContentStrategyV2_OrderContentStrategy on OrderContentStrategy {
    id
    topic
    webPage
    pageType
    purposeOfContent
    linkTo
    voice
    focusPoints
    sampleContent
    clientProvidedInstructions
    customerKeywordId
    isComplete
    orderContentStrategyReviews {
      id
      createdAt
      resolvedAt
      comments
      createdByUser {
        id
        fullName
      }
    }
    orderHaloKeywords {
      id
      isProposed
      customerKeyword {
        id
        name
      }
    }
    order {
      id
      startDate
      customerId
      instructions
      partnerProduct {
        id
        product {
          id
          name
          minWordCount
          maxWordCount
        }
      }
      customer {
        customerQuestionnaire {
          contentPreference
        }
      }
    }
  }
`;
export const ContentWorkspace_ContentItemFragmentDoc = gql`
  fragment ContentWorkspace_ContentItem on ContentItem {
    id
    title
    content
  }
`;
export const ContentWorkspace_TaskFragmentDoc = gql`
  fragment ContentWorkspace_Task on Task {
    id
    customer {
      id
      name
      website
      instructions
      customerQuestionnaire {
        id
        businessDescription
        customerMarketSize
        targetArea
        targetAudience
        competitorWebsites
      }
    }
    contentItem {
      ...ContentWorkspace_ContentItem
    }
    category {
      id
      name
    }
    order {
      id
      topic
      instructions
      partnerProduct {
        id
        product {
          id
          name
          minWordCount
          maxWordCount
        }
      }
      activeOrderContentStrategy {
        sampleContent
        webPage
        pageType
        focusPoints
        linkTo
        voice
        purposeOfContent
        customerKeyword {
          id
          name
          landingPageUrl
        }
        orderHaloKeywords {
          id
          customerKeyword {
            id
            name
          }
        }
      }
    }
    reviewTasks(isFulfilledByCustomer: false) {
      nodes {
        ...Comment_ReviewTask
      }
    }
    expiresAt
  }
  ${ContentWorkspace_ContentItemFragmentDoc}
  ${Comment_ReviewTaskFragmentDoc}
`;
export const GbpWorkspace_GbpItemFragmentDoc = gql`
  fragment GbpWorkspace_GbpItem on GbpItem {
    id
    content
    readMoreUrl
    thumbnailFile {
      id
      name
      url
    }
  }
`;
export const GbpWorkspace_TaskFragmentDoc = gql`
  fragment GbpWorkspace_Task on Task {
    id
    customer {
      id
      name
      partner {
        id
        name
      }
      website
      gbpUrl
      customerKeywords(isPrimary: true) {
        nodes {
          id
          name
        }
      }
      customerQuestionnaire {
        id
        serviceArea
      }
    }
    gbpItem {
      ...GbpWorkspace_GbpItem
    }
    category {
      id
      name
    }
    order {
      id
      instructions
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
    expiresAt
  }
  ${GbpWorkspace_GbpItemFragmentDoc}
`;
export const TitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItemFragmentDoc = gql`
  fragment TitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItem on TitleMetaDescriptionItem {
    id
    titleMetaDescriptions {
      id
      titleTag
      metaDescription
      url
    }
  }
`;
export const TitleMetaDescriptionTaskWorkspace_TaskFragmentDoc = gql`
  fragment TitleMetaDescriptionTaskWorkspace_Task on Task {
    id
    expiresAt
    customer {
      id
      name
      website
    }
    order {
      id
      instructions
    }
    titleMetaDescriptionItem {
      ...TitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItem
    }
  }
  ${TitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItemFragmentDoc}
`;
export const CategoriesForSelectorDocument = gql`
  query CategoriesForSelector($search: String, $limit: Int, $offset: Int, $categoryIds: [Int!]) {
    categories(search: $search, limit: $limit, offset: $offset, categoryIds: $categoryIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCategoriesForSelectorQuery__
 *
 * To run a query within a React component, call `useCategoriesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useCategoriesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export function useCategoriesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export type CategoriesForSelectorQueryHookResult = ReturnType<typeof useCategoriesForSelectorQuery>;
export type CategoriesForSelectorLazyQueryHookResult = ReturnType<typeof useCategoriesForSelectorLazyQuery>;
export type CategoriesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CategoriesForSelectorQuery,
  CategoriesForSelectorQueryVariables
>;
export const CustomerKeywordSelectorDocument = gql`
  query CustomerKeywordSelector(
    $customerId: Int!
    $isPrimary: Boolean
    $includeInactive: Boolean
    $limit: Int
    $offset: Int
  ) {
    customer(id: $customerId) {
      id
      customerKeywords(limit: $limit, offset: $offset, isPrimary: $isPrimary, includeInactive: $includeInactive) {
        totalCount
        nodes {
          ...CustomerKeywordSelector_Keyword
        }
      }
    }
  }
  ${CustomerKeywordSelector_KeywordFragmentDoc}
`;

/**
 * __useCustomerKeywordSelectorQuery__
 *
 * To run a query within a React component, call `useCustomerKeywordSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerKeywordSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerKeywordSelectorQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      isPrimary: // value for 'isPrimary'
 *      includeInactive: // value for 'includeInactive'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCustomerKeywordSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerKeywordSelectorQuery, CustomerKeywordSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerKeywordSelectorQuery, CustomerKeywordSelectorQueryVariables>(
    CustomerKeywordSelectorDocument,
    options
  );
}
export function useCustomerKeywordSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerKeywordSelectorQuery,
    CustomerKeywordSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerKeywordSelectorQuery, CustomerKeywordSelectorQueryVariables>(
    CustomerKeywordSelectorDocument,
    options
  );
}
export type CustomerKeywordSelectorQueryHookResult = ReturnType<typeof useCustomerKeywordSelectorQuery>;
export type CustomerKeywordSelectorLazyQueryHookResult = ReturnType<typeof useCustomerKeywordSelectorLazyQuery>;
export type CustomerKeywordSelectorQueryResult = ApolloReactCommon.QueryResult<
  CustomerKeywordSelectorQuery,
  CustomerKeywordSelectorQueryVariables
>;
export const CustomersForSelectorDocument = gql`
  query CustomersForSelector($search: String, $partnerIds: [Int!]) {
    customers(search: $search, partnerIds: $partnerIds, sort: NAME_ASC) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCustomersForSelectorQuery__
 *
 * To run a query within a React component, call `useCustomersForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      partnerIds: // value for 'partnerIds'
 *   },
 * });
 */
export function useCustomersForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>(
    CustomersForSelectorDocument,
    options
  );
}
export function useCustomersForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>(
    CustomersForSelectorDocument,
    options
  );
}
export type CustomersForSelectorQueryHookResult = ReturnType<typeof useCustomersForSelectorQuery>;
export type CustomersForSelectorLazyQueryHookResult = ReturnType<typeof useCustomersForSelectorLazyQuery>;
export type CustomersForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CustomersForSelectorQuery,
  CustomersForSelectorQueryVariables
>;
export const JourneyStepGrammarAssessmentDocument = gql`
  query JourneyStepGrammarAssessment {
    viewer {
      creativesScaleUser {
        id
        retryGrammarTestAt
        activeUserGrammarTest {
          id
          startedAt
          expiresAt
        }
      }
    }
  }
`;

/**
 * __useJourneyStepGrammarAssessmentQuery__
 *
 * To run a query within a React component, call `useJourneyStepGrammarAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyStepGrammarAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyStepGrammarAssessmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useJourneyStepGrammarAssessmentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    JourneyStepGrammarAssessmentQuery,
    JourneyStepGrammarAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<JourneyStepGrammarAssessmentQuery, JourneyStepGrammarAssessmentQueryVariables>(
    JourneyStepGrammarAssessmentDocument,
    options
  );
}
export function useJourneyStepGrammarAssessmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    JourneyStepGrammarAssessmentQuery,
    JourneyStepGrammarAssessmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<JourneyStepGrammarAssessmentQuery, JourneyStepGrammarAssessmentQueryVariables>(
    JourneyStepGrammarAssessmentDocument,
    options
  );
}
export type JourneyStepGrammarAssessmentQueryHookResult = ReturnType<typeof useJourneyStepGrammarAssessmentQuery>;
export type JourneyStepGrammarAssessmentLazyQueryHookResult = ReturnType<
  typeof useJourneyStepGrammarAssessmentLazyQuery
>;
export type JourneyStepGrammarAssessmentQueryResult = ApolloReactCommon.QueryResult<
  JourneyStepGrammarAssessmentQuery,
  JourneyStepGrammarAssessmentQueryVariables
>;
export const StartGrammarTestDocument = gql`
  mutation StartGrammarTest {
    startGrammarTest {
      ok
      error {
        message
      }
    }
  }
`;
export type StartGrammarTestMutationFn = ApolloReactCommon.MutationFunction<
  StartGrammarTestMutation,
  StartGrammarTestMutationVariables
>;

/**
 * __useStartGrammarTestMutation__
 *
 * To run a mutation, you first call `useStartGrammarTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartGrammarTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startGrammarTestMutation, { data, loading, error }] = useStartGrammarTestMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartGrammarTestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<StartGrammarTestMutation, StartGrammarTestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<StartGrammarTestMutation, StartGrammarTestMutationVariables>(
    StartGrammarTestDocument,
    options
  );
}
export type StartGrammarTestMutationHookResult = ReturnType<typeof useStartGrammarTestMutation>;
export type StartGrammarTestMutationResult = ApolloReactCommon.MutationResult<StartGrammarTestMutation>;
export type StartGrammarTestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartGrammarTestMutation,
  StartGrammarTestMutationVariables
>;
export const JourneyStepWritingSampleDocument = gql`
  query JourneyStepWritingSample {
    viewer {
      creativesScaleUser {
        id
        retryWritingSampleAt
        activeWritingSample {
          id
          endsAt
        }
      }
    }
  }
`;

/**
 * __useJourneyStepWritingSampleQuery__
 *
 * To run a query within a React component, call `useJourneyStepWritingSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyStepWritingSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyStepWritingSampleQuery({
 *   variables: {
 *   },
 * });
 */
export function useJourneyStepWritingSampleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<JourneyStepWritingSampleQuery, JourneyStepWritingSampleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<JourneyStepWritingSampleQuery, JourneyStepWritingSampleQueryVariables>(
    JourneyStepWritingSampleDocument,
    options
  );
}
export function useJourneyStepWritingSampleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    JourneyStepWritingSampleQuery,
    JourneyStepWritingSampleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<JourneyStepWritingSampleQuery, JourneyStepWritingSampleQueryVariables>(
    JourneyStepWritingSampleDocument,
    options
  );
}
export type JourneyStepWritingSampleQueryHookResult = ReturnType<typeof useJourneyStepWritingSampleQuery>;
export type JourneyStepWritingSampleLazyQueryHookResult = ReturnType<typeof useJourneyStepWritingSampleLazyQuery>;
export type JourneyStepWritingSampleQueryResult = ApolloReactCommon.QueryResult<
  JourneyStepWritingSampleQuery,
  JourneyStepWritingSampleQueryVariables
>;
export const StartWritingSampleDocument = gql`
  mutation StartWritingSample {
    startWritingSample {
      ok
      error {
        message
      }
    }
  }
`;
export type StartWritingSampleMutationFn = ApolloReactCommon.MutationFunction<
  StartWritingSampleMutation,
  StartWritingSampleMutationVariables
>;

/**
 * __useStartWritingSampleMutation__
 *
 * To run a mutation, you first call `useStartWritingSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartWritingSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startWritingSampleMutation, { data, loading, error }] = useStartWritingSampleMutation({
 *   variables: {
 *   },
 * });
 */
export function useStartWritingSampleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<StartWritingSampleMutation, StartWritingSampleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<StartWritingSampleMutation, StartWritingSampleMutationVariables>(
    StartWritingSampleDocument,
    options
  );
}
export type StartWritingSampleMutationHookResult = ReturnType<typeof useStartWritingSampleMutation>;
export type StartWritingSampleMutationResult = ApolloReactCommon.MutationResult<StartWritingSampleMutation>;
export type StartWritingSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  StartWritingSampleMutation,
  StartWritingSampleMutationVariables
>;
export const OnboardingRouteAuthDocument = gql`
  query OnboardingRouteAuth {
    viewer {
      creativesScaleUser {
        id
        onboardingStatusId
      }
    }
  }
`;

/**
 * __useOnboardingRouteAuthQuery__
 *
 * To run a query within a React component, call `useOnboardingRouteAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingRouteAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingRouteAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingRouteAuthQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OnboardingRouteAuthQuery, OnboardingRouteAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OnboardingRouteAuthQuery, OnboardingRouteAuthQueryVariables>(
    OnboardingRouteAuthDocument,
    options
  );
}
export function useOnboardingRouteAuthLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OnboardingRouteAuthQuery, OnboardingRouteAuthQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OnboardingRouteAuthQuery, OnboardingRouteAuthQueryVariables>(
    OnboardingRouteAuthDocument,
    options
  );
}
export type OnboardingRouteAuthQueryHookResult = ReturnType<typeof useOnboardingRouteAuthQuery>;
export type OnboardingRouteAuthLazyQueryHookResult = ReturnType<typeof useOnboardingRouteAuthLazyQuery>;
export type OnboardingRouteAuthQueryResult = ApolloReactCommon.QueryResult<
  OnboardingRouteAuthQuery,
  OnboardingRouteAuthQueryVariables
>;
export const RouteAuthorizationDocument = gql`
  query RouteAuthorization {
    viewer {
      creativesScaleUser {
        id
        onboardingStatusId
      }
    }
  }
`;

/**
 * __useRouteAuthorizationQuery__
 *
 * To run a query within a React component, call `useRouteAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRouteAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRouteAuthorizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useRouteAuthorizationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<RouteAuthorizationQuery, RouteAuthorizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RouteAuthorizationQuery, RouteAuthorizationQueryVariables>(
    RouteAuthorizationDocument,
    options
  );
}
export function useRouteAuthorizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RouteAuthorizationQuery, RouteAuthorizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RouteAuthorizationQuery, RouteAuthorizationQueryVariables>(
    RouteAuthorizationDocument,
    options
  );
}
export type RouteAuthorizationQueryHookResult = ReturnType<typeof useRouteAuthorizationQuery>;
export type RouteAuthorizationLazyQueryHookResult = ReturnType<typeof useRouteAuthorizationLazyQuery>;
export type RouteAuthorizationQueryResult = ApolloReactCommon.QueryResult<
  RouteAuthorizationQuery,
  RouteAuthorizationQueryVariables
>;
export const StripeActionBarDocument = gql`
  query StripeActionBar {
    viewer {
      creativesScaleUser {
        id
        isEligibleForPayments
        stripeAccountStatus {
          id
          name
          helpText
        }
      }
    }
  }
`;

/**
 * __useStripeActionBarQuery__
 *
 * To run a query within a React component, call `useStripeActionBarQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeActionBarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeActionBarQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeActionBarQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StripeActionBarQuery, StripeActionBarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<StripeActionBarQuery, StripeActionBarQueryVariables>(
    StripeActionBarDocument,
    options
  );
}
export function useStripeActionBarLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StripeActionBarQuery, StripeActionBarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<StripeActionBarQuery, StripeActionBarQueryVariables>(
    StripeActionBarDocument,
    options
  );
}
export type StripeActionBarQueryHookResult = ReturnType<typeof useStripeActionBarQuery>;
export type StripeActionBarLazyQueryHookResult = ReturnType<typeof useStripeActionBarLazyQuery>;
export type StripeActionBarQueryResult = ApolloReactCommon.QueryResult<
  StripeActionBarQuery,
  StripeActionBarQueryVariables
>;
export const TaskTypeSelectorDocument = gql`
  query TaskTypeSelector($ids: [String!]) {
    taskTypes(ids: $ids) {
      nodes {
        id
        name
        isFulfilledByCustomer
      }
    }
  }
`;

/**
 * __useTaskTypeSelectorQuery__
 *
 * To run a query within a React component, call `useTaskTypeSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTypeSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTypeSelectorQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTaskTypeSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>(
    TaskTypeSelectorDocument,
    options
  );
}
export function useTaskTypeSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>(
    TaskTypeSelectorDocument,
    options
  );
}
export type TaskTypeSelectorQueryHookResult = ReturnType<typeof useTaskTypeSelectorQuery>;
export type TaskTypeSelectorLazyQueryHookResult = ReturnType<typeof useTaskTypeSelectorLazyQuery>;
export type TaskTypeSelectorQueryResult = ApolloReactCommon.QueryResult<
  TaskTypeSelectorQuery,
  TaskTypeSelectorQueryVariables
>;
export const UploadFileDialog_GenerateUrlForFileUploadDocument = gql`
  mutation UploadFileDialog_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadInput!) {
    generateUrlForFileUpload(input: $input) {
      url
      fileId
      url
    }
  }
`;
export type UploadFileDialog_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  UploadFileDialog_GenerateUrlForFileUploadMutation,
  UploadFileDialog_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __useUploadFileDialog_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `useUploadFileDialog_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileDialog_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileDialogGenerateUrlForFileUploadMutation, { data, loading, error }] = useUploadFileDialog_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileDialog_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadFileDialog_GenerateUrlForFileUploadMutation,
    UploadFileDialog_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UploadFileDialog_GenerateUrlForFileUploadMutation,
    UploadFileDialog_GenerateUrlForFileUploadMutationVariables
  >(UploadFileDialog_GenerateUrlForFileUploadDocument, options);
}
export type UploadFileDialog_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof useUploadFileDialog_GenerateUrlForFileUploadMutation
>;
export type UploadFileDialog_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<UploadFileDialog_GenerateUrlForFileUploadMutation>;
export type UploadFileDialog_GenerateUrlForFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileDialog_GenerateUrlForFileUploadMutation,
  UploadFileDialog_GenerateUrlForFileUploadMutationVariables
>;
export const UserSelectorV2Document = gql`
  query UserSelectorV2 {
    creativesScaleUsers {
      nodes {
        id
        user {
          id
          fullName
        }
      }
    }
  }
`;

/**
 * __useUserSelectorV2Query__
 *
 * To run a query within a React component, call `useUserSelectorV2Query` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectorV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectorV2Query({
 *   variables: {
 *   },
 * });
 */
export function useUserSelectorV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserSelectorV2Query, UserSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserSelectorV2Query, UserSelectorV2QueryVariables>(UserSelectorV2Document, options);
}
export function useUserSelectorV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSelectorV2Query, UserSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserSelectorV2Query, UserSelectorV2QueryVariables>(
    UserSelectorV2Document,
    options
  );
}
export type UserSelectorV2QueryHookResult = ReturnType<typeof useUserSelectorV2Query>;
export type UserSelectorV2LazyQueryHookResult = ReturnType<typeof useUserSelectorV2LazyQuery>;
export type UserSelectorV2QueryResult = ApolloReactCommon.QueryResult<
  UserSelectorV2Query,
  UserSelectorV2QueryVariables
>;
export const UserTaskReportDocument = gql`
  query UserTaskReport($userId: Int!, $taskTypeIds: [String!]) {
    userTaskReport(userId: $userId, taskTypeIds: $taskTypeIds) {
      user {
        id
        fullName
      }
      taskReports {
        ...TaskReport_UserTaskReport
      }
    }
  }
  ${TaskReport_UserTaskReportFragmentDoc}
`;

/**
 * __useUserTaskReportQuery__
 *
 * To run a query within a React component, call `useUserTaskReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTaskReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTaskReportQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      taskTypeIds: // value for 'taskTypeIds'
 *   },
 * });
 */
export function useUserTaskReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserTaskReportQuery, UserTaskReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserTaskReportQuery, UserTaskReportQueryVariables>(UserTaskReportDocument, options);
}
export function useUserTaskReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserTaskReportQuery, UserTaskReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserTaskReportQuery, UserTaskReportQueryVariables>(
    UserTaskReportDocument,
    options
  );
}
export type UserTaskReportQueryHookResult = ReturnType<typeof useUserTaskReportQuery>;
export type UserTaskReportLazyQueryHookResult = ReturnType<typeof useUserTaskReportLazyQuery>;
export type UserTaskReportQueryResult = ApolloReactCommon.QueryResult<
  UserTaskReportQuery,
  UserTaskReportQueryVariables
>;
export const AuthManagerDocument = gql`
  query AuthManager {
    viewer {
      ...AuthManager_Viewer
    }
  }
  ${AuthManager_ViewerFragmentDoc}
`;

/**
 * __useAuthManagerQuery__
 *
 * To run a query within a React component, call `useAuthManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthManagerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export function useAuthManagerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export type AuthManagerQueryHookResult = ReturnType<typeof useAuthManagerQuery>;
export type AuthManagerLazyQueryHookResult = ReturnType<typeof useAuthManagerLazyQuery>;
export type AuthManagerQueryResult = ApolloReactCommon.QueryResult<AuthManagerQuery, AuthManagerQueryVariables>;
export const LogoutUserDocument = gql`
  mutation LogoutUser {
    logoutUser {
      isLoggedOut
    }
  }
`;
export type LogoutUserMutationFn = ApolloReactCommon.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
}
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = ApolloReactCommon.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;
export const AdminGrammarTestQuestionsDocument = gql`
  query AdminGrammarTestQuestions($limit: Int, $offset: Int) {
    grammarTestQuestions(limit: $limit, offset: $offset, includeInactive: true) {
      totalCount
      nodes {
        ...AdminGrammarTestQuestion
      }
    }
  }
  ${AdminGrammarTestQuestionFragmentDoc}
`;

/**
 * __useAdminGrammarTestQuestionsQuery__
 *
 * To run a query within a React component, call `useAdminGrammarTestQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGrammarTestQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGrammarTestQuestionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdminGrammarTestQuestionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminGrammarTestQuestionsQuery,
    AdminGrammarTestQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminGrammarTestQuestionsQuery, AdminGrammarTestQuestionsQueryVariables>(
    AdminGrammarTestQuestionsDocument,
    options
  );
}
export function useAdminGrammarTestQuestionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminGrammarTestQuestionsQuery,
    AdminGrammarTestQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminGrammarTestQuestionsQuery, AdminGrammarTestQuestionsQueryVariables>(
    AdminGrammarTestQuestionsDocument,
    options
  );
}
export type AdminGrammarTestQuestionsQueryHookResult = ReturnType<typeof useAdminGrammarTestQuestionsQuery>;
export type AdminGrammarTestQuestionsLazyQueryHookResult = ReturnType<typeof useAdminGrammarTestQuestionsLazyQuery>;
export type AdminGrammarTestQuestionsQueryResult = ApolloReactCommon.QueryResult<
  AdminGrammarTestQuestionsQuery,
  AdminGrammarTestQuestionsQueryVariables
>;
export const SaveGrammarTestQuestionDocument = gql`
  mutation SaveGrammarTestQuestion($input: SaveGrammarTestQuestionInput!) {
    saveGrammarTestQuestion(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type SaveGrammarTestQuestionMutationFn = ApolloReactCommon.MutationFunction<
  SaveGrammarTestQuestionMutation,
  SaveGrammarTestQuestionMutationVariables
>;

/**
 * __useSaveGrammarTestQuestionMutation__
 *
 * To run a mutation, you first call `useSaveGrammarTestQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGrammarTestQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGrammarTestQuestionMutation, { data, loading, error }] = useSaveGrammarTestQuestionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveGrammarTestQuestionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveGrammarTestQuestionMutation,
    SaveGrammarTestQuestionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveGrammarTestQuestionMutation, SaveGrammarTestQuestionMutationVariables>(
    SaveGrammarTestQuestionDocument,
    options
  );
}
export type SaveGrammarTestQuestionMutationHookResult = ReturnType<typeof useSaveGrammarTestQuestionMutation>;
export type SaveGrammarTestQuestionMutationResult = ApolloReactCommon.MutationResult<SaveGrammarTestQuestionMutation>;
export type SaveGrammarTestQuestionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveGrammarTestQuestionMutation,
  SaveGrammarTestQuestionMutationVariables
>;
export const GrammarTestQuestionDocument = gql`
  query GrammarTestQuestion($id: Int!) {
    grammarTestQuestion(id: $id) {
      id
      prompt
      isActive
      answers {
        id
        content
        isCorrect
      }
    }
  }
`;

/**
 * __useGrammarTestQuestionQuery__
 *
 * To run a query within a React component, call `useGrammarTestQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrammarTestQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrammarTestQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGrammarTestQuestionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GrammarTestQuestionQuery, GrammarTestQuestionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GrammarTestQuestionQuery, GrammarTestQuestionQueryVariables>(
    GrammarTestQuestionDocument,
    options
  );
}
export function useGrammarTestQuestionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GrammarTestQuestionQuery, GrammarTestQuestionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GrammarTestQuestionQuery, GrammarTestQuestionQueryVariables>(
    GrammarTestQuestionDocument,
    options
  );
}
export type GrammarTestQuestionQueryHookResult = ReturnType<typeof useGrammarTestQuestionQuery>;
export type GrammarTestQuestionLazyQueryHookResult = ReturnType<typeof useGrammarTestQuestionLazyQuery>;
export type GrammarTestQuestionQueryResult = ApolloReactCommon.QueryResult<
  GrammarTestQuestionQuery,
  GrammarTestQuestionQueryVariables
>;
export const AdminQaViewTasksDocument = gql`
  query AdminQaViewTasks(
    $limit: Int
    $offset: Int
    $vendorIds: [Int!]
    $taskTypeIds: [String!]
    $statusIds: [String!]
    $search: String
    $completedAt: DateQuery
    $assignedUserIds: [Int!]
    $customerIds: [Int!]
    $sort: TasksSort
  ) {
    tasks(
      limit: $limit
      offset: $offset
      vendorIds: $vendorIds
      taskTypeIds: $taskTypeIds
      statusIds: $statusIds
      search: $search
      completedAt: $completedAt
      assignedUserIds: $assignedUserIds
      customerIds: $customerIds
      sort: $sort
    ) {
      totalCount
      nodes {
        ...AdminQaView_Task
      }
    }
  }
  ${AdminQaView_TaskFragmentDoc}
`;

/**
 * __useAdminQaViewTasksQuery__
 *
 * To run a query within a React component, call `useAdminQaViewTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminQaViewTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminQaViewTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      vendorIds: // value for 'vendorIds'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      statusIds: // value for 'statusIds'
 *      search: // value for 'search'
 *      completedAt: // value for 'completedAt'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      customerIds: // value for 'customerIds'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminQaViewTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminQaViewTasksQuery, AdminQaViewTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminQaViewTasksQuery, AdminQaViewTasksQueryVariables>(
    AdminQaViewTasksDocument,
    options
  );
}
export function useAdminQaViewTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminQaViewTasksQuery, AdminQaViewTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminQaViewTasksQuery, AdminQaViewTasksQueryVariables>(
    AdminQaViewTasksDocument,
    options
  );
}
export type AdminQaViewTasksQueryHookResult = ReturnType<typeof useAdminQaViewTasksQuery>;
export type AdminQaViewTasksLazyQueryHookResult = ReturnType<typeof useAdminQaViewTasksLazyQuery>;
export type AdminQaViewTasksQueryResult = ApolloReactCommon.QueryResult<
  AdminQaViewTasksQuery,
  AdminQaViewTasksQueryVariables
>;
export const AdminTasksDocument = gql`
  query AdminTasks(
    $limit: Int
    $offset: Int
    $customerIds: [Int!]
    $vendorIds: [Int!]
    $taskTypeIds: [String!]
    $statusIds: [String!]
    $assignedUserIds: [Int!]
    $search: String
  ) {
    tasks(
      limit: $limit
      offset: $offset
      customerIds: $customerIds
      vendorIds: $vendorIds
      taskTypeIds: $taskTypeIds
      statusIds: $statusIds
      assignedUserIds: $assignedUserIds
      search: $search
    ) {
      totalCount
      nodes {
        ...AdminTasks_Task
      }
    }
  }
  ${AdminTasks_TaskFragmentDoc}
`;

/**
 * __useAdminTasksQuery__
 *
 * To run a query within a React component, call `useAdminTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      customerIds: // value for 'customerIds'
 *      vendorIds: // value for 'vendorIds'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      statusIds: // value for 'statusIds'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminTasksQuery, AdminTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminTasksQuery, AdminTasksQueryVariables>(AdminTasksDocument, options);
}
export function useAdminTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminTasksQuery, AdminTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminTasksQuery, AdminTasksQueryVariables>(AdminTasksDocument, options);
}
export type AdminTasksQueryHookResult = ReturnType<typeof useAdminTasksQuery>;
export type AdminTasksLazyQueryHookResult = ReturnType<typeof useAdminTasksLazyQuery>;
export type AdminTasksQueryResult = ApolloReactCommon.QueryResult<AdminTasksQuery, AdminTasksQueryVariables>;
export const TeamMemberProfile_CreatePermissionDocument = gql`
  mutation TeamMemberProfile_CreatePermission($input: CreateUserTaskPermissionInput!) {
    createUserTaskPermission(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type TeamMemberProfile_CreatePermissionMutationFn = ApolloReactCommon.MutationFunction<
  TeamMemberProfile_CreatePermissionMutation,
  TeamMemberProfile_CreatePermissionMutationVariables
>;

/**
 * __useTeamMemberProfile_CreatePermissionMutation__
 *
 * To run a mutation, you first call `useTeamMemberProfile_CreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberProfile_CreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberProfileCreatePermissionMutation, { data, loading, error }] = useTeamMemberProfile_CreatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamMemberProfile_CreatePermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TeamMemberProfile_CreatePermissionMutation,
    TeamMemberProfile_CreatePermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TeamMemberProfile_CreatePermissionMutation,
    TeamMemberProfile_CreatePermissionMutationVariables
  >(TeamMemberProfile_CreatePermissionDocument, options);
}
export type TeamMemberProfile_CreatePermissionMutationHookResult = ReturnType<
  typeof useTeamMemberProfile_CreatePermissionMutation
>;
export type TeamMemberProfile_CreatePermissionMutationResult =
  ApolloReactCommon.MutationResult<TeamMemberProfile_CreatePermissionMutation>;
export type TeamMemberProfile_CreatePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TeamMemberProfile_CreatePermissionMutation,
  TeamMemberProfile_CreatePermissionMutationVariables
>;
export const TeamMemberProfile_DeletePermissionDocument = gql`
  mutation TeamMemberProfile_DeletePermission($input: DeleteUserTaskPermissionInput!) {
    deleteUserTaskPermission(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type TeamMemberProfile_DeletePermissionMutationFn = ApolloReactCommon.MutationFunction<
  TeamMemberProfile_DeletePermissionMutation,
  TeamMemberProfile_DeletePermissionMutationVariables
>;

/**
 * __useTeamMemberProfile_DeletePermissionMutation__
 *
 * To run a mutation, you first call `useTeamMemberProfile_DeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberProfile_DeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamMemberProfileDeletePermissionMutation, { data, loading, error }] = useTeamMemberProfile_DeletePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTeamMemberProfile_DeletePermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TeamMemberProfile_DeletePermissionMutation,
    TeamMemberProfile_DeletePermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TeamMemberProfile_DeletePermissionMutation,
    TeamMemberProfile_DeletePermissionMutationVariables
  >(TeamMemberProfile_DeletePermissionDocument, options);
}
export type TeamMemberProfile_DeletePermissionMutationHookResult = ReturnType<
  typeof useTeamMemberProfile_DeletePermissionMutation
>;
export type TeamMemberProfile_DeletePermissionMutationResult =
  ApolloReactCommon.MutationResult<TeamMemberProfile_DeletePermissionMutation>;
export type TeamMemberProfile_DeletePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TeamMemberProfile_DeletePermissionMutation,
  TeamMemberProfile_DeletePermissionMutationVariables
>;
export const TeamMemberProfileDocument = gql`
  query TeamMemberProfile($id: Int!) {
    creativesScaleUser(creativesScaleUserId: $id) {
      id
      user {
        id
        firstName
        lastName
        email
        userTaskPermissions(vendorId: 3) {
          ...TeamMemberProfile_UserTaskPermission
        }
        userProfessional {
          linkedInProfileUrl
          resumeFile {
            id
            url
            name
          }
        }
      }
      avgStarRating
      isRCMWriter
      onboardingStatus {
        id
        name
      }
      onboardingStatusLastUpdatedAt
      workInterests {
        id
        name
      }
      latestWritingSample {
        reviewStatus {
          id
          name
        }
        reviewerUser {
          id
          fullName
        }
      }
      latestUserGrammarTest {
        id
        completedAt
        result
      }
      stripeAccountId
      writerClassification
    }
    productTaskTypes {
      totalCount
      nodes {
        ...TeamMemberProfile_ProductTaskType
      }
    }
  }
  ${TeamMemberProfile_UserTaskPermissionFragmentDoc}
  ${TeamMemberProfile_ProductTaskTypeFragmentDoc}
`;

/**
 * __useTeamMemberProfileQuery__
 *
 * To run a query within a React component, call `useTeamMemberProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamMemberProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TeamMemberProfileQuery, TeamMemberProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeamMemberProfileQuery, TeamMemberProfileQueryVariables>(
    TeamMemberProfileDocument,
    options
  );
}
export function useTeamMemberProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamMemberProfileQuery, TeamMemberProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeamMemberProfileQuery, TeamMemberProfileQueryVariables>(
    TeamMemberProfileDocument,
    options
  );
}
export type TeamMemberProfileQueryHookResult = ReturnType<typeof useTeamMemberProfileQuery>;
export type TeamMemberProfileLazyQueryHookResult = ReturnType<typeof useTeamMemberProfileLazyQuery>;
export type TeamMemberProfileQueryResult = ApolloReactCommon.QueryResult<
  TeamMemberProfileQuery,
  TeamMemberProfileQueryVariables
>;
export const UpdateTeamMemberProfileDocument = gql`
  mutation UpdateTeamMemberProfile($input: UpdateCreativeScaleUserInput) {
    updateCreativesScaleUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateTeamMemberProfileMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTeamMemberProfileMutation,
  UpdateTeamMemberProfileMutationVariables
>;

/**
 * __useUpdateTeamMemberProfileMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberProfileMutation, { data, loading, error }] = useUpdateTeamMemberProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMemberProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTeamMemberProfileMutation,
    UpdateTeamMemberProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTeamMemberProfileMutation, UpdateTeamMemberProfileMutationVariables>(
    UpdateTeamMemberProfileDocument,
    options
  );
}
export type UpdateTeamMemberProfileMutationHookResult = ReturnType<typeof useUpdateTeamMemberProfileMutation>;
export type UpdateTeamMemberProfileMutationResult = ApolloReactCommon.MutationResult<UpdateTeamMemberProfileMutation>;
export type UpdateTeamMemberProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTeamMemberProfileMutation,
  UpdateTeamMemberProfileMutationVariables
>;
export const TasksHistoryDocument = gql`
  query TasksHistory(
    $currentLimit: Int
    $currentOffset: Int
    $currentSort: TasksSort
    $assignedUserIds: [Int!]
    $vendorIds: [Int!]
    $currentStatusIds: [String!]
    $historyStatusIds: [String!]
    $historyLimit: Int
    $historyOffset: Int
    $historySort: TasksSort
  ) {
    currentTasks: tasks(
      limit: $currentLimit
      offset: $currentOffset
      assignedUserIds: $assignedUserIds
      vendorIds: $vendorIds
      statusIds: $currentStatusIds
      sort: $currentSort
    ) {
      totalCount
      nodes {
        ...TaskHistory
      }
    }
    historyTasks: tasks(
      limit: $historyLimit
      offset: $historyOffset
      assignedUserIds: $assignedUserIds
      vendorIds: $vendorIds
      statusIds: $historyStatusIds
      sort: $historySort
    ) {
      totalCount
      nodes {
        ...TaskHistory
      }
    }
  }
  ${TaskHistoryFragmentDoc}
`;

/**
 * __useTasksHistoryQuery__
 *
 * To run a query within a React component, call `useTasksHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksHistoryQuery({
 *   variables: {
 *      currentLimit: // value for 'currentLimit'
 *      currentOffset: // value for 'currentOffset'
 *      currentSort: // value for 'currentSort'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      vendorIds: // value for 'vendorIds'
 *      currentStatusIds: // value for 'currentStatusIds'
 *      historyStatusIds: // value for 'historyStatusIds'
 *      historyLimit: // value for 'historyLimit'
 *      historyOffset: // value for 'historyOffset'
 *      historySort: // value for 'historySort'
 *   },
 * });
 */
export function useTasksHistoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TasksHistoryQuery, TasksHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TasksHistoryQuery, TasksHistoryQueryVariables>(TasksHistoryDocument, options);
}
export function useTasksHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksHistoryQuery, TasksHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TasksHistoryQuery, TasksHistoryQueryVariables>(TasksHistoryDocument, options);
}
export type TasksHistoryQueryHookResult = ReturnType<typeof useTasksHistoryQuery>;
export type TasksHistoryLazyQueryHookResult = ReturnType<typeof useTasksHistoryLazyQuery>;
export type TasksHistoryQueryResult = ApolloReactCommon.QueryResult<TasksHistoryQuery, TasksHistoryQueryVariables>;
export const TasksHistoryTeamMemberDocument = gql`
  query TasksHistoryTeamMember($creativesScaleUserId: Int!) {
    creativesScaleUser(creativesScaleUserId: $creativesScaleUserId) {
      id
      user {
        id
      }
    }
  }
`;

/**
 * __useTasksHistoryTeamMemberQuery__
 *
 * To run a query within a React component, call `useTasksHistoryTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksHistoryTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksHistoryTeamMemberQuery({
 *   variables: {
 *      creativesScaleUserId: // value for 'creativesScaleUserId'
 *   },
 * });
 */
export function useTasksHistoryTeamMemberQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TasksHistoryTeamMemberQuery, TasksHistoryTeamMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TasksHistoryTeamMemberQuery, TasksHistoryTeamMemberQueryVariables>(
    TasksHistoryTeamMemberDocument,
    options
  );
}
export function useTasksHistoryTeamMemberLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksHistoryTeamMemberQuery, TasksHistoryTeamMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TasksHistoryTeamMemberQuery, TasksHistoryTeamMemberQueryVariables>(
    TasksHistoryTeamMemberDocument,
    options
  );
}
export type TasksHistoryTeamMemberQueryHookResult = ReturnType<typeof useTasksHistoryTeamMemberQuery>;
export type TasksHistoryTeamMemberLazyQueryHookResult = ReturnType<typeof useTasksHistoryTeamMemberLazyQuery>;
export type TasksHistoryTeamMemberQueryResult = ApolloReactCommon.QueryResult<
  TasksHistoryTeamMemberQuery,
  TasksHistoryTeamMemberQueryVariables
>;
export const UpdateTeamMemberDocument = gql`
  mutation UpdateTeamMember($input: UpdateCreativeScaleUserInput) {
    updateCreativesScaleUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateTeamMemberMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>;

/**
 * __useUpdateTeamMemberMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberMutation, { data, loading, error }] = useUpdateTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTeamMemberMutation, UpdateTeamMemberMutationVariables>(
    UpdateTeamMemberDocument,
    options
  );
}
export type UpdateTeamMemberMutationHookResult = ReturnType<typeof useUpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationResult = ApolloReactCommon.MutationResult<UpdateTeamMemberMutation>;
export type UpdateTeamMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTeamMemberMutation,
  UpdateTeamMemberMutationVariables
>;
export const TeamMembersDocument = gql`
  query TeamMembers($search: String, $limit: Int, $offset: Int, $isAdmin: Boolean, $onboardingStatusIds: [String!]) {
    creativesScaleUsers(
      search: $search
      limit: $limit
      offset: $offset
      isAdmin: $isAdmin
      onboardingStatusIds: $onboardingStatusIds
    ) {
      totalCount
      nodes {
        ...TeamMemberList
      }
    }
  }
  ${TeamMemberListFragmentDoc}
`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      isAdmin: // value for 'isAdmin'
 *      onboardingStatusIds: // value for 'onboardingStatusIds'
 *   },
 * });
 */
export function useTeamMembersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
}
export function useTeamMembersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
}
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = ApolloReactCommon.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const AdminUserReportDocument = gql`
  query AdminUserReport($search: String, $limit: Int, $offset: Int, $onboardingStatusIds: [String!]) {
    creativesScaleUsers(search: $search, limit: $limit, offset: $offset, onboardingStatusIds: $onboardingStatusIds) {
      totalCount
      nodes {
        user {
          id
        }
      }
    }
  }
`;

/**
 * __useAdminUserReportQuery__
 *
 * To run a query within a React component, call `useAdminUserReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserReportQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      onboardingStatusIds: // value for 'onboardingStatusIds'
 *   },
 * });
 */
export function useAdminUserReportQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminUserReportQuery, AdminUserReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUserReportQuery, AdminUserReportQueryVariables>(
    AdminUserReportDocument,
    options
  );
}
export function useAdminUserReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminUserReportQuery, AdminUserReportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminUserReportQuery, AdminUserReportQueryVariables>(
    AdminUserReportDocument,
    options
  );
}
export type AdminUserReportQueryHookResult = ReturnType<typeof useAdminUserReportQuery>;
export type AdminUserReportLazyQueryHookResult = ReturnType<typeof useAdminUserReportLazyQuery>;
export type AdminUserReportQueryResult = ApolloReactCommon.QueryResult<
  AdminUserReportQuery,
  AdminUserReportQueryVariables
>;
export const UserWritingSampleDocument = gql`
  query UserWritingSample($userWritingSampleId: Int!) {
    userWritingSample(userWritingSampleId: $userWritingSampleId) {
      id
      endsAt
      writingSampleTopic {
        category {
          id
          name
        }
        topic
      }
      user {
        id
        fullName
        userProfessional {
          userId
          linkedInProfileUrl
          resumeFile {
            id
            name
            url
          }
        }
      }
      contentItem {
        title
        content
      }
      validationCheckSummary {
        ...ValidationChecklistPanel_Summary
      }
    }
  }
  ${ValidationChecklistPanel_SummaryFragmentDoc}
`;

/**
 * __useUserWritingSampleQuery__
 *
 * To run a query within a React component, call `useUserWritingSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWritingSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWritingSampleQuery({
 *   variables: {
 *      userWritingSampleId: // value for 'userWritingSampleId'
 *   },
 * });
 */
export function useUserWritingSampleQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserWritingSampleQuery, UserWritingSampleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserWritingSampleQuery, UserWritingSampleQueryVariables>(
    UserWritingSampleDocument,
    options
  );
}
export function useUserWritingSampleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWritingSampleQuery, UserWritingSampleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserWritingSampleQuery, UserWritingSampleQueryVariables>(
    UserWritingSampleDocument,
    options
  );
}
export type UserWritingSampleQueryHookResult = ReturnType<typeof useUserWritingSampleQuery>;
export type UserWritingSampleLazyQueryHookResult = ReturnType<typeof useUserWritingSampleLazyQuery>;
export type UserWritingSampleQueryResult = ApolloReactCommon.QueryResult<
  UserWritingSampleQuery,
  UserWritingSampleQueryVariables
>;
export const AdminWritingSampleTopics_CreateDocument = gql`
  mutation AdminWritingSampleTopics_Create($input: CreateWritingSampleTopicInput!) {
    createWritingSampleTopic(input: $input) {
      writingSampleTopic {
        id
      }
    }
  }
`;
export type AdminWritingSampleTopics_CreateMutationFn = ApolloReactCommon.MutationFunction<
  AdminWritingSampleTopics_CreateMutation,
  AdminWritingSampleTopics_CreateMutationVariables
>;

/**
 * __useAdminWritingSampleTopics_CreateMutation__
 *
 * To run a mutation, you first call `useAdminWritingSampleTopics_CreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWritingSampleTopics_CreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWritingSampleTopicsCreateMutation, { data, loading, error }] = useAdminWritingSampleTopics_CreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminWritingSampleTopics_CreateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminWritingSampleTopics_CreateMutation,
    AdminWritingSampleTopics_CreateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminWritingSampleTopics_CreateMutation,
    AdminWritingSampleTopics_CreateMutationVariables
  >(AdminWritingSampleTopics_CreateDocument, options);
}
export type AdminWritingSampleTopics_CreateMutationHookResult = ReturnType<
  typeof useAdminWritingSampleTopics_CreateMutation
>;
export type AdminWritingSampleTopics_CreateMutationResult =
  ApolloReactCommon.MutationResult<AdminWritingSampleTopics_CreateMutation>;
export type AdminWritingSampleTopics_CreateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminWritingSampleTopics_CreateMutation,
  AdminWritingSampleTopics_CreateMutationVariables
>;
export const AdminWritingSampleTopics_UpdateDocument = gql`
  mutation AdminWritingSampleTopics_Update($input: UpdateWritingSampleTopicInput!) {
    updateWritingSampleTopic(input: $input) {
      writingSampleTopic {
        id
      }
    }
  }
`;
export type AdminWritingSampleTopics_UpdateMutationFn = ApolloReactCommon.MutationFunction<
  AdminWritingSampleTopics_UpdateMutation,
  AdminWritingSampleTopics_UpdateMutationVariables
>;

/**
 * __useAdminWritingSampleTopics_UpdateMutation__
 *
 * To run a mutation, you first call `useAdminWritingSampleTopics_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminWritingSampleTopics_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminWritingSampleTopicsUpdateMutation, { data, loading, error }] = useAdminWritingSampleTopics_UpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminWritingSampleTopics_UpdateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminWritingSampleTopics_UpdateMutation,
    AdminWritingSampleTopics_UpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminWritingSampleTopics_UpdateMutation,
    AdminWritingSampleTopics_UpdateMutationVariables
  >(AdminWritingSampleTopics_UpdateDocument, options);
}
export type AdminWritingSampleTopics_UpdateMutationHookResult = ReturnType<
  typeof useAdminWritingSampleTopics_UpdateMutation
>;
export type AdminWritingSampleTopics_UpdateMutationResult =
  ApolloReactCommon.MutationResult<AdminWritingSampleTopics_UpdateMutation>;
export type AdminWritingSampleTopics_UpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminWritingSampleTopics_UpdateMutation,
  AdminWritingSampleTopics_UpdateMutationVariables
>;
export const AdminWritingSampleTopicsDocument = gql`
  query AdminWritingSampleTopics($limit: Int, $offset: Int) {
    writingSampleTopics(limit: $limit, offset: $offset, includeInactive: true) {
      totalCount
      nodes {
        ...AdminWritingSampleTopic
      }
    }
  }
  ${AdminWritingSampleTopicFragmentDoc}
`;

/**
 * __useAdminWritingSampleTopicsQuery__
 *
 * To run a query within a React component, call `useAdminWritingSampleTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminWritingSampleTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminWritingSampleTopicsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAdminWritingSampleTopicsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminWritingSampleTopicsQuery, AdminWritingSampleTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminWritingSampleTopicsQuery, AdminWritingSampleTopicsQueryVariables>(
    AdminWritingSampleTopicsDocument,
    options
  );
}
export function useAdminWritingSampleTopicsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminWritingSampleTopicsQuery,
    AdminWritingSampleTopicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminWritingSampleTopicsQuery, AdminWritingSampleTopicsQueryVariables>(
    AdminWritingSampleTopicsDocument,
    options
  );
}
export type AdminWritingSampleTopicsQueryHookResult = ReturnType<typeof useAdminWritingSampleTopicsQuery>;
export type AdminWritingSampleTopicsLazyQueryHookResult = ReturnType<typeof useAdminWritingSampleTopicsLazyQuery>;
export type AdminWritingSampleTopicsQueryResult = ApolloReactCommon.QueryResult<
  AdminWritingSampleTopicsQuery,
  AdminWritingSampleTopicsQueryVariables
>;
export const ApproveUserWritingSampleDocument = gql`
  mutation ApproveUserWritingSample($input: ApproveUserWritingSampleInput!) {
    approveUserWritingSample(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type ApproveUserWritingSampleMutationFn = ApolloReactCommon.MutationFunction<
  ApproveUserWritingSampleMutation,
  ApproveUserWritingSampleMutationVariables
>;

/**
 * __useApproveUserWritingSampleMutation__
 *
 * To run a mutation, you first call `useApproveUserWritingSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUserWritingSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUserWritingSampleMutation, { data, loading, error }] = useApproveUserWritingSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveUserWritingSampleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ApproveUserWritingSampleMutation,
    ApproveUserWritingSampleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ApproveUserWritingSampleMutation, ApproveUserWritingSampleMutationVariables>(
    ApproveUserWritingSampleDocument,
    options
  );
}
export type ApproveUserWritingSampleMutationHookResult = ReturnType<typeof useApproveUserWritingSampleMutation>;
export type ApproveUserWritingSampleMutationResult = ApolloReactCommon.MutationResult<ApproveUserWritingSampleMutation>;
export type ApproveUserWritingSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ApproveUserWritingSampleMutation,
  ApproveUserWritingSampleMutationVariables
>;
export const RejectUserWritingSampleDocument = gql`
  mutation RejectUserWritingSample($input: RejectUserWritingSampleInput!) {
    rejectUserWritingSample(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type RejectUserWritingSampleMutationFn = ApolloReactCommon.MutationFunction<
  RejectUserWritingSampleMutation,
  RejectUserWritingSampleMutationVariables
>;

/**
 * __useRejectUserWritingSampleMutation__
 *
 * To run a mutation, you first call `useRejectUserWritingSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectUserWritingSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectUserWritingSampleMutation, { data, loading, error }] = useRejectUserWritingSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectUserWritingSampleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectUserWritingSampleMutation,
    RejectUserWritingSampleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RejectUserWritingSampleMutation, RejectUserWritingSampleMutationVariables>(
    RejectUserWritingSampleDocument,
    options
  );
}
export type RejectUserWritingSampleMutationHookResult = ReturnType<typeof useRejectUserWritingSampleMutation>;
export type RejectUserWritingSampleMutationResult = ApolloReactCommon.MutationResult<RejectUserWritingSampleMutation>;
export type RejectUserWritingSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectUserWritingSampleMutation,
  RejectUserWritingSampleMutationVariables
>;
export const WritingSamplesDocument = gql`
  query WritingSamples(
    $limit: Int
    $offset: Int
    $sort: UserWritingSamplesSort
    $search: String
    $reviewedAt: DateQuery
  ) {
    userWritingSamples(limit: $limit, offset: $offset, sort: $sort, search: $search, reviewedAt: $reviewedAt) {
      totalCount
      nodes {
        id
        writingSampleTopic {
          category {
            id
            name
          }
          topic
        }
        user {
          id
          fullName
        }
        contentItem {
          title
        }
      }
    }
  }
`;

/**
 * __useWritingSamplesQuery__
 *
 * To run a query within a React component, call `useWritingSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingSamplesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      reviewedAt: // value for 'reviewedAt'
 *   },
 * });
 */
export function useWritingSamplesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WritingSamplesQuery, WritingSamplesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WritingSamplesQuery, WritingSamplesQueryVariables>(WritingSamplesDocument, options);
}
export function useWritingSamplesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WritingSamplesQuery, WritingSamplesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WritingSamplesQuery, WritingSamplesQueryVariables>(
    WritingSamplesDocument,
    options
  );
}
export type WritingSamplesQueryHookResult = ReturnType<typeof useWritingSamplesQuery>;
export type WritingSamplesLazyQueryHookResult = ReturnType<typeof useWritingSamplesLazyQuery>;
export type WritingSamplesQueryResult = ApolloReactCommon.QueryResult<
  WritingSamplesQuery,
  WritingSamplesQueryVariables
>;
export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      ok
    }
  }
`;
export type ConfirmEmailMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
    ConfirmEmailDocument,
    options
  );
}
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = ApolloReactCommon.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const FindTasksFilterDocument = gql`
  query FindTasksFilter {
    categories {
      totalCount
      nodes {
        id
        name
        isActive
      }
    }
    productTaskTypes {
      nodes {
        ...FindTasksFilter_ProductTaskType
      }
    }
    taskTypes {
      nodes {
        id
        name
        isFulfilledByCustomer
      }
    }
  }
  ${FindTasksFilter_ProductTaskTypeFragmentDoc}
`;

/**
 * __useFindTasksFilterQuery__
 *
 * To run a query within a React component, call `useFindTasksFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTasksFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTasksFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindTasksFilterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<FindTasksFilterQuery, FindTasksFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindTasksFilterQuery, FindTasksFilterQueryVariables>(
    FindTasksFilterDocument,
    options
  );
}
export function useFindTasksFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindTasksFilterQuery, FindTasksFilterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindTasksFilterQuery, FindTasksFilterQueryVariables>(
    FindTasksFilterDocument,
    options
  );
}
export type FindTasksFilterQueryHookResult = ReturnType<typeof useFindTasksFilterQuery>;
export type FindTasksFilterLazyQueryHookResult = ReturnType<typeof useFindTasksFilterLazyQuery>;
export type FindTasksFilterQueryResult = ApolloReactCommon.QueryResult<
  FindTasksFilterQuery,
  FindTasksFilterQueryVariables
>;
export const FindTasksDocument = gql`
  query FindTasks(
    $limit: Int
    $offset: Int
    $statusIds: [String!]!
    $vendorIds: [Int!]
    $productTaskTypes: [ProductTaskTypePair]
    $categoryIds: [Int!]
    $taskTypeIds: [String!]
    $isFulfilledByRCMWriter: Boolean
    $search: String
  ) {
    tasks(
      limit: $limit
      offset: $offset
      statusIds: $statusIds
      vendorIds: $vendorIds
      productTaskTypes: $productTaskTypes
      categoryIds: $categoryIds
      taskTypeIds: $taskTypeIds
      isFulfilledByRCMWriter: $isFulfilledByRCMWriter
      search: $search
    ) {
      totalCount
      nodes {
        id
        taskType {
          id
          name
        }
        category {
          id
          name
        }
        order {
          id
          topic
        }
        product {
          id
          name
          minWordCount
        }
        customer {
          id
          name
          isGuestArticleFulfilledByRCMWriter
          isBlogFulfilledByRCMWriter
        }
        paymentAmount
      }
    }
  }
`;

/**
 * __useFindTasksQuery__
 *
 * To run a query within a React component, call `useFindTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      statusIds: // value for 'statusIds'
 *      vendorIds: // value for 'vendorIds'
 *      productTaskTypes: // value for 'productTaskTypes'
 *      categoryIds: // value for 'categoryIds'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      isFulfilledByRCMWriter: // value for 'isFulfilledByRCMWriter'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFindTasksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<FindTasksQuery, FindTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindTasksQuery, FindTasksQueryVariables>(FindTasksDocument, options);
}
export function useFindTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindTasksQuery, FindTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindTasksQuery, FindTasksQueryVariables>(FindTasksDocument, options);
}
export type FindTasksQueryHookResult = ReturnType<typeof useFindTasksQuery>;
export type FindTasksLazyQueryHookResult = ReturnType<typeof useFindTasksLazyQuery>;
export type FindTasksQueryResult = ApolloReactCommon.QueryResult<FindTasksQuery, FindTasksQueryVariables>;
export const ClaimTaskDocument = gql`
  mutation ClaimTask($input: ClaimTaskInput!) {
    claimTask(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type ClaimTaskMutationFn = ApolloReactCommon.MutationFunction<ClaimTaskMutation, ClaimTaskMutationVariables>;

/**
 * __useClaimTaskMutation__
 *
 * To run a mutation, you first call `useClaimTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimTaskMutation, { data, loading, error }] = useClaimTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimTaskMutation, ClaimTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ClaimTaskMutation, ClaimTaskMutationVariables>(ClaimTaskDocument, options);
}
export type ClaimTaskMutationHookResult = ReturnType<typeof useClaimTaskMutation>;
export type ClaimTaskMutationResult = ApolloReactCommon.MutationResult<ClaimTaskMutation>;
export type ClaimTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClaimTaskMutation,
  ClaimTaskMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      wasRequested
    }
  }
`;
export type RequestPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
    }
  }
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MyTasksDocument = gql`
  query MyTasks(
    $limit: Int
    $offset: Int
    $assignedUserIds: [Int!]
    $vendorIds: [Int!]
    $search: String
    $sort: TasksSort
    $statusIds: [String!]
    $ignoreUserTaskPermission: Boolean
  ) {
    tasks(
      limit: $limit
      offset: $offset
      assignedUserIds: $assignedUserIds
      vendorIds: $vendorIds
      search: $search
      sort: $sort
      statusIds: $statusIds
      ignoreUserTaskPermission: $ignoreUserTaskPermission
    ) {
      totalCount
      nodes {
        ...MyTask
      }
    }
  }
  ${MyTaskFragmentDoc}
`;

/**
 * __useMyTasksQuery__
 *
 * To run a query within a React component, call `useMyTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      vendorIds: // value for 'vendorIds'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      statusIds: // value for 'statusIds'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useMyTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyTasksQuery, MyTasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MyTasksQuery, MyTasksQueryVariables>(MyTasksDocument, options);
}
export function useMyTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyTasksQuery, MyTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MyTasksQuery, MyTasksQueryVariables>(MyTasksDocument, options);
}
export type MyTasksQueryHookResult = ReturnType<typeof useMyTasksQuery>;
export type MyTasksLazyQueryHookResult = ReturnType<typeof useMyTasksLazyQuery>;
export type MyTasksQueryResult = ApolloReactCommon.QueryResult<MyTasksQuery, MyTasksQueryVariables>;
export const ResendConfirmationEmailDocument = gql`
  mutation ResendConfirmationEmail($input: ResendConfirmationEmailInput!) {
    resendConfirmationEmail(input: $input) {
      ok
    }
  }
`;
export type ResendConfirmationEmailMutationFn = ApolloReactCommon.MutationFunction<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendConfirmationEmailMutation,
    ResendConfirmationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(
    ResendConfirmationEmailDocument,
    options
  );
}
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = ApolloReactCommon.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResendConfirmationEmailMutation,
  ResendConfirmationEmailMutationVariables
>;
export const LastGrammarTestResultDocument = gql`
  query LastGrammarTestResult {
    viewer {
      creativesScaleUser {
        id
        onboardingStatusId
        latestUserGrammarTest {
          id
          result
          completedAt
        }
      }
    }
  }
`;

/**
 * __useLastGrammarTestResultQuery__
 *
 * To run a query within a React component, call `useLastGrammarTestResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastGrammarTestResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastGrammarTestResultQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastGrammarTestResultQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LastGrammarTestResultQuery, LastGrammarTestResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LastGrammarTestResultQuery, LastGrammarTestResultQueryVariables>(
    LastGrammarTestResultDocument,
    options
  );
}
export function useLastGrammarTestResultLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LastGrammarTestResultQuery, LastGrammarTestResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LastGrammarTestResultQuery, LastGrammarTestResultQueryVariables>(
    LastGrammarTestResultDocument,
    options
  );
}
export type LastGrammarTestResultQueryHookResult = ReturnType<typeof useLastGrammarTestResultQuery>;
export type LastGrammarTestResultLazyQueryHookResult = ReturnType<typeof useLastGrammarTestResultLazyQuery>;
export type LastGrammarTestResultQueryResult = ApolloReactCommon.QueryResult<
  LastGrammarTestResultQuery,
  LastGrammarTestResultQueryVariables
>;
export const SaveUserGrammarTestResponseDocument = gql`
  mutation SaveUserGrammarTestResponse($input: SaveUserGrammarTestResponseInput!) {
    saveUserGrammarTestResponse(input: $input) {
      ok
    }
  }
`;
export type SaveUserGrammarTestResponseMutationFn = ApolloReactCommon.MutationFunction<
  SaveUserGrammarTestResponseMutation,
  SaveUserGrammarTestResponseMutationVariables
>;

/**
 * __useSaveUserGrammarTestResponseMutation__
 *
 * To run a mutation, you first call `useSaveUserGrammarTestResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserGrammarTestResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserGrammarTestResponseMutation, { data, loading, error }] = useSaveUserGrammarTestResponseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserGrammarTestResponseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveUserGrammarTestResponseMutation,
    SaveUserGrammarTestResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveUserGrammarTestResponseMutation,
    SaveUserGrammarTestResponseMutationVariables
  >(SaveUserGrammarTestResponseDocument, options);
}
export type SaveUserGrammarTestResponseMutationHookResult = ReturnType<typeof useSaveUserGrammarTestResponseMutation>;
export type SaveUserGrammarTestResponseMutationResult =
  ApolloReactCommon.MutationResult<SaveUserGrammarTestResponseMutation>;
export type SaveUserGrammarTestResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveUserGrammarTestResponseMutation,
  SaveUserGrammarTestResponseMutationVariables
>;
export const GrammarTestDocument = gql`
  query GrammarTest {
    viewer {
      creativesScaleUser {
        id
        onboardingStatusId
        latestUserGrammarTest {
          id
        }
        activeUserGrammarTest {
          id
          startedAt
          expiresAt
          activeQuestion {
            ...GrammarTest_ActiveQuestion
          }
        }
      }
    }
  }
  ${GrammarTest_ActiveQuestionFragmentDoc}
`;

/**
 * __useGrammarTestQuery__
 *
 * To run a query within a React component, call `useGrammarTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrammarTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrammarTestQuery({
 *   variables: {
 *   },
 * });
 */
export function useGrammarTestQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GrammarTestQuery, GrammarTestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GrammarTestQuery, GrammarTestQueryVariables>(GrammarTestDocument, options);
}
export function useGrammarTestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GrammarTestQuery, GrammarTestQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GrammarTestQuery, GrammarTestQueryVariables>(GrammarTestDocument, options);
}
export type GrammarTestQueryHookResult = ReturnType<typeof useGrammarTestQuery>;
export type GrammarTestLazyQueryHookResult = ReturnType<typeof useGrammarTestLazyQuery>;
export type GrammarTestQueryResult = ApolloReactCommon.QueryResult<GrammarTestQuery, GrammarTestQueryVariables>;
export const JourneyDocument = gql`
  query Journey {
    viewer {
      creativesScaleUser {
        id
        onboardingStatusId
      }
    }
  }
`;

/**
 * __useJourneyQuery__
 *
 * To run a query within a React component, call `useJourneyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJourneyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJourneyQuery({
 *   variables: {
 *   },
 * });
 */
export function useJourneyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<JourneyQuery, JourneyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<JourneyQuery, JourneyQueryVariables>(JourneyDocument, options);
}
export function useJourneyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<JourneyQuery, JourneyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<JourneyQuery, JourneyQueryVariables>(JourneyDocument, options);
}
export type JourneyQueryHookResult = ReturnType<typeof useJourneyQuery>;
export type JourneyLazyQueryHookResult = ReturnType<typeof useJourneyLazyQuery>;
export type JourneyQueryResult = ApolloReactCommon.QueryResult<JourneyQuery, JourneyQueryVariables>;
export const NonWriterDocument = gql`
  query NonWriter {
    viewer {
      creativesScaleUser {
        id
        optedInForUpdatesAt
      }
    }
  }
`;

/**
 * __useNonWriterQuery__
 *
 * To run a query within a React component, call `useNonWriterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonWriterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonWriterQuery({
 *   variables: {
 *   },
 * });
 */
export function useNonWriterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<NonWriterQuery, NonWriterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NonWriterQuery, NonWriterQueryVariables>(NonWriterDocument, options);
}
export function useNonWriterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NonWriterQuery, NonWriterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NonWriterQuery, NonWriterQueryVariables>(NonWriterDocument, options);
}
export type NonWriterQueryHookResult = ReturnType<typeof useNonWriterQuery>;
export type NonWriterLazyQueryHookResult = ReturnType<typeof useNonWriterLazyQuery>;
export type NonWriterQueryResult = ApolloReactCommon.QueryResult<NonWriterQuery, NonWriterQueryVariables>;
export const NonWriter_UpdateCreativesScaleUserDocument = gql`
  mutation NonWriter_UpdateCreativesScaleUser($input: UpdateCreativeScaleUserInput!) {
    updateCreativesScaleUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type NonWriter_UpdateCreativesScaleUserMutationFn = ApolloReactCommon.MutationFunction<
  NonWriter_UpdateCreativesScaleUserMutation,
  NonWriter_UpdateCreativesScaleUserMutationVariables
>;

/**
 * __useNonWriter_UpdateCreativesScaleUserMutation__
 *
 * To run a mutation, you first call `useNonWriter_UpdateCreativesScaleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonWriter_UpdateCreativesScaleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonWriterUpdateCreativesScaleUserMutation, { data, loading, error }] = useNonWriter_UpdateCreativesScaleUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNonWriter_UpdateCreativesScaleUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NonWriter_UpdateCreativesScaleUserMutation,
    NonWriter_UpdateCreativesScaleUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    NonWriter_UpdateCreativesScaleUserMutation,
    NonWriter_UpdateCreativesScaleUserMutationVariables
  >(NonWriter_UpdateCreativesScaleUserDocument, options);
}
export type NonWriter_UpdateCreativesScaleUserMutationHookResult = ReturnType<
  typeof useNonWriter_UpdateCreativesScaleUserMutation
>;
export type NonWriter_UpdateCreativesScaleUserMutationResult =
  ApolloReactCommon.MutationResult<NonWriter_UpdateCreativesScaleUserMutation>;
export type NonWriter_UpdateCreativesScaleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NonWriter_UpdateCreativesScaleUserMutation,
  NonWriter_UpdateCreativesScaleUserMutationVariables
>;
export const CreateUserProfessionalDocument = gql`
  mutation CreateUserProfessional($input: CreateUserProfessionalInput!) {
    createUserProfessional(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateUserProfessionalMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserProfessionalMutation,
  CreateUserProfessionalMutationVariables
>;

/**
 * __useCreateUserProfessionalMutation__
 *
 * To run a mutation, you first call `useCreateUserProfessionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfessionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfessionalMutation, { data, loading, error }] = useCreateUserProfessionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserProfessionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserProfessionalMutation,
    CreateUserProfessionalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateUserProfessionalMutation, CreateUserProfessionalMutationVariables>(
    CreateUserProfessionalDocument,
    options
  );
}
export type CreateUserProfessionalMutationHookResult = ReturnType<typeof useCreateUserProfessionalMutation>;
export type CreateUserProfessionalMutationResult = ApolloReactCommon.MutationResult<CreateUserProfessionalMutation>;
export type CreateUserProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserProfessionalMutation,
  CreateUserProfessionalMutationVariables
>;
export const Signup_GenerateUrlForFileUploadDocument = gql`
  mutation Signup_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadSignupInput!) {
    generateUrlForFileUploadSignup(input: $input) {
      fileId
      url
    }
  }
`;
export type Signup_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  Signup_GenerateUrlForFileUploadMutation,
  Signup_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __useSignup_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `useSignup_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignup_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupGenerateUrlForFileUploadMutation, { data, loading, error }] = useSignup_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignup_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Signup_GenerateUrlForFileUploadMutation,
    Signup_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Signup_GenerateUrlForFileUploadMutation,
    Signup_GenerateUrlForFileUploadMutationVariables
  >(Signup_GenerateUrlForFileUploadDocument, options);
}
export type Signup_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof useSignup_GenerateUrlForFileUploadMutation
>;
export type Signup_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<Signup_GenerateUrlForFileUploadMutation>;
export type Signup_GenerateUrlForFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Signup_GenerateUrlForFileUploadMutation,
  Signup_GenerateUrlForFileUploadMutationVariables
>;
export const CreativesScaleSignupDocument = gql`
  mutation CreativesScaleSignup($input: SignupCSUserInput!) {
    signupCSUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      creativesScaleUser {
        id
        userId
      }
    }
  }
`;
export type CreativesScaleSignupMutationFn = ApolloReactCommon.MutationFunction<
  CreativesScaleSignupMutation,
  CreativesScaleSignupMutationVariables
>;

/**
 * __useCreativesScaleSignupMutation__
 *
 * To run a mutation, you first call `useCreativesScaleSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreativesScaleSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creativesScaleSignupMutation, { data, loading, error }] = useCreativesScaleSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreativesScaleSignupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreativesScaleSignupMutation,
    CreativesScaleSignupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreativesScaleSignupMutation, CreativesScaleSignupMutationVariables>(
    CreativesScaleSignupDocument,
    options
  );
}
export type CreativesScaleSignupMutationHookResult = ReturnType<typeof useCreativesScaleSignupMutation>;
export type CreativesScaleSignupMutationResult = ApolloReactCommon.MutationResult<CreativesScaleSignupMutation>;
export type CreativesScaleSignupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreativesScaleSignupMutation,
  CreativesScaleSignupMutationVariables
>;
export const SignupViewerDocument = gql`
  query SignupViewer {
    viewer {
      user {
        id
        firstName
        lastName
        email
        isEmailConfirmed
        isPasswordSet
      }
      creativesScaleUser {
        id
      }
    }
  }
`;

/**
 * __useSignupViewerQuery__
 *
 * To run a query within a React component, call `useSignupViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignupViewerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SignupViewerQuery, SignupViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SignupViewerQuery, SignupViewerQueryVariables>(SignupViewerDocument, options);
}
export function useSignupViewerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SignupViewerQuery, SignupViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SignupViewerQuery, SignupViewerQueryVariables>(SignupViewerDocument, options);
}
export type SignupViewerQueryHookResult = ReturnType<typeof useSignupViewerQuery>;
export type SignupViewerLazyQueryHookResult = ReturnType<typeof useSignupViewerLazyQuery>;
export type SignupViewerQueryResult = ApolloReactCommon.QueryResult<SignupViewerQuery, SignupViewerQueryVariables>;
export const GenerateUrlForStripeOnboardingDocument = gql`
  mutation GenerateUrlForStripeOnboarding {
    generateUrlForStripeOnboarding {
      ok
      url
      error {
        message
      }
    }
  }
`;
export type GenerateUrlForStripeOnboardingMutationFn = ApolloReactCommon.MutationFunction<
  GenerateUrlForStripeOnboardingMutation,
  GenerateUrlForStripeOnboardingMutationVariables
>;

/**
 * __useGenerateUrlForStripeOnboardingMutation__
 *
 * To run a mutation, you first call `useGenerateUrlForStripeOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUrlForStripeOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUrlForStripeOnboardingMutation, { data, loading, error }] = useGenerateUrlForStripeOnboardingMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateUrlForStripeOnboardingMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateUrlForStripeOnboardingMutation,
    GenerateUrlForStripeOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GenerateUrlForStripeOnboardingMutation,
    GenerateUrlForStripeOnboardingMutationVariables
  >(GenerateUrlForStripeOnboardingDocument, options);
}
export type GenerateUrlForStripeOnboardingMutationHookResult = ReturnType<
  typeof useGenerateUrlForStripeOnboardingMutation
>;
export type GenerateUrlForStripeOnboardingMutationResult =
  ApolloReactCommon.MutationResult<GenerateUrlForStripeOnboardingMutation>;
export type GenerateUrlForStripeOnboardingMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateUrlForStripeOnboardingMutation,
  GenerateUrlForStripeOnboardingMutationVariables
>;
export const UnsupportedCountryDocument = gql`
  query UnsupportedCountry {
    viewer {
      creativesScaleUser {
        id
        optedInForUpdatesAt
        countryOfResidence
      }
    }
  }
`;

/**
 * __useUnsupportedCountryQuery__
 *
 * To run a query within a React component, call `useUnsupportedCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnsupportedCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnsupportedCountryQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnsupportedCountryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UnsupportedCountryQuery, UnsupportedCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UnsupportedCountryQuery, UnsupportedCountryQueryVariables>(
    UnsupportedCountryDocument,
    options
  );
}
export function useUnsupportedCountryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnsupportedCountryQuery, UnsupportedCountryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UnsupportedCountryQuery, UnsupportedCountryQueryVariables>(
    UnsupportedCountryDocument,
    options
  );
}
export type UnsupportedCountryQueryHookResult = ReturnType<typeof useUnsupportedCountryQuery>;
export type UnsupportedCountryLazyQueryHookResult = ReturnType<typeof useUnsupportedCountryLazyQuery>;
export type UnsupportedCountryQueryResult = ApolloReactCommon.QueryResult<
  UnsupportedCountryQuery,
  UnsupportedCountryQueryVariables
>;
export const UnsupportedCountry_UpdateCsUserDocument = gql`
  mutation UnsupportedCountry_UpdateCSUser($input: UpdateCreativeScaleUserInput!) {
    updateCreativesScaleUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UnsupportedCountry_UpdateCsUserMutationFn = ApolloReactCommon.MutationFunction<
  UnsupportedCountry_UpdateCsUserMutation,
  UnsupportedCountry_UpdateCsUserMutationVariables
>;

/**
 * __useUnsupportedCountry_UpdateCsUserMutation__
 *
 * To run a mutation, you first call `useUnsupportedCountry_UpdateCsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsupportedCountry_UpdateCsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsupportedCountryUpdateCsUserMutation, { data, loading, error }] = useUnsupportedCountry_UpdateCsUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsupportedCountry_UpdateCsUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnsupportedCountry_UpdateCsUserMutation,
    UnsupportedCountry_UpdateCsUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnsupportedCountry_UpdateCsUserMutation,
    UnsupportedCountry_UpdateCsUserMutationVariables
  >(UnsupportedCountry_UpdateCsUserDocument, options);
}
export type UnsupportedCountry_UpdateCsUserMutationHookResult = ReturnType<
  typeof useUnsupportedCountry_UpdateCsUserMutation
>;
export type UnsupportedCountry_UpdateCsUserMutationResult =
  ApolloReactCommon.MutationResult<UnsupportedCountry_UpdateCsUserMutation>;
export type UnsupportedCountry_UpdateCsUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnsupportedCountry_UpdateCsUserMutation,
  UnsupportedCountry_UpdateCsUserMutationVariables
>;
export const WelcomeDocument = gql`
  query Welcome {
    creativesScaleConfiguration {
      workInterests {
        id
        name
      }
    }
  }
`;

/**
 * __useWelcomeQuery__
 *
 * To run a query within a React component, call `useWelcomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWelcomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWelcomeQuery({
 *   variables: {
 *   },
 * });
 */
export function useWelcomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WelcomeQuery, WelcomeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WelcomeQuery, WelcomeQueryVariables>(WelcomeDocument, options);
}
export function useWelcomeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WelcomeQuery, WelcomeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WelcomeQuery, WelcomeQueryVariables>(WelcomeDocument, options);
}
export type WelcomeQueryHookResult = ReturnType<typeof useWelcomeQuery>;
export type WelcomeLazyQueryHookResult = ReturnType<typeof useWelcomeLazyQuery>;
export type WelcomeQueryResult = ApolloReactCommon.QueryResult<WelcomeQuery, WelcomeQueryVariables>;
export const SaveUserWorkInterestsDocument = gql`
  mutation SaveUserWorkInterests($input: SaveUserWorkInterestsInput!) {
    saveUserWorkInterests(input: $input) {
      interests
    }
  }
`;
export type SaveUserWorkInterestsMutationFn = ApolloReactCommon.MutationFunction<
  SaveUserWorkInterestsMutation,
  SaveUserWorkInterestsMutationVariables
>;

/**
 * __useSaveUserWorkInterestsMutation__
 *
 * To run a mutation, you first call `useSaveUserWorkInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserWorkInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserWorkInterestsMutation, { data, loading, error }] = useSaveUserWorkInterestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserWorkInterestsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveUserWorkInterestsMutation,
    SaveUserWorkInterestsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveUserWorkInterestsMutation, SaveUserWorkInterestsMutationVariables>(
    SaveUserWorkInterestsDocument,
    options
  );
}
export type SaveUserWorkInterestsMutationHookResult = ReturnType<typeof useSaveUserWorkInterestsMutation>;
export type SaveUserWorkInterestsMutationResult = ApolloReactCommon.MutationResult<SaveUserWorkInterestsMutation>;
export type SaveUserWorkInterestsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveUserWorkInterestsMutation,
  SaveUserWorkInterestsMutationVariables
>;
export const SelectWritingSampleTopicDocument = gql`
  mutation SelectWritingSampleTopic($input: SelectWritingSampleTopicInput!) {
    selectWritingSampleTopic(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SelectWritingSampleTopicMutationFn = ApolloReactCommon.MutationFunction<
  SelectWritingSampleTopicMutation,
  SelectWritingSampleTopicMutationVariables
>;

/**
 * __useSelectWritingSampleTopicMutation__
 *
 * To run a mutation, you first call `useSelectWritingSampleTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectWritingSampleTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectWritingSampleTopicMutation, { data, loading, error }] = useSelectWritingSampleTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelectWritingSampleTopicMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SelectWritingSampleTopicMutation,
    SelectWritingSampleTopicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SelectWritingSampleTopicMutation, SelectWritingSampleTopicMutationVariables>(
    SelectWritingSampleTopicDocument,
    options
  );
}
export type SelectWritingSampleTopicMutationHookResult = ReturnType<typeof useSelectWritingSampleTopicMutation>;
export type SelectWritingSampleTopicMutationResult = ApolloReactCommon.MutationResult<SelectWritingSampleTopicMutation>;
export type SelectWritingSampleTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SelectWritingSampleTopicMutation,
  SelectWritingSampleTopicMutationVariables
>;
export const SaveWritingSampleDocument = gql`
  mutation SaveWritingSample($input: SaveWritingSampleInput!) {
    saveWritingSample(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      userWritingSample {
        id
        validationCheckSummary {
          ...ValidationChecklistPanel_Summary
        }
      }
    }
  }
  ${ValidationChecklistPanel_SummaryFragmentDoc}
`;
export type SaveWritingSampleMutationFn = ApolloReactCommon.MutationFunction<
  SaveWritingSampleMutation,
  SaveWritingSampleMutationVariables
>;

/**
 * __useSaveWritingSampleMutation__
 *
 * To run a mutation, you first call `useSaveWritingSampleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWritingSampleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWritingSampleMutation, { data, loading, error }] = useSaveWritingSampleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWritingSampleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveWritingSampleMutation, SaveWritingSampleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveWritingSampleMutation, SaveWritingSampleMutationVariables>(
    SaveWritingSampleDocument,
    options
  );
}
export type SaveWritingSampleMutationHookResult = ReturnType<typeof useSaveWritingSampleMutation>;
export type SaveWritingSampleMutationResult = ApolloReactCommon.MutationResult<SaveWritingSampleMutation>;
export type SaveWritingSampleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveWritingSampleMutation,
  SaveWritingSampleMutationVariables
>;
export const WritingSampleDocument = gql`
  query WritingSample {
    viewer {
      creativesScaleUser {
        activeWritingSample {
          ...Writing
        }
        writingSampleTopics {
          ...WritingSample_Topic
        }
      }
    }
  }
  ${WritingFragmentDoc}
  ${WritingSample_TopicFragmentDoc}
`;

/**
 * __useWritingSampleQuery__
 *
 * To run a query within a React component, call `useWritingSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useWritingSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWritingSampleQuery({
 *   variables: {
 *   },
 * });
 */
export function useWritingSampleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WritingSampleQuery, WritingSampleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WritingSampleQuery, WritingSampleQueryVariables>(WritingSampleDocument, options);
}
export function useWritingSampleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WritingSampleQuery, WritingSampleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WritingSampleQuery, WritingSampleQueryVariables>(WritingSampleDocument, options);
}
export type WritingSampleQueryHookResult = ReturnType<typeof useWritingSampleQuery>;
export type WritingSampleLazyQueryHookResult = ReturnType<typeof useWritingSampleLazyQuery>;
export type WritingSampleQueryResult = ApolloReactCommon.QueryResult<WritingSampleQuery, WritingSampleQueryVariables>;
export const ProfileTasksDocument = gql`
  query ProfileTasks($limit: Int, $offset: Int, $assignedUserIds: [Int!], $statusIds: [String!]!) {
    tasks(limit: $limit, offset: $offset, assignedUserIds: $assignedUserIds, statusIds: $statusIds) {
      totalCount
      nodes {
        id
        completedAt
        taskType {
          name
        }
        status {
          id
          name
        }
        category {
          id
          name
        }
        topic
        reviewTargetTaskId
        reviewTasks {
          totalCount
          nodes {
            status {
              id
              name
            }
            reviewStatus {
              id
              name
            }
            completedAt
          }
        }
      }
    }
  }
`;

/**
 * __useProfileTasksQuery__
 *
 * To run a query within a React component, call `useProfileTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      statusIds: // value for 'statusIds'
 *   },
 * });
 */
export function useProfileTasksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ProfileTasksQuery, ProfileTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProfileTasksQuery, ProfileTasksQueryVariables>(ProfileTasksDocument, options);
}
export function useProfileTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileTasksQuery, ProfileTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProfileTasksQuery, ProfileTasksQueryVariables>(ProfileTasksDocument, options);
}
export type ProfileTasksQueryHookResult = ReturnType<typeof useProfileTasksQuery>;
export type ProfileTasksLazyQueryHookResult = ReturnType<typeof useProfileTasksLazyQuery>;
export type ProfileTasksQueryResult = ApolloReactCommon.QueryResult<ProfileTasksQuery, ProfileTasksQueryVariables>;
export const SaveProfileDocument = gql`
  mutation SaveProfile($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type SaveProfileMutationFn = ApolloReactCommon.MutationFunction<
  SaveProfileMutation,
  SaveProfileMutationVariables
>;

/**
 * __useSaveProfileMutation__
 *
 * To run a mutation, you first call `useSaveProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProfileMutation, { data, loading, error }] = useSaveProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveProfileMutation, SaveProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveProfileMutation, SaveProfileMutationVariables>(SaveProfileDocument, options);
}
export type SaveProfileMutationHookResult = ReturnType<typeof useSaveProfileMutation>;
export type SaveProfileMutationResult = ApolloReactCommon.MutationResult<SaveProfileMutation>;
export type SaveProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveProfileMutation,
  SaveProfileMutationVariables
>;
export const ProfileDocument = gql`
  query Profile {
    viewer {
      user {
        ...ProfileUser
      }
    }
  }
  ${ProfileUserFragmentDoc}
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
}
export function useProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, options);
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = ApolloReactCommon.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const Profile_GenerateUrlForFileUploadDocument = gql`
  mutation Profile_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadSignupInput!) {
    generateUrlForFileUploadSignup(input: $input) {
      fileId
      url
    }
  }
`;
export type Profile_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  Profile_GenerateUrlForFileUploadMutation,
  Profile_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __useProfile_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `useProfile_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfile_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileGenerateUrlForFileUploadMutation, { data, loading, error }] = useProfile_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProfile_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Profile_GenerateUrlForFileUploadMutation,
    Profile_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Profile_GenerateUrlForFileUploadMutation,
    Profile_GenerateUrlForFileUploadMutationVariables
  >(Profile_GenerateUrlForFileUploadDocument, options);
}
export type Profile_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof useProfile_GenerateUrlForFileUploadMutation
>;
export type Profile_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<Profile_GenerateUrlForFileUploadMutation>;
export type Profile_GenerateUrlForFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Profile_GenerateUrlForFileUploadMutation,
  Profile_GenerateUrlForFileUploadMutationVariables
>;
export const SaveUserProfessionalDocument = gql`
  mutation SaveUserProfessional($input: SaveUserProfessionalInput!) {
    saveUserProfessional(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          message
          fieldName
        }
      }
    }
  }
`;
export type SaveUserProfessionalMutationFn = ApolloReactCommon.MutationFunction<
  SaveUserProfessionalMutation,
  SaveUserProfessionalMutationVariables
>;

/**
 * __useSaveUserProfessionalMutation__
 *
 * To run a mutation, you first call `useSaveUserProfessionalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserProfessionalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserProfessionalMutation, { data, loading, error }] = useSaveUserProfessionalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveUserProfessionalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveUserProfessionalMutation,
    SaveUserProfessionalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveUserProfessionalMutation, SaveUserProfessionalMutationVariables>(
    SaveUserProfessionalDocument,
    options
  );
}
export type SaveUserProfessionalMutationHookResult = ReturnType<typeof useSaveUserProfessionalMutation>;
export type SaveUserProfessionalMutationResult = ApolloReactCommon.MutationResult<SaveUserProfessionalMutation>;
export type SaveUserProfessionalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveUserProfessionalMutation,
  SaveUserProfessionalMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      wasReset
    }
  }
`;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const GenerateUrlForStripeDashboardDocument = gql`
  mutation GenerateUrlForStripeDashboard {
    generateUrlForStripeDashboard {
      ok
      url
      error {
        message
      }
    }
  }
`;
export type GenerateUrlForStripeDashboardMutationFn = ApolloReactCommon.MutationFunction<
  GenerateUrlForStripeDashboardMutation,
  GenerateUrlForStripeDashboardMutationVariables
>;

/**
 * __useGenerateUrlForStripeDashboardMutation__
 *
 * To run a mutation, you first call `useGenerateUrlForStripeDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUrlForStripeDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUrlForStripeDashboardMutation, { data, loading, error }] = useGenerateUrlForStripeDashboardMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateUrlForStripeDashboardMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateUrlForStripeDashboardMutation,
    GenerateUrlForStripeDashboardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GenerateUrlForStripeDashboardMutation,
    GenerateUrlForStripeDashboardMutationVariables
  >(GenerateUrlForStripeDashboardDocument, options);
}
export type GenerateUrlForStripeDashboardMutationHookResult = ReturnType<
  typeof useGenerateUrlForStripeDashboardMutation
>;
export type GenerateUrlForStripeDashboardMutationResult =
  ApolloReactCommon.MutationResult<GenerateUrlForStripeDashboardMutation>;
export type GenerateUrlForStripeDashboardMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GenerateUrlForStripeDashboardMutation,
  GenerateUrlForStripeDashboardMutationVariables
>;
export const ContentViewDocument = gql`
  query ContentView($taskId: Int!) {
    task(taskId: $taskId) {
      id
      contentItem {
        id
        title
        content
      }
      reviewTasks(reviewStatusId: "rejected") {
        nodes {
          ...Comment_ReviewTask
        }
      }
    }
  }
  ${Comment_ReviewTaskFragmentDoc}
`;

/**
 * __useContentViewQuery__
 *
 * To run a query within a React component, call `useContentViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentViewQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ContentViewQuery, ContentViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentViewQuery, ContentViewQueryVariables>(ContentViewDocument, options);
}
export function useContentViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentViewQuery, ContentViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentViewQuery, ContentViewQueryVariables>(ContentViewDocument, options);
}
export type ContentViewQueryHookResult = ReturnType<typeof useContentViewQuery>;
export type ContentViewLazyQueryHookResult = ReturnType<typeof useContentViewLazyQuery>;
export type ContentViewQueryResult = ApolloReactCommon.QueryResult<ContentViewQuery, ContentViewQueryVariables>;
export const TaskDetailsDocument = gql`
  query TaskDetails($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      ...TasksDetails_Task
    }
  }
  ${TasksDetails_TaskFragmentDoc}
`;

/**
 * __useTaskDetailsQuery__
 *
 * To run a query within a React component, call `useTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useTaskDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
}
export function useTaskDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
}
export type TaskDetailsQueryHookResult = ReturnType<typeof useTaskDetailsQuery>;
export type TaskDetailsLazyQueryHookResult = ReturnType<typeof useTaskDetailsLazyQuery>;
export type TaskDetailsQueryResult = ApolloReactCommon.QueryResult<TaskDetailsQuery, TaskDetailsQueryVariables>;
export const TaskDetails_UpdateTaskDocument = gql`
  mutation TaskDetails_UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        ...TasksDetails_Task
      }
    }
  }
  ${TasksDetails_TaskFragmentDoc}
`;
export type TaskDetails_UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  TaskDetails_UpdateTaskMutation,
  TaskDetails_UpdateTaskMutationVariables
>;

/**
 * __useTaskDetails_UpdateTaskMutation__
 *
 * To run a mutation, you first call `useTaskDetails_UpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskDetails_UpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskDetailsUpdateTaskMutation, { data, loading, error }] = useTaskDetails_UpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskDetails_UpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TaskDetails_UpdateTaskMutation,
    TaskDetails_UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TaskDetails_UpdateTaskMutation, TaskDetails_UpdateTaskMutationVariables>(
    TaskDetails_UpdateTaskDocument,
    options
  );
}
export type TaskDetails_UpdateTaskMutationHookResult = ReturnType<typeof useTaskDetails_UpdateTaskMutation>;
export type TaskDetails_UpdateTaskMutationResult = ApolloReactCommon.MutationResult<TaskDetails_UpdateTaskMutation>;
export type TaskDetails_UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TaskDetails_UpdateTaskMutation,
  TaskDetails_UpdateTaskMutationVariables
>;
export const ContentIdeationTaskWorkspaceDocument = gql`
  query ContentIdeationTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ContentIdeationTaskWorkspace_Task
    }
  }
  ${ContentIdeationTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentIdeationTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentIdeationTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentIdeationTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentIdeationTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentIdeationTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentIdeationTaskWorkspaceQuery,
    ContentIdeationTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentIdeationTaskWorkspaceQuery, ContentIdeationTaskWorkspaceQueryVariables>(
    ContentIdeationTaskWorkspaceDocument,
    options
  );
}
export function useContentIdeationTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentIdeationTaskWorkspaceQuery,
    ContentIdeationTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentIdeationTaskWorkspaceQuery, ContentIdeationTaskWorkspaceQueryVariables>(
    ContentIdeationTaskWorkspaceDocument,
    options
  );
}
export type ContentIdeationTaskWorkspaceQueryHookResult = ReturnType<typeof useContentIdeationTaskWorkspaceQuery>;
export type ContentIdeationTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentIdeationTaskWorkspaceLazyQuery
>;
export type ContentIdeationTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentIdeationTaskWorkspaceQuery,
  ContentIdeationTaskWorkspaceQueryVariables
>;
export const SaveTaskContentIdeationDocument = gql`
  mutation SaveTaskContentIdeation($input: SaveTaskContentIdeationInput!) {
    saveTaskContentIdeation(input: $input) {
      contentIdeation {
        id
      }
    }
  }
`;
export type SaveTaskContentIdeationMutationFn = ApolloReactCommon.MutationFunction<
  SaveTaskContentIdeationMutation,
  SaveTaskContentIdeationMutationVariables
>;

/**
 * __useSaveTaskContentIdeationMutation__
 *
 * To run a mutation, you first call `useSaveTaskContentIdeationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskContentIdeationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskContentIdeationMutation, { data, loading, error }] = useSaveTaskContentIdeationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskContentIdeationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveTaskContentIdeationMutation,
    SaveTaskContentIdeationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveTaskContentIdeationMutation, SaveTaskContentIdeationMutationVariables>(
    SaveTaskContentIdeationDocument,
    options
  );
}
export type SaveTaskContentIdeationMutationHookResult = ReturnType<typeof useSaveTaskContentIdeationMutation>;
export type SaveTaskContentIdeationMutationResult = ApolloReactCommon.MutationResult<SaveTaskContentIdeationMutation>;
export type SaveTaskContentIdeationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveTaskContentIdeationMutation,
  SaveTaskContentIdeationMutationVariables
>;
export const ContentReviewTaskWorkspaceDocument = gql`
  query ContentReviewTaskWorkspace($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      ...ContentReviewTaskWorkspace_Task
    }
  }
  ${ContentReviewTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useContentReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentReviewTaskWorkspaceQuery,
    ContentReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentReviewTaskWorkspaceQuery, ContentReviewTaskWorkspaceQueryVariables>(
    ContentReviewTaskWorkspaceDocument,
    options
  );
}
export function useContentReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentReviewTaskWorkspaceQuery,
    ContentReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentReviewTaskWorkspaceQuery, ContentReviewTaskWorkspaceQueryVariables>(
    ContentReviewTaskWorkspaceDocument,
    options
  );
}
export type ContentReviewTaskWorkspaceQueryHookResult = ReturnType<typeof useContentReviewTaskWorkspaceQuery>;
export type ContentReviewTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useContentReviewTaskWorkspaceLazyQuery>;
export type ContentReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentReviewTaskWorkspaceQuery,
  ContentReviewTaskWorkspaceQueryVariables
>;
export const CreateFeedbackContentItemDocument = gql`
  mutation CreateFeedbackContentItem($input: CreateFeedbackContentItemInput!) {
    createFeedbackContentItem(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateFeedbackContentItemMutationFn = ApolloReactCommon.MutationFunction<
  CreateFeedbackContentItemMutation,
  CreateFeedbackContentItemMutationVariables
>;

/**
 * __useCreateFeedbackContentItemMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackContentItemMutation, { data, loading, error }] = useCreateFeedbackContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateFeedbackContentItemMutation,
    CreateFeedbackContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateFeedbackContentItemMutation, CreateFeedbackContentItemMutationVariables>(
    CreateFeedbackContentItemDocument,
    options
  );
}
export type CreateFeedbackContentItemMutationHookResult = ReturnType<typeof useCreateFeedbackContentItemMutation>;
export type CreateFeedbackContentItemMutationResult =
  ApolloReactCommon.MutationResult<CreateFeedbackContentItemMutation>;
export type CreateFeedbackContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateFeedbackContentItemMutation,
  CreateFeedbackContentItemMutationVariables
>;
export const ContentReview_SaveTaskContentItemDocument = gql`
  mutation ContentReview_SaveTaskContentItem($input: SaveTaskContentItemInput!) {
    saveTaskContentItem(input: $input) {
      contentItem {
        id
      }
    }
  }
`;
export type ContentReview_SaveTaskContentItemMutationFn = ApolloReactCommon.MutationFunction<
  ContentReview_SaveTaskContentItemMutation,
  ContentReview_SaveTaskContentItemMutationVariables
>;

/**
 * __useContentReview_SaveTaskContentItemMutation__
 *
 * To run a mutation, you first call `useContentReview_SaveTaskContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentReview_SaveTaskContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentReviewSaveTaskContentItemMutation, { data, loading, error }] = useContentReview_SaveTaskContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentReview_SaveTaskContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentReview_SaveTaskContentItemMutation,
    ContentReview_SaveTaskContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentReview_SaveTaskContentItemMutation,
    ContentReview_SaveTaskContentItemMutationVariables
  >(ContentReview_SaveTaskContentItemDocument, options);
}
export type ContentReview_SaveTaskContentItemMutationHookResult = ReturnType<
  typeof useContentReview_SaveTaskContentItemMutation
>;
export type ContentReview_SaveTaskContentItemMutationResult =
  ApolloReactCommon.MutationResult<ContentReview_SaveTaskContentItemMutation>;
export type ContentReview_SaveTaskContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentReview_SaveTaskContentItemMutation,
  ContentReview_SaveTaskContentItemMutationVariables
>;
export const UpdateFeedbackContentItemDocument = gql`
  mutation UpdateFeedbackContentItem($input: UpdateFeedbackContentItemInput!) {
    updateFeedbackContentItem(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateFeedbackContentItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFeedbackContentItemMutation,
  UpdateFeedbackContentItemMutationVariables
>;

/**
 * __useUpdateFeedbackContentItemMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackContentItemMutation, { data, loading, error }] = useUpdateFeedbackContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeedbackContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFeedbackContentItemMutation,
    UpdateFeedbackContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateFeedbackContentItemMutation, UpdateFeedbackContentItemMutationVariables>(
    UpdateFeedbackContentItemDocument,
    options
  );
}
export type UpdateFeedbackContentItemMutationHookResult = ReturnType<typeof useUpdateFeedbackContentItemMutation>;
export type UpdateFeedbackContentItemMutationResult =
  ApolloReactCommon.MutationResult<UpdateFeedbackContentItemMutation>;
export type UpdateFeedbackContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFeedbackContentItemMutation,
  UpdateFeedbackContentItemMutationVariables
>;
export const ContentReviewValidationCheckSummaryDocument = gql`
  query ContentReviewValidationCheckSummary($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      id
      reviewTargetTask {
        id
        validationCheckSummary {
          ...ValidationChecklistPanel_Summary
        }
      }
    }
  }
  ${ValidationChecklistPanel_SummaryFragmentDoc}
`;

/**
 * __useContentReviewValidationCheckSummaryQuery__
 *
 * To run a query within a React component, call `useContentReviewValidationCheckSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentReviewValidationCheckSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentReviewValidationCheckSummaryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useContentReviewValidationCheckSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentReviewValidationCheckSummaryQuery,
    ContentReviewValidationCheckSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ContentReviewValidationCheckSummaryQuery,
    ContentReviewValidationCheckSummaryQueryVariables
  >(ContentReviewValidationCheckSummaryDocument, options);
}
export function useContentReviewValidationCheckSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentReviewValidationCheckSummaryQuery,
    ContentReviewValidationCheckSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentReviewValidationCheckSummaryQuery,
    ContentReviewValidationCheckSummaryQueryVariables
  >(ContentReviewValidationCheckSummaryDocument, options);
}
export type ContentReviewValidationCheckSummaryQueryHookResult = ReturnType<
  typeof useContentReviewValidationCheckSummaryQuery
>;
export type ContentReviewValidationCheckSummaryLazyQueryHookResult = ReturnType<
  typeof useContentReviewValidationCheckSummaryLazyQuery
>;
export type ContentReviewValidationCheckSummaryQueryResult = ApolloReactCommon.QueryResult<
  ContentReviewValidationCheckSummaryQuery,
  ContentReviewValidationCheckSummaryQueryVariables
>;
export const ContentStrategyV2_OrderStatusesDocument = gql`
  query ContentStrategyV2_OrderStatuses {
    orderStatuses {
      id
      name
    }
  }
`;

/**
 * __useContentStrategyV2_OrderStatusesQuery__
 *
 * To run a query within a React component, call `useContentStrategyV2_OrderStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_OrderStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyV2_OrderStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentStrategyV2_OrderStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContentStrategyV2_OrderStatusesQuery,
    ContentStrategyV2_OrderStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyV2_OrderStatusesQuery, ContentStrategyV2_OrderStatusesQueryVariables>(
    ContentStrategyV2_OrderStatusesDocument,
    options
  );
}
export function useContentStrategyV2_OrderStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyV2_OrderStatusesQuery,
    ContentStrategyV2_OrderStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentStrategyV2_OrderStatusesQuery,
    ContentStrategyV2_OrderStatusesQueryVariables
  >(ContentStrategyV2_OrderStatusesDocument, options);
}
export type ContentStrategyV2_OrderStatusesQueryHookResult = ReturnType<typeof useContentStrategyV2_OrderStatusesQuery>;
export type ContentStrategyV2_OrderStatusesLazyQueryHookResult = ReturnType<
  typeof useContentStrategyV2_OrderStatusesLazyQuery
>;
export type ContentStrategyV2_OrderStatusesQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyV2_OrderStatusesQuery,
  ContentStrategyV2_OrderStatusesQueryVariables
>;
export const ContentStrategyV2_OrdersDocument = gql`
  query ContentStrategyV2_Orders(
    $limit: Int
    $offset: Int
    $sort: OrdersSort
    $search: String
    $customerIds: [Int!]
    $statusIds: [String!]
    $productIds: [String!]
  ) {
    orders(
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
      customerIds: $customerIds
      statusIds: $statusIds
      productIds: $productIds
    ) {
      totalCount
      nodes {
        id
        topic
        startDate
        instructions
        billingStatus {
          id
          name
          description
        }
        status {
          id
          name
        }
        customer {
          id
          name
          partner {
            id
            name
          }
        }
        user {
          id
          firstName
          lastName
        }
        partnerProduct {
          id
          name
        }
        customerKeyword {
          id
          name
        }
        orderIssues {
          title
          message
        }
      }
    }
  }
`;

/**
 * __useContentStrategyV2_OrdersQuery__
 *
 * To run a query within a React component, call `useContentStrategyV2_OrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_OrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyV2_OrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      customerIds: // value for 'customerIds'
 *      statusIds: // value for 'statusIds'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useContentStrategyV2_OrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ContentStrategyV2_OrdersQuery, ContentStrategyV2_OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyV2_OrdersQuery, ContentStrategyV2_OrdersQueryVariables>(
    ContentStrategyV2_OrdersDocument,
    options
  );
}
export function useContentStrategyV2_OrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyV2_OrdersQuery,
    ContentStrategyV2_OrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentStrategyV2_OrdersQuery, ContentStrategyV2_OrdersQueryVariables>(
    ContentStrategyV2_OrdersDocument,
    options
  );
}
export type ContentStrategyV2_OrdersQueryHookResult = ReturnType<typeof useContentStrategyV2_OrdersQuery>;
export type ContentStrategyV2_OrdersLazyQueryHookResult = ReturnType<typeof useContentStrategyV2_OrdersLazyQuery>;
export type ContentStrategyV2_OrdersQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyV2_OrdersQuery,
  ContentStrategyV2_OrdersQueryVariables
>;
export const ContentStrategyV2TaskWorkspaceDocument = gql`
  query ContentStrategyV2TaskWorkspace($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      id
      taskType {
        id
        name
      }
      reviewTasks {
        nodes {
          ...Comment_ReviewTask
        }
      }
      order {
        id
      }
      customer {
        id
        name
        website
        instructions
        category {
          id
          name
        }
        customerQuestionnaire {
          id
          businessDescription
          customerMarketSize
          targetAudience
          competitorWebsites
        }
      }
      customerProposal {
        id
        summary
        orderContentStrategies(sort: ORDER_START_DATE_ASC) {
          ...ContentStrategyV2_OrderContentStrategy
        }
      }
    }
  }
  ${Comment_ReviewTaskFragmentDoc}
  ${ContentStrategyV2_OrderContentStrategyFragmentDoc}
`;

/**
 * __useContentStrategyV2TaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentStrategyV2TaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2TaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyV2TaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useContentStrategyV2TaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentStrategyV2TaskWorkspaceQuery,
    ContentStrategyV2TaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyV2TaskWorkspaceQuery, ContentStrategyV2TaskWorkspaceQueryVariables>(
    ContentStrategyV2TaskWorkspaceDocument,
    options
  );
}
export function useContentStrategyV2TaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyV2TaskWorkspaceQuery,
    ContentStrategyV2TaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentStrategyV2TaskWorkspaceQuery,
    ContentStrategyV2TaskWorkspaceQueryVariables
  >(ContentStrategyV2TaskWorkspaceDocument, options);
}
export type ContentStrategyV2TaskWorkspaceQueryHookResult = ReturnType<typeof useContentStrategyV2TaskWorkspaceQuery>;
export type ContentStrategyV2TaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentStrategyV2TaskWorkspaceLazyQuery
>;
export type ContentStrategyV2TaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyV2TaskWorkspaceQuery,
  ContentStrategyV2TaskWorkspaceQueryVariables
>;
export const ResolveOrderContentStrategyReviewDocument = gql`
  mutation ResolveOrderContentStrategyReview($input: ResolveOrderContentStrategyReviewInput!) {
    resolveOrderContentStrategyReview(input: $input) {
      ok
      error {
        message
      }
      orderContentStrategyReview {
        id
        resolvedAt
      }
    }
  }
`;
export type ResolveOrderContentStrategyReviewMutationFn = ApolloReactCommon.MutationFunction<
  ResolveOrderContentStrategyReviewMutation,
  ResolveOrderContentStrategyReviewMutationVariables
>;

/**
 * __useResolveOrderContentStrategyReviewMutation__
 *
 * To run a mutation, you first call `useResolveOrderContentStrategyReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveOrderContentStrategyReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveOrderContentStrategyReviewMutation, { data, loading, error }] = useResolveOrderContentStrategyReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveOrderContentStrategyReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResolveOrderContentStrategyReviewMutation,
    ResolveOrderContentStrategyReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ResolveOrderContentStrategyReviewMutation,
    ResolveOrderContentStrategyReviewMutationVariables
  >(ResolveOrderContentStrategyReviewDocument, options);
}
export type ResolveOrderContentStrategyReviewMutationHookResult = ReturnType<
  typeof useResolveOrderContentStrategyReviewMutation
>;
export type ResolveOrderContentStrategyReviewMutationResult =
  ApolloReactCommon.MutationResult<ResolveOrderContentStrategyReviewMutation>;
export type ResolveOrderContentStrategyReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResolveOrderContentStrategyReviewMutation,
  ResolveOrderContentStrategyReviewMutationVariables
>;
export const ContentStrategyV2_UpdateCustomerProposalDocument = gql`
  mutation ContentStrategyV2_UpdateCustomerProposal($input: UpdateCustomerProposalInput!) {
    updateCustomerProposal(input: $input) {
      ok
      error {
        message
      }
      customerProposal {
        id
        summary
      }
    }
  }
`;
export type ContentStrategyV2_UpdateCustomerProposalMutationFn = ApolloReactCommon.MutationFunction<
  ContentStrategyV2_UpdateCustomerProposalMutation,
  ContentStrategyV2_UpdateCustomerProposalMutationVariables
>;

/**
 * __useContentStrategyV2_UpdateCustomerProposalMutation__
 *
 * To run a mutation, you first call `useContentStrategyV2_UpdateCustomerProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_UpdateCustomerProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentStrategyV2UpdateCustomerProposalMutation, { data, loading, error }] = useContentStrategyV2_UpdateCustomerProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentStrategyV2_UpdateCustomerProposalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentStrategyV2_UpdateCustomerProposalMutation,
    ContentStrategyV2_UpdateCustomerProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentStrategyV2_UpdateCustomerProposalMutation,
    ContentStrategyV2_UpdateCustomerProposalMutationVariables
  >(ContentStrategyV2_UpdateCustomerProposalDocument, options);
}
export type ContentStrategyV2_UpdateCustomerProposalMutationHookResult = ReturnType<
  typeof useContentStrategyV2_UpdateCustomerProposalMutation
>;
export type ContentStrategyV2_UpdateCustomerProposalMutationResult =
  ApolloReactCommon.MutationResult<ContentStrategyV2_UpdateCustomerProposalMutation>;
export type ContentStrategyV2_UpdateCustomerProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentStrategyV2_UpdateCustomerProposalMutation,
  ContentStrategyV2_UpdateCustomerProposalMutationVariables
>;
export const ContentStrategyV2_UpdateOrderContentStrategyDocument = gql`
  mutation ContentStrategyV2_UpdateOrderContentStrategy($input: UpdateOrderContentStrategyInput!) {
    updateOrderContentStrategy(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      orderContentStrategy {
        ...ContentStrategyV2_OrderContentStrategy
      }
    }
  }
  ${ContentStrategyV2_OrderContentStrategyFragmentDoc}
`;
export type ContentStrategyV2_UpdateOrderContentStrategyMutationFn = ApolloReactCommon.MutationFunction<
  ContentStrategyV2_UpdateOrderContentStrategyMutation,
  ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
>;

/**
 * __useContentStrategyV2_UpdateOrderContentStrategyMutation__
 *
 * To run a mutation, you first call `useContentStrategyV2_UpdateOrderContentStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_UpdateOrderContentStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentStrategyV2UpdateOrderContentStrategyMutation, { data, loading, error }] = useContentStrategyV2_UpdateOrderContentStrategyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentStrategyV2_UpdateOrderContentStrategyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentStrategyV2_UpdateOrderContentStrategyMutation,
    ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentStrategyV2_UpdateOrderContentStrategyMutation,
    ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
  >(ContentStrategyV2_UpdateOrderContentStrategyDocument, options);
}
export type ContentStrategyV2_UpdateOrderContentStrategyMutationHookResult = ReturnType<
  typeof useContentStrategyV2_UpdateOrderContentStrategyMutation
>;
export type ContentStrategyV2_UpdateOrderContentStrategyMutationResult =
  ApolloReactCommon.MutationResult<ContentStrategyV2_UpdateOrderContentStrategyMutation>;
export type ContentStrategyV2_UpdateOrderContentStrategyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentStrategyV2_UpdateOrderContentStrategyMutation,
  ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
>;
export const ContentWorkspaceDocument = gql`
  query ContentWorkspace($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      ...ContentWorkspace_Task
    }
  }
  ${ContentWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useContentWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ContentWorkspaceQuery, ContentWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentWorkspaceQuery, ContentWorkspaceQueryVariables>(
    ContentWorkspaceDocument,
    options
  );
}
export function useContentWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentWorkspaceQuery, ContentWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentWorkspaceQuery, ContentWorkspaceQueryVariables>(
    ContentWorkspaceDocument,
    options
  );
}
export type ContentWorkspaceQueryHookResult = ReturnType<typeof useContentWorkspaceQuery>;
export type ContentWorkspaceLazyQueryHookResult = ReturnType<typeof useContentWorkspaceLazyQuery>;
export type ContentWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentWorkspaceQuery,
  ContentWorkspaceQueryVariables
>;
export const ContentWorkspace_SaveTaskContentItemDocument = gql`
  mutation ContentWorkspace_SaveTaskContentItem($input: SaveTaskContentItemInput!) {
    saveTaskContentItem(input: $input) {
      contentItem {
        id
      }
    }
  }
`;
export type ContentWorkspace_SaveTaskContentItemMutationFn = ApolloReactCommon.MutationFunction<
  ContentWorkspace_SaveTaskContentItemMutation,
  ContentWorkspace_SaveTaskContentItemMutationVariables
>;

/**
 * __useContentWorkspace_SaveTaskContentItemMutation__
 *
 * To run a mutation, you first call `useContentWorkspace_SaveTaskContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentWorkspace_SaveTaskContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentWorkspaceSaveTaskContentItemMutation, { data, loading, error }] = useContentWorkspace_SaveTaskContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentWorkspace_SaveTaskContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentWorkspace_SaveTaskContentItemMutation,
    ContentWorkspace_SaveTaskContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentWorkspace_SaveTaskContentItemMutation,
    ContentWorkspace_SaveTaskContentItemMutationVariables
  >(ContentWorkspace_SaveTaskContentItemDocument, options);
}
export type ContentWorkspace_SaveTaskContentItemMutationHookResult = ReturnType<
  typeof useContentWorkspace_SaveTaskContentItemMutation
>;
export type ContentWorkspace_SaveTaskContentItemMutationResult =
  ApolloReactCommon.MutationResult<ContentWorkspace_SaveTaskContentItemMutation>;
export type ContentWorkspace_SaveTaskContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentWorkspace_SaveTaskContentItemMutation,
  ContentWorkspace_SaveTaskContentItemMutationVariables
>;
export const ContentValidationCheckSummaryDocument = gql`
  query ContentValidationCheckSummary($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      id
      validationCheckSummary {
        ...ValidationChecklistPanel_Summary
      }
    }
  }
  ${ValidationChecklistPanel_SummaryFragmentDoc}
`;

/**
 * __useContentValidationCheckSummaryQuery__
 *
 * To run a query within a React component, call `useContentValidationCheckSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentValidationCheckSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentValidationCheckSummaryQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useContentValidationCheckSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentValidationCheckSummaryQuery,
    ContentValidationCheckSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentValidationCheckSummaryQuery, ContentValidationCheckSummaryQueryVariables>(
    ContentValidationCheckSummaryDocument,
    options
  );
}
export function useContentValidationCheckSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentValidationCheckSummaryQuery,
    ContentValidationCheckSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentValidationCheckSummaryQuery, ContentValidationCheckSummaryQueryVariables>(
    ContentValidationCheckSummaryDocument,
    options
  );
}
export type ContentValidationCheckSummaryQueryHookResult = ReturnType<typeof useContentValidationCheckSummaryQuery>;
export type ContentValidationCheckSummaryLazyQueryHookResult = ReturnType<
  typeof useContentValidationCheckSummaryLazyQuery
>;
export type ContentValidationCheckSummaryQueryResult = ApolloReactCommon.QueryResult<
  ContentValidationCheckSummaryQuery,
  ContentValidationCheckSummaryQueryVariables
>;
export const GbpWorkspaceDocument = gql`
  query GbpWorkspace($taskId: Int!, $ignoreUserTaskPermission: Boolean) {
    task(taskId: $taskId, ignoreUserTaskPermission: $ignoreUserTaskPermission) {
      ...GbpWorkspace_Task
    }
  }
  ${GbpWorkspace_TaskFragmentDoc}
`;

/**
 * __useGbpWorkspaceQuery__
 *
 * To run a query within a React component, call `useGbpWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGbpWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGbpWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      ignoreUserTaskPermission: // value for 'ignoreUserTaskPermission'
 *   },
 * });
 */
export function useGbpWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GbpWorkspaceQuery, GbpWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GbpWorkspaceQuery, GbpWorkspaceQueryVariables>(GbpWorkspaceDocument, options);
}
export function useGbpWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GbpWorkspaceQuery, GbpWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GbpWorkspaceQuery, GbpWorkspaceQueryVariables>(GbpWorkspaceDocument, options);
}
export type GbpWorkspaceQueryHookResult = ReturnType<typeof useGbpWorkspaceQuery>;
export type GbpWorkspaceLazyQueryHookResult = ReturnType<typeof useGbpWorkspaceLazyQuery>;
export type GbpWorkspaceQueryResult = ApolloReactCommon.QueryResult<GbpWorkspaceQuery, GbpWorkspaceQueryVariables>;
export const GbpWorkspace_SaveTaskGbpItemDocument = gql`
  mutation GbpWorkspace_SaveTaskGbpItem($input: SaveTaskGbpItemInput!) {
    saveTaskGbpItem(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type GbpWorkspace_SaveTaskGbpItemMutationFn = ApolloReactCommon.MutationFunction<
  GbpWorkspace_SaveTaskGbpItemMutation,
  GbpWorkspace_SaveTaskGbpItemMutationVariables
>;

/**
 * __useGbpWorkspace_SaveTaskGbpItemMutation__
 *
 * To run a mutation, you first call `useGbpWorkspace_SaveTaskGbpItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGbpWorkspace_SaveTaskGbpItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gbpWorkspaceSaveTaskGbpItemMutation, { data, loading, error }] = useGbpWorkspace_SaveTaskGbpItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGbpWorkspace_SaveTaskGbpItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GbpWorkspace_SaveTaskGbpItemMutation,
    GbpWorkspace_SaveTaskGbpItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GbpWorkspace_SaveTaskGbpItemMutation,
    GbpWorkspace_SaveTaskGbpItemMutationVariables
  >(GbpWorkspace_SaveTaskGbpItemDocument, options);
}
export type GbpWorkspace_SaveTaskGbpItemMutationHookResult = ReturnType<typeof useGbpWorkspace_SaveTaskGbpItemMutation>;
export type GbpWorkspace_SaveTaskGbpItemMutationResult =
  ApolloReactCommon.MutationResult<GbpWorkspace_SaveTaskGbpItemMutation>;
export type GbpWorkspace_SaveTaskGbpItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GbpWorkspace_SaveTaskGbpItemMutation,
  GbpWorkspace_SaveTaskGbpItemMutationVariables
>;
export const SaveTaskTitleMetaDescriptionDocument = gql`
  mutation SaveTaskTitleMetaDescription($input: SaveTaskTitleMetaDescriptionInput!) {
    saveTaskTitleMetaDescriptions(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          message
          fieldName
        }
      }
    }
  }
`;
export type SaveTaskTitleMetaDescriptionMutationFn = ApolloReactCommon.MutationFunction<
  SaveTaskTitleMetaDescriptionMutation,
  SaveTaskTitleMetaDescriptionMutationVariables
>;

/**
 * __useSaveTaskTitleMetaDescriptionMutation__
 *
 * To run a mutation, you first call `useSaveTaskTitleMetaDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskTitleMetaDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskTitleMetaDescriptionMutation, { data, loading, error }] = useSaveTaskTitleMetaDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskTitleMetaDescriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveTaskTitleMetaDescriptionMutation,
    SaveTaskTitleMetaDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveTaskTitleMetaDescriptionMutation,
    SaveTaskTitleMetaDescriptionMutationVariables
  >(SaveTaskTitleMetaDescriptionDocument, options);
}
export type SaveTaskTitleMetaDescriptionMutationHookResult = ReturnType<typeof useSaveTaskTitleMetaDescriptionMutation>;
export type SaveTaskTitleMetaDescriptionMutationResult =
  ApolloReactCommon.MutationResult<SaveTaskTitleMetaDescriptionMutation>;
export type SaveTaskTitleMetaDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveTaskTitleMetaDescriptionMutation,
  SaveTaskTitleMetaDescriptionMutationVariables
>;
export const TitleMetaDescriptionTaskWorkspaceDocument = gql`
  query TitleMetaDescriptionTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...TitleMetaDescriptionTaskWorkspace_Task
    }
  }
  ${TitleMetaDescriptionTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useTitleMetaDescriptionTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useTitleMetaDescriptionTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitleMetaDescriptionTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitleMetaDescriptionTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTitleMetaDescriptionTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TitleMetaDescriptionTaskWorkspaceQuery,
    TitleMetaDescriptionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    TitleMetaDescriptionTaskWorkspaceQuery,
    TitleMetaDescriptionTaskWorkspaceQueryVariables
  >(TitleMetaDescriptionTaskWorkspaceDocument, options);
}
export function useTitleMetaDescriptionTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TitleMetaDescriptionTaskWorkspaceQuery,
    TitleMetaDescriptionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TitleMetaDescriptionTaskWorkspaceQuery,
    TitleMetaDescriptionTaskWorkspaceQueryVariables
  >(TitleMetaDescriptionTaskWorkspaceDocument, options);
}
export type TitleMetaDescriptionTaskWorkspaceQueryHookResult = ReturnType<
  typeof useTitleMetaDescriptionTaskWorkspaceQuery
>;
export type TitleMetaDescriptionTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useTitleMetaDescriptionTaskWorkspaceLazyQuery
>;
export type TitleMetaDescriptionTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  TitleMetaDescriptionTaskWorkspaceQuery,
  TitleMetaDescriptionTaskWorkspaceQueryVariables
>;
export const PaymentIdentityReminderDocument = gql`
  query PaymentIdentityReminder {
    viewer {
      creativesScaleUser {
        id
        isStripeOnboardingReminderEnabled
      }
    }
  }
`;

/**
 * __usePaymentIdentityReminderQuery__
 *
 * To run a query within a React component, call `usePaymentIdentityReminderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentIdentityReminderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentIdentityReminderQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentIdentityReminderQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentIdentityReminderQuery, PaymentIdentityReminderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentIdentityReminderQuery, PaymentIdentityReminderQueryVariables>(
    PaymentIdentityReminderDocument,
    options
  );
}
export function usePaymentIdentityReminderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentIdentityReminderQuery,
    PaymentIdentityReminderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentIdentityReminderQuery, PaymentIdentityReminderQueryVariables>(
    PaymentIdentityReminderDocument,
    options
  );
}
export type PaymentIdentityReminderQueryHookResult = ReturnType<typeof usePaymentIdentityReminderQuery>;
export type PaymentIdentityReminderLazyQueryHookResult = ReturnType<typeof usePaymentIdentityReminderLazyQuery>;
export type PaymentIdentityReminderQueryResult = ApolloReactCommon.QueryResult<
  PaymentIdentityReminderQuery,
  PaymentIdentityReminderQueryVariables
>;
export const UnsubscribePaymentIdentityReminder_UpdateCsUserDocument = gql`
  mutation UnsubscribePaymentIdentityReminder_UpdateCSUser($input: UpdateCreativeScaleUserInput!) {
    updateCreativesScaleUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UnsubscribePaymentIdentityReminder_UpdateCsUserMutationFn = ApolloReactCommon.MutationFunction<
  UnsubscribePaymentIdentityReminder_UpdateCsUserMutation,
  UnsubscribePaymentIdentityReminder_UpdateCsUserMutationVariables
>;

/**
 * __useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation__
 *
 * To run a mutation, you first call `useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribePaymentIdentityReminderUpdateCsUserMutation, { data, loading, error }] = useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnsubscribePaymentIdentityReminder_UpdateCsUserMutation,
    UnsubscribePaymentIdentityReminder_UpdateCsUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnsubscribePaymentIdentityReminder_UpdateCsUserMutation,
    UnsubscribePaymentIdentityReminder_UpdateCsUserMutationVariables
  >(UnsubscribePaymentIdentityReminder_UpdateCsUserDocument, options);
}
export type UnsubscribePaymentIdentityReminder_UpdateCsUserMutationHookResult = ReturnType<
  typeof useUnsubscribePaymentIdentityReminder_UpdateCsUserMutation
>;
export type UnsubscribePaymentIdentityReminder_UpdateCsUserMutationResult =
  ApolloReactCommon.MutationResult<UnsubscribePaymentIdentityReminder_UpdateCsUserMutation>;
export type UnsubscribePaymentIdentityReminder_UpdateCsUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UnsubscribePaymentIdentityReminder_UpdateCsUserMutation,
  UnsubscribePaymentIdentityReminder_UpdateCsUserMutationVariables
>;
